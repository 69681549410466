import React, {useContext} from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import ApiDatabase from 'server'
import {toast} from "react-toastify";
import useModal from "components/modal/useModal";
import UseContext from "hooks/useContext";


const Modalexperiencedelete = () => {
    const {expID, refreshLoadWorker} = UseContext()
    const {toggle}=useModal()

    const handleSubmit = (e) => {
        e.preventDefault()
        ApiDatabase.postDeleteExp({
                token: localStorage.getItem('xsrfToken'),
                expID: expID
            },
            (data) => {
                if (data.success) {
                    refreshLoadWorker()
                    toggle()
                    toast.success('L\'expérience  est supprimée.')
                }
                if (data.error) toast.error('Error')
            },
            (err) => {
                if (err.name !== "CanceledError") {
                    console.log(err)
                    toast.error('Error')
                }
            })

    }
    return (
        <>
                <Modalheader hide={toggle} hideShow={true}>Supprimer une
                    expérience</Modalheader>
                <ModalBody>
                    Êtes-vous sûr de vouloir supprimer cette expérience ? Cette action est
                    irréversible.
                </ModalBody>
                <ModalFooter>
                    <Button size={'LG'} color={'SECONDARY'}
                            onClick={toggle}>Annuler</Button>
                    <Button size={'LG'} onClick={handleSubmit}>Confirmer</Button>
                </ModalFooter>
        </>
    );
};

export default Modalexperiencedelete;