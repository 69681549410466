import React from 'react';
import {Outlet} from "react-router-dom";
import PageForm from "../../components/pageForm/PageForm";
import TimeSheetSubNav from "../../components/subnav/timeSheet.subNav";

const TimeSheet = () => {
    return (
        <>
            <PageForm>
                <div className="flex items-center justify-end col-span-12 bg-white sticky z-10">
                    <TimeSheetSubNav/>
                </div>
                <div className="p-3 flex flex-col w-full h-full gap-3">
                    <Outlet/>
                </div>
            </PageForm>
        </>
    );
};

export default TimeSheet;