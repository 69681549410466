import {useEffect, useRef} from 'react';

const useAbortController = () => {
    const controllerRef = useRef(null);

    useEffect(() => {
        return () => {
            if (controllerRef.current) {
                controllerRef.current.abort();
            }
        };
    }, []);

    return () => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        controllerRef.current = new AbortController();
        return controllerRef.current;
    };
};

export default useAbortController;