import Badge from "../../../../../../../components/badge/badge";
import CopyToClipboard from "../../../../../../../components/copyToClipboard";
import Button from "../../../../../../../components/button";
import {
    BanIcon, CrossIcon,
    Dots,
    EyeIcon,
    Localisation,
    Plus,
    Star,
    SwitchIcon
} from "../../../../../../../assets/icons";
import UseContext from "../../../../../../../hooks/useContext";
import useModal from "../../../../../../../components/modal/useModal";
import useSlideOver from "../../../../../../../components/SlideOver/useSlideOver";
import {useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";

const TbodyFicheVivier = ({champsBody, setAddVivier, list, idVivier, isExclude}) => {

    const { setInfoVivier, setAdminLink, setAdminType, setAdminTempWorkerId } = UseContext()
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropDownRef = useRef(null);
    const { toggle } = useModal()
    const {objID, setObjID}=UseContext()
    const {toggleSlideOver} = useSlideOver();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const idTempWorker = urlParams.get('idTempWorker');

    const handleCheckboxChange = (id) => {
        // Vérifiez si la case à cocher d'en-tête est cochée pour décider de l'état des cases à cocher individuelles
        const checked = list.includes(id);

        // Mettez à jour l'état de la case à cocher individuelle
        if (checked) {
            setAddVivier(list.filter((item) => item !== id));
        } else {
            setAddVivier([...list, id]);
        }
    };

    useEffect(() => {
        let handler = (e) => {
            if (!dropDownRef.current.contains(e.target)) {
                setDropdownOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)

        return () => {
            document.removeEventListener("mousedown", handler)
        }
    }, [])

    const openTempWorkerInfo = (id) => {
        setObjID(id)
        toggleSlideOver('get_temp_worker')
        navigate('/ficheVivier/'+ idVivier +'?idTempWorker=' + id);
    }

    useEffect(() => {
        if(idTempWorker) {
            if(!objID){
                openTempWorkerInfo(idTempWorker)
            } else {
                if(idTempWorker !== objID) {
                    openTempWorkerInfo(idTempWorker)
                }
            }
        }
    }, [idTempWorker])

    const openDocument = (type, uri) => {
        setAdminLink(uri);
        setAdminType(type);
        setAdminTempWorkerId('');
        toggle('ad_file_view');
    }

    const excludeAgencySelected = (idUser) => {
        setInfoVivier({listUsers: [idUser]})
        toggle("exclude_agency")
    }

    const addVivierSelected = (idUser) => {
        setInfoVivier({listUsers: [idUser]})
        toggle("vivier_add")
    }

    const changeLocalisation = (idUser) => {
        setInfoVivier({listUsers: [idUser]})
        toggle("localisation_preferences")
    }

    const excludeVivier = (idUser) => {
        setInfoVivier({
            idVivier: idVivier,
            nameUser: champsBody.firstname + " " + champsBody.lastname,
            listUsers: [idUser]
        })
        toggle("vivier_delete_candidate")
    }

    const changeUserVivierStatus = (idUser) => {
        setInfoVivier({
            idVivier: idVivier,
            listUsers: [idUser]
        })
        toggle("vivier_change_status")
    }

    const handleDropDown = () => {
        setDropdownOpen(prev => !prev);
    }

    return (
        <>
            {!champsBody?.isDeleted && (
                <tr className={'border-b-2 border-gray-100 h-20 '}>
                    <td className='flex items-center gap-4 py-3.5 px-4 sticky left-0 bg-white cursor-pointer' onClick={() => handleCheckboxChange(champsBody._id)}>
                        <div className='flex items-center gap-4'>
                            <input type="checkbox" checked={list?.includes(champsBody._id)} readOnly={true}/>
                            <div>
                                <div className='text-sm text-gray-900'>{champsBody.firstname} {champsBody.lastname}</div>
                                <div className='flex gap-4'>
                                    {champsBody.token_refresh_mobile ? <Badge type={'APP'}>App</Badge> : ""}
                                    {champsBody.token_refresh ? <Badge type={'WEB'}>Web</Badge> : ""}
                                </div>
                            </div>
                        </div>
                    </td>
                    {idVivier !== '64e8cfd10b4f73f442432c9f' && (
                        <td className='py-3.5 px-4 bg-white'>
                            {(champsBody.rating || champsBody.rating === 0) ? (
                                <div className={'my-auto border rounded-full flex gap-1 px-1.5 w-fit'}>
                                    <div className={'my-auto'}>
                                        <Star wh={18} color={'#FF9600'}/>
                                    </div>
                                    <div className={'my-auto'}>
                                        {champsBody.rating}
                                    </div>
                                </div>
                            ) : (
                                <div className={'text-sm text-gray-500'}>
                                    Non noté
                                </div>
                            )}
                        </td>
                    )}
                    <td className='py-3.5 px-4 bg-white'>
                        {champsBody.phone ?
                            <CopyToClipboard text={champsBody.phone}>
                                <span className='text-sm text-gray-700'>
                                    {champsBody.phone}
                                </span>
                            </CopyToClipboard>
                            : null
                        }
                        {
                            champsBody.email ?
                                <CopyToClipboard text={champsBody.email}>
                                    <span className='text-sm text-gray-500'>
                                        {champsBody.email}
                                    </span>
                                </CopyToClipboard>
                                : null
                        }
                    </td>
                    {!isExclude && (
                        <td className='py-3.5 px-4 bg-white'>
                            <p className="truncate text-sm text-gray-500">
                                {champsBody?.viviers?.join("/ ")}
                            </p>
                        </td>
                    )}
                    <td className='py-3.5 px-4 bg-white'>
                        <div className={'flex'}>
                            <div className={'flex items-center gap-2 my-auto ml-auto'}>
                                {!isExclude && (
                                    <div className='flex items-center justify-center my-auto w-9 h-9 cursor-pointer' onClick={() => openTempWorkerInfo(champsBody?._id)}>
                                        <EyeIcon wh={24}  color={'#374151'}/>
                                    </div>
                                )}
                                <div className={'my-auto relative cursor-pointer w-9 h-9 flex items-center justify-center rounded hover:bg-gray-100'} ref={dropDownRef} onClick={() => handleDropDown()}>
                                    <Dots w={14} h={4} color={'#374151'}/>
                                    {dropdownOpen &&
                                        <div
                                            className={`${dropdownOpen ? "" : "hidden"} absolute border-2 border-[#F3F4F6] rounded-lg shadow-lg h-30 w-56 right-8 -bottom-8 bg-gray-50`}>
                                            <div
                                                className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer"
                                                onClick={() => addVivierSelected(champsBody?._id)}>
                                                <div className={'flex gap-2'}>
                                                    <div className={'my-auto'}>
                                                        <Plus wh={20} color={'#374151'}/>
                                                    </div>
                                                    <div className={'my-auto'}>
                                                        Ajouter dans un vivier
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer"
                                                onClick={() => changeLocalisation(champsBody?._id)}>
                                                <div className={'flex gap-2'}>
                                                    <div className={'my-auto'}>
                                                        <Localisation wh={20} color={'#374151'}/>
                                                    </div>
                                                    <div className={'my-auto'}>
                                                        Modifier la localisation
                                                    </div>
                                                </div>
                                            </div>
                                            {champsBody.vivier_status !== 'archive' && (
                                                <>
                                                    <div className="border-b border-gray-100"></div>
                                                    <div
                                                        className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer"
                                                        onClick={() => changeUserVivierStatus(champsBody?._id)}>
                                                        <div className={'flex gap-2'}>
                                                            <div className={'my-auto'}>
                                                                <SwitchIcon wh={20} color={'#374151'}/>
                                                            </div>
                                                            <div className={'my-auto'}>
                                                                Déplacer de colonne
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            <div className="border-b border-gray-100"></div>
                                            <div
                                                className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer"
                                                onClick={() => excludeVivier(champsBody?._id)}>
                                                <div className={'flex gap-2'}>
                                                    <div className={'my-auto'}>
                                                        <CrossIcon wh={20} color={'#374151'}/>
                                                    </div>
                                                    <div className={'my-auto'}>
                                                        Supprimer du vivier
                                                    </div>
                                                </div>
                                            </div>
                                            {!isExclude && (
                                                <>
                                                    <div
                                                        className="flex flex-row gap-2 items-center h-9 p-2 ft-sm text-red-500 cursor-pointer"
                                                        onClick={() => excludeAgencySelected(champsBody?._id)}>
                                                        <div className={'flex gap-2'}>
                                                            <div className={'my-auto'}>
                                                                <BanIcon wh={20} color={'#DC2626'}/>
                                                            </div>
                                                            <div className={'my-auto'}>
                                                                Exclure de l'agence
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            )}
        </>
    )
}

export default TbodyFicheVivier;