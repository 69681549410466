import React, {useEffect, useState} from 'react';
import UseContext from "hooks/useContext";
import ApiDatabase from "server";
import Table from "components/table/table";
import EmptyState from "../../../components/EmptyState/EmptyState";
import Pagination from "../../../components/pagination/pagination";
import Tooltip from "../../../components/tooltips/Tooltips";
import {InformationCircle} from "../../../assets/icons";
import DatePicker from "react-datepicker";
import {dateToEpoch} from "../../../helper/helper";

const CheckedContent = () => {
    const {
        companyData,
        refreshTime,
        currentPageTimeSheetChecked, setCurrentPageTimeSheetChecked,
        timeSheetSearchTimeSheetChecked, setTimeSheetSearchTimeSheetChecked,
        timeSheetSearchTypeTimeSheetChecked, setTimeSheetSearchTypeTimeSheetChecked,
        dateRangeTimeSheetChecked, setDateRangeTimeSheetChecked
    } = UseContext()
    const token = localStorage.getItem('xsrfToken')
    const [isWaiting, setIsWaiting] = useState(true);
    const [currentPage, setCurrentPage] = useState(1)
    const [limitElement, setLimitElement] = useState(20)
    const [totalPages, setTotalPages] = useState(0)
    const [countPerPage, setCountPerPage] = useState(0)
    const [countTotal, setCountTotal] = useState(0)

    const [arrayTimeSheets, setArrayTimeSheets] = useState([])
    const [timeSheetSearch, setTimeSheetSearch] = useState('')
    const [timeSheetSearchType, setTimeSheetSearchType] = useState('')

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const [isFirstSearchDone, setIsFirstSearchDone] = useState(false)
    const [currentPageBackup, setCurrentPageBackup] = useState(1)

    const urlParams = new URLSearchParams(window.location.search);
    const urlDateRange = urlParams.get('dateRange');

    let timerTimeSheetSearch = '';

    const manageTimeSheetSearch = (search) => {
        setIsWaiting(true)
        const timeSheetSearchOptions = {
            type: timeSheetSearchType !== '' ? timeSheetSearchType : null,
            startDate: startDate ? dateToEpoch(startDate) : null,
            endDate: endDate ? dateToEpoch(endDate) : null,
        }
        ApiDatabase.getTimeSheets({token, status: '3', search, searchOptions: timeSheetSearchOptions, limit: limitElement, page: currentPage},
            (data) => {
                setArrayTimeSheets(data.timeSheets)
                setTotalPages(data.countPages)
                setCountPerPage(limitElement)
                setCountTotal(data.count)
                setIsWaiting(false)
                setIsFirstSearchDone(true)
            },
            (err) => {
                if (err.name !== "CanceledError") {
                    console.log(err)
                }
            })
    }

    useEffect(() => {
        //split ,
        const missionSearchSplit = timeSheetSearch.split(/(?:,)+/);
        //const missionSearchSplit = [missionSearch];

        if (missionSearchSplit.length > 0) {
            timerTimeSheetSearch = setTimeout(async () => {
                await manageTimeSheetSearch(missionSearchSplit);
            }, 400);
        } else {
            manageTimeSheetSearch(false)
        }
        return () => clearTimeout(timerTimeSheetSearch);
    }, [companyData, refreshTime, currentPage, startDate, endDate, timeSheetSearchType, timeSheetSearch])

    useEffect(() => {
        setCurrentPage(1)
    }, [timeSheetSearch, timeSheetSearchType, startDate, endDate])

    const titles=['N° DE RELEVÉ', 'ENTREPRISE', 'MISSION', 'N° DE MISSION', 'INTÉRIMAIRE', 'ÉTAT', 'PÉRIODE']

    useEffect(() => {
        if(currentPageTimeSheetChecked !== currentPage) setCurrentPageBackup(currentPageTimeSheetChecked)
        if(timeSheetSearchTimeSheetChecked !== timeSheetSearch) setTimeSheetSearch(timeSheetSearchTimeSheetChecked)
        if(timeSheetSearchTypeTimeSheetChecked !== timeSheetSearchType) setTimeSheetSearchType(timeSheetSearchTypeTimeSheetChecked)
        if(dateRangeTimeSheetChecked !== dateRange) setDateRange(dateRangeTimeSheetChecked)
    }, [])

    useEffect(() => {
        if(isFirstSearchDone && currentPage !== currentPageBackup) {
            setCurrentPage(currentPageBackup)
        }
    }, [isFirstSearchDone])

    useEffect(() => {
        if(currentPage !== currentPageTimeSheetChecked) setCurrentPageTimeSheetChecked(currentPage)
        if(timeSheetSearch !== timeSheetSearchTimeSheetChecked) setTimeSheetSearchTimeSheetChecked(timeSheetSearch)
        if(timeSheetSearchType !== timeSheetSearchTypeTimeSheetChecked) setTimeSheetSearchTypeTimeSheetChecked(timeSheetSearchType)
        if(dateRange !== dateRangeTimeSheetChecked) setDateRangeTimeSheetChecked(dateRange)
    }, [currentPage, timeSheetSearch, timeSheetSearchType, dateRange])

    useEffect(() => {
        if (urlDateRange) {
            const dateRange = JSON.parse(urlDateRange);
            setDateRange([new Date(dateRange[0]), new Date(dateRange[1])]);
        }
    }, [urlDateRange]);

    return (
        <div className={'flex flex-col gap-8'}>
            <div className={'flex gap-4'}>
                <div className={'w-1/2 my-auto flex gap-2'}>
                    <div className={'w-full my-auto'}>
                        <div className={'w-full'}>
                            <div>
                                <label htmlFor="name" className='ft-sm text-gray-500 flex gap-1'>
                                    Recherche
                                    <Tooltip text={`La recherche se fait sur l'id du relevé d'heure, l'id bestt de l'entreprise, l'id de la mission, l'id bestt du contrat, l'id bestt de l'intérimaire, le nom de l'entreprise, le nom de l'intérimaire`} placement='right'>
                                        <div><InformationCircle wh={18} color={'#6B7280'}/></div>
                                    </Tooltip>
                                </label>
                                <input type="text"
                                       value={timeSheetSearch}
                                       name='lastname'
                                       className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg'
                                       onChange={(e) => setTimeSheetSearch(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={'my-auto'}>
                        <div>
                            <label htmlFor="name" className='ft-sm text-gray-500 flex gap-1'>
                                Options
                            </label>
                            <select className="w-full h-10 pl-2 pr-3 ft-b placeholder-gray-600 border rounded-lg"
                                    onChange={(e) => setTimeSheetSearchType(e.target.value)}
                                    value={timeSheetSearchType}
                            >
                                <option value="">Tous</option>
                                <option value="id_time_sheet">ID Relevé Heure</option>
                                <option value="id_company">ID Entreprise</option>
                                <option value="id_mission">ID Mission</option>
                                <option value="id_contract_bestt">ID BeSTT Contrat</option>
                                <option value="id_bestt_temp_worker">ID BeSTT Intérimaire</option>
                                <option value="name_company">Nom Entreprise</option>
                                <option value="temp_worker_name">Nom Intérimaire</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className={'w-1/2 my-auto flex justify-between gap-5'}>
                    <div className={'ml-auto'}>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Périodes
                                </div>
                                <div>
                                    <DatePicker
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => {
                                            setDateRange(update);
                                        }}
                                        isClearable={true}
                                        className='w-full min-w-[250px] h-10 pl-3 pr-8 ft-b border rounded-lg text-center'
                                        locale='fr-FR'
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            {
                isWaiting ? (
                    <>
                        <table className="animate-pulse w-full animate-fadeIn">
                            <tbody>
                            {
                                [1, 2, 3, 4, 5].map((i, index)=>(
                                    <tr key={index}>
                                        {
                                            titles?.map((title, index) => (
                                                <td key={index} className={'px-2 py-7'}>
                                                    <div className="h-2 bg-gray-200 rounded"></div>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                        {
                            (currentPage && totalPages > 1 && setCurrentPage) ? (
                                <div className={'px-8 py-4'}>
                                    <Pagination currentPage={currentPage} countPerPage={countPerPage} countTotal={countTotal} totalPages={totalPages} setCurrentPage={setCurrentPage}/>
                                </div>
                            ) : null
                        }
                    </>
                ) : (
                    <>
                        {arrayTimeSheets?.length > 0 ?
                            <Table champsBody={arrayTimeSheets} champsHeader={titles} currentPage={currentPage} countPerPage={countPerPage} countTotal={countTotal} totalPages={totalPages} setCurrentPage={setCurrentPage}/>
                            :
                            <EmptyState type={'time_sheet_check'}/>
                        }
                    </>
                )
            }
        </div>
    );
};

export default CheckedContent;