import PageForm from "../../../components/pageForm/PageForm";
import React, {useEffect, useState} from "react";
import Tooltip from "../../../components/tooltips/Tooltips";
import {ExternalLink, InformationCircle, Pencil, TrashIcon} from "../../../assets/icons";
import EmptyState from "../../../components/EmptyState/EmptyState";
import ApiDatabase from "server";
import {dateExp, dateToEpoch, dateWordShort} from "../../../helper/helper";
import CopyToClipboard from "../../../components/copyToClipboard";
import ThreeStateCheckbox from "../../../components/threeStateCheckbox/threeStateCheckbox";
import UseContext from "../../../hooks/useContext";
import useSlideOver from "../../../components/SlideOver/useSlideOver";
import {useNavigate} from "react-router-dom";
import DatePicker from "react-datepicker";
import Button from "../../../components/button";
import Banner from "../../../components/banner/banner";
import {toast} from "react-toastify";
import useModal from "../../../components/modal/useModal";
import usefetchdata from "../../../hooks/usefetchdata";
import Badge from "../../../components/badge/badge";
import AdminMissionDetails from "./adminMissionDetails";
import Pagination from "../../../components/pagination/pagination";

const MissionPeriod = () => {
    const {objID, setObjID}=UseContext()
    const {toggleSlideOver} = useSlideOver();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const idCompany = urlParams.get('idCompany');
    const [missionSearch, setMissionSearch] = useState('');
    const [searchMissionResultWaiting, setSearchMissionResultWaiting] = useState(false);
    const [searchMissionResult, setSearchMissionResult] = useState(false);
    const [isMissionSearchAssigned, setIsMissionSearchAssigned] = useState(null);
    const [isMissionSearchSigned, setIsMissionSearchSigned] = useState(null);
    const [isMissionSearchSignatureSend, setIsMissionSearchSignatureSend] = useState(null);
    const [isInfoMissionWaiting, setIsInfoMissionWaiting] = useState(false);
    const [isInfoMissionLoadData, setIsInfoMissionLoadData] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [statusMission, setStatusMission] = useState(0);
    const [statusNameMission, setStatusNameMission] = useState('Tous les status');
    const [editMission, setEditMission] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [limitElement, setLimitElement] = useState(20)
    const [totalPages, setTotalPages] = useState(0)
    const [countPerPage, setCountPerPage] = useState(0)
    const [countTotal, setCountTotal] = useState(0)

    const {toggle}=useModal()
    const {userData} = usefetchdata();
    const [role, setRole] = useState(500);

    useEffect(() => {
        if(userData?.role){
            setRole(userData.role)
        }
    }, [userData])

    let timerMissionSearch = '';
    const token = localStorage.getItem('xsrfToken')

    const manageMissionSearch = async (missionSearchSplit) => {
        setSearchMissionResultWaiting(true)

        setIsInfoMissionLoadData(false)
        setIsInfoMissionWaiting(false)
        setEditMission(false)

        ApiDatabase.searchMission({token: token, search: missionSearchSplit, isMissionSearchAssigned, isMissionSearchSigned, isMissionSearchSignatureSend, statusMission, limit: limitElement, page: currentPage}, (data) => {
            setSearchMissionResultWaiting(false)
            setSearchMissionResult(data.missions)
            setTotalPages(data.countPages)
            setCountPerPage(limitElement)
            setCountTotal(data.count)

            if (data.missions.length === 1) {
                setEditMission(data.missions[0]._id)
            }
        }, (err) => {
            if (err.name !== "CanceledError") {
                setSearchMissionResultWaiting(false)
                setSearchMissionResult(false)
                console.error(err);
            }
        })
    }

    useEffect(() => {
        //split ,
        const missionSearchSplit = missionSearch.split(/(?:,)+/);
        //const missionSearchSplit = [missionSearch];

        if (missionSearchSplit.length > 0) {
            timerMissionSearch = setTimeout(async () => {
                await manageMissionSearch(missionSearchSplit);
            }, 400);
        } else {
            searchMissionResult(false)
        }
        return () => clearTimeout(timerMissionSearch);
    }, [missionSearch, isMissionSearchAssigned, isMissionSearchSigned, isMissionSearchSignatureSend, statusMission, currentPage])

    useEffect(() => {
        manageMissionSearch(false);
    }, [])

    useEffect(() => {
        if(isMissionSearchAssigned === false){
            setIsMissionSearchSigned(false)
            setIsMissionSearchSignatureSend(false)
        }
    }, [isMissionSearchAssigned])

    useEffect(() => {
        if(isMissionSearchSigned === true) {
            setIsMissionSearchAssigned(true)
            setIsMissionSearchSignatureSend(true)
        }
    }, [isMissionSearchSigned])

    useEffect(() => {
        if(isMissionSearchSignatureSend === true) {
            setIsMissionSearchAssigned(true)
        } else if(isMissionSearchSignatureSend === false) {
            setIsMissionSearchSigned(false)
        }
    }, [isMissionSearchSignatureSend])

    const openCompanyInfo = (id) => {
        setObjID(id)
        toggleSlideOver('get_company')
        navigate('/missions?idCompany=' + id);
    }

    useEffect(() => {
        if(idCompany) {
            if(!objID){
                openCompanyInfo(idCompany)
            } else {
                if(idCompany !== objID) {
                    openCompanyInfo(idCompany)
                }
            }
        }
    }, [idCompany])

    useEffect(() => {
        if(statusMission === 0) setStatusNameMission('Tous les status')
        if(statusMission === 1) setStatusNameMission('Terminé')
        if(statusMission === 2) setStatusNameMission('En cours')
        if(statusMission === 3) setStatusNameMission('À venir')
    }, [statusMission])

    useEffect(() => {
        if(role){
            if(role === 525){
                setIsMissionSearchAssigned(true)
                setIsMissionSearchSignatureSend(false)
            }
        }
    }, [role])

    const shouldShowTrashButton = (mission) => {
        if (mission.status === 'cancel') return 'delete'

        const now = new Date();
        const startDate = new Date(mission.duration.datestart);
        const endDate = new Date(mission.duration.dateend);
        const hasAssignedUser = mission.user ? typeof mission.user === 'object' && true : false

        let missionStatus;
        if (now < startDate) {
            missionStatus = 'soon';
        } else if (now > endDate) {
            missionStatus = 'past';
        } else {
            missionStatus = 'in_progress';
        }

        if (missionStatus === 'soon' && hasAssignedUser) {
            return 'cancel';
        }

        if ((missionStatus === 'in_progress' || missionStatus === 'soon' || missionStatus === 'past') && !hasAssignedUser) {
            return 'delete';
        }

        return false;
    }

    const handleDeleteMission = (idMission) => {
        setObjID(idMission)
        toggle('mission_delete')
    }

    const handleCancelMission = (idMission) => {
        setObjID(idMission)
        toggle('mission_cancel')
    }

    return (
        <PageForm>
            <div className={'p-3 flex flex-col w-full h-full gap-3'}>
                {/*Search part*/}
                <div className={'flex gap-4'}>
                    <div className={'w-1/2 my-auto'}>
                        <div className={'w-full'}>
                            <div>
                                <label htmlFor="name" className='ft-sm text-gray-500 flex gap-1'>
                                    Mission
                                    <Tooltip text={`La recherche se fait sur l'id de la mission, le numéro du contrat BeSTT, le nom de l'entreprise ou le nom de l'intérimaire.`} placement='right'>
                                        <div><InformationCircle wh={18} color={'#6B7280'}/></div>
                                    </Tooltip>
                                </label>
                                <input type="text"
                                       value={missionSearch}
                                       name='lastname'
                                       className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg'
                                       onChange={(e) => setMissionSearch(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    {/*<div className={'w-1/3 my-auto flex flex-wrap gap-3'}>
                        <div className={'my-auto'}>
                            <Button size={'SM'} color={'SECONDARY'}>
                                Missions en attente d'attribution
                            </Button>
                        </div>
                        <div className={'my-auto'}>
                            <Button size={'SM'} color={'SECONDARY'}>
                                Missions à envoyer pour signature
                            </Button>
                        </div>
                    </div>*/}
                    <div className={'w-1/2 my-auto flex justify-between gap-5'}>
                        <div className={'my-auto flex flex-wrap gap-3'}>
                            <div className={'my-auto'}>
                                <label className={'flex gap-1 cursor-pointer'}>
                                    <div className={'my-auto'}>
                                        <ThreeStateCheckbox checked={isMissionSearchAssigned} onChange={setIsMissionSearchAssigned}/>
                                    </div>
                                    <div className={'my-auto'}>
                                        Missions attribuées
                                    </div>
                                    <div className={'my-auto'}>
                                        <Tooltip text={
                                            isMissionSearchAssigned === null ?
                                                `La recherche se fait sur les missions attribuées ou non.` :
                                                isMissionSearchAssigned === true ?
                                                    `La recherche se fait sur les missions attribuées.` :
                                                    `La recherche se fait sur les missions non attribuées.`
                                        } placement='right'>
                                            <div><InformationCircle wh={18} color={'#6B7280'}/></div>
                                        </Tooltip>
                                    </div>
                                </label>
                            </div>
                            <div className={''}>
                                <label className={'flex gap-1 cursor-pointer'}>
                                    <div className={'my-auto'}>
                                        <ThreeStateCheckbox checked={isMissionSearchSigned} onChange={setIsMissionSearchSigned}/>
                                    </div>
                                    <div className={'my-auto'}>
                                        Contrats signées
                                    </div>
                                    <div className={'my-auto'}>
                                        <Tooltip text={
                                            isMissionSearchSigned === null ?
                                                `La recherche se fait sur les contrats signées ou non.` :
                                                isMissionSearchSigned === true ?
                                                    `La recherche se fait sur les contrats signées.` :
                                                    `La recherche se fait sur les contrats non signées.`
                                        } placement='right'>
                                            <div><InformationCircle wh={18} color={'#6B7280'}/></div>
                                        </Tooltip>
                                    </div>
                                </label>
                            </div>
                            <div className={''}>
                                <label className={'flex gap-1 cursor-pointer'}>
                                    <div className={'my-auto'}>
                                        <ThreeStateCheckbox checked={isMissionSearchSignatureSend} onChange={setIsMissionSearchSignatureSend}/>
                                    </div>
                                    <div className={'my-auto'}>
                                        Contrats envoyés pour signature
                                    </div>
                                    <div className={'my-auto'}>
                                        <Tooltip text={
                                            isMissionSearchSignatureSend === null ?
                                                `La recherche se fait sur les contrats envoyés pour signature ou non.` :
                                                isMissionSearchSignatureSend === true ?
                                                    `La recherche se fait sur les contrats envoyés pour signature.` :
                                                    `La recherche se fait sur les contrats non envoyés pour signature.`
                                        } placement='right'>
                                            <div><InformationCircle wh={18} color={'#6B7280'}/></div>
                                        </Tooltip>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={'my-auto whitespace-nowrap'}>
                            <div className='relative flex items-center justify-center' onClick={() => setDropdownOpen(!dropdownOpen)}>
                                <Button size={'SM'} color={'DEFAULT'} onClick={() => setDropdownOpen(!dropdownOpen)}>
                                    {statusNameMission}
                                </Button>
                                <div className={`${dropdownOpen ? "" : "hidden"} absolute border-2 border-[#F3F4F6] rounded-lg shadow-lg w-48 px-4 py-2 top-10 right-1  bg-gray-50 flex flex-col gap-2`}>
                                    <div className='flex flex-row items-center gap-3 cursor-pointer ft-sm' onClick={() => setStatusMission(0)}>
                                        Tous les status
                                    </div>
                                    <div className='flex flex-row items-center gap-3 cursor-pointer ft-sm' onClick={() => setStatusMission(1)}>
                                        Terminé
                                    </div>
                                    <div className='flex flex-row items-center gap-3 cursor-pointer ft-sm' onClick={() => setStatusMission(2)}>
                                        En cours
                                    </div>
                                    <div className='flex flex-row items-center gap-3 cursor-pointer ft-sm' onClick={() => setStatusMission(3)}>
                                        À venir
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'flex flex-1 w-full h-full gap-3 overflow-y-auto'}>
                    {
                        searchMissionResultWaiting ? (
                            <>
                                <div className={'flex-1 animate-pulse'}>
                                    <div className={'flex flex-col gap-4 h-full'}>
                                        <div className={'flex gap-1 text-base text-gray-500'}>
                                            <div className={'my-auto'}>Total :</div>
                                            <div className={'my-auto'}>
                                                <div className="w-12 h-2 bg-gray-200 rounded"></div>
                                            </div>
                                        </div>
                                        <div className={'flex flex-1 flex-col gap-4 overflow-y-auto'}>

                                            {
                                                [1, 2, 3, 4, 5].map((i, index)=>(
                                                    <div key={index} className={'flex gap-2 border rounded px-3 py-2 justify-between'}>
                                                        <div className={'flex flex-col gap-4'}>
                                                            <div><div className="w-80 h-2 bg-gray-200 rounded"></div></div>
                                                            <div><div className="w-40 h-2 bg-gray-200 rounded"></div></div>
                                                            <div className={'mt-2 flex gap-1'}>
                                                                <div className="w-40 h-2 bg-gray-200 rounded"></div>
                                                            </div>
                                                            <div><div className="w-56 h-2 bg-gray-200 rounded"></div></div>
                                                            <div><div className="w-56 h-2 bg-gray-200 rounded"></div></div>
                                                            <div><div className="w-56 h-2 bg-gray-200 rounded"></div></div>
                                                            <div className={'mt-2'}><div className="w-72 h-2 bg-gray-200 rounded"></div></div>
                                                        </div>
                                                        <div className={''}>
                                                            <div className={'border rounded p-1'}>
                                                                <div className="w-4 h-4 bg-gray-200 rounded"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={'flex-1 animate-pulse'}>

                                </div>
                            </>
                        ) : (
                            <>
                                {
                                    searchMissionResult.length > 0 ? (
                                        <>
                                            <div className={'flex-1 h-full'}>
                                                <div className={'flex flex-col gap-4 max-h-full w-full'}>
                                                    <div className={'flex gap-1 text-base text-gray-500'}>
                                                        Total : {countTotal}
                                                    </div>
                                                    <div className={'flex flex-col gap-4 overflow-y-auto'}>
                                                        {
                                                            searchMissionResult.map((mission, index) => {
                                                                return (
                                                                    <div key={index} className={'flex gap-2 border rounded px-3 py-2 justify-between cursor-pointer'} onClick={() => setEditMission(mission._id)}>
                                                                        <div className={'flex flex-col w-full'}>
                                                                            <div className={'flex gap-1 justify-between'}>
                                                                                <div>{mission.title} n°{mission.id_mission}</div>
                                                                                {shouldShowTrashButton(mission) === 'delete' && (
                                                                                    <Button size={'SMI'} color={'SECONDARY'} onClick={() => handleDeleteMission(mission._id)}>
                                                                                        <TrashIcon wh={20} color={'#374151'}/>
                                                                                    </Button>
                                                                                )}
                                                                                {shouldShowTrashButton(mission) === 'cancel' && (
                                                                                    <Button size={'SMI'} color={'SECONDARY'} onClick={() => handleCancelMission(mission._id)}>
                                                                                        <TrashIcon wh={20} color={'#374151'}/>
                                                                                    </Button>
                                                                                )}
                                                                            </div>
                                                                            <div>{dateWordShort(mission.duration.datestart) + ' > ' + dateWordShort(mission.duration.dateend)}</div>
                                                                            <div className={'mt-2 flex gap-1'}>
                                                                                <div className={'whitespace-nowrap'}>
                                                                                    Entreprise :
                                                                                </div>
                                                                                <div className={'my-auto'}>
                                                                                    {
                                                                                        (mission.company.reason_social === mission.company.name_commercial) ?
                                                                                            mission.company.reason_social :
                                                                                            (mission.company.reason_social + ' => ' + mission.company.name_commercial)
                                                                                    }
                                                                                    {
                                                                                        mission.company.id_bestt ? ' n°' + mission.company.id_bestt : ''
                                                                                    }
                                                                                </div>
                                                                                <div className={'my-auto cursor-pointer'} onClick={() => openCompanyInfo(mission.company._id)}>
                                                                                    <ExternalLink wh={18} color={'#235fe6'}/>
                                                                                </div>
                                                                            </div>
                                                                            <div>Contrat bestt : {mission.contract_company[0]?.id_bestt ? 'n°' + mission.contract_company[0]?.id_bestt : "Aucun contrat bestt"}</div>
                                                                            <div>Contrat entreprise : {mission.contract_company[0]?.status === "1" ? 'Signé' : 'Non signé'}</div>
                                                                            <div>Contrat intérimaire : {mission.contract_tempworker[0]?.status === "1" ? 'Signé' : 'Non signé'}</div>
                                                                            <div className={'mt-2'}>Intérimaire : {mission.user ? '' : 'Aucun utilisateur assigné'}</div>
                                                                            {
                                                                                mission.user && (
                                                                                    <>
                                                                                        <div className={'flex gap-1'}>
                                                                                            <div className={'my-auto'}>-</div>
                                                                                            <div className={'my-auto'}>
                                                                                                <CopyToClipboard
                                                                                                    text={mission.user.firstname + ' ' + mission.user.lastname}>
                                                                                                    {mission.user.firstname + ' ' + mission.user.lastname}
                                                                                                </CopyToClipboard>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className={'flex gap-1'}>
                                                                                            <div className={'my-auto'}>-</div>
                                                                                            <div className={'my-auto'}>
                                                                                                <CopyToClipboard
                                                                                                    text={mission.user.email}>
                                                                                                    <a className={'underline'}
                                                                                                       href={`mailto:${mission.user.email}`}>
                                                                                                        {mission.user.email}
                                                                                                    </a>
                                                                                                </CopyToClipboard>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className={'flex gap-1'}>
                                                                                            <div className={'my-auto'}>-
                                                                                            </div>
                                                                                            <div className={'my-auto'}>
                                                                                                <CopyToClipboard
                                                                                                    text={mission.user.phone}>
                                                                                                    <a className={'underline'}
                                                                                                       href={`tel:${mission.user.phone}`}>
                                                                                                        {mission.user.phone}
                                                                                                    </a>
                                                                                                </CopyToClipboard>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    {
                                                        (currentPage && totalPages > 1 && setCurrentPage) ? (
                                                            <Pagination currentPage={currentPage} countPerPage={countPerPage} countTotal={countTotal} totalPages={totalPages} setCurrentPage={setCurrentPage}/>
                                                        ) : null
                                                    }
                                                </div>
                                            </div>
                                            <div className={'flex-1'}>
                                                {editMission ? (
                                                    <AdminMissionDetails missionId={editMission}
                                                                         searchMissionResult={searchMissionResult}
                                                                         setSearchMissionResult={setSearchMissionResult}/>
                                                ) : (
                                                    <EmptyState type={'select_mission'}/>
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <EmptyState type={'mission_search'}/>
                                        </>
                                    )
                                }
                            </>
                        )
                    }
                </div>
            </div>
        </PageForm>
    );
}

export default MissionPeriod;