import React, {useState, useEffect, useContext} from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import ApiDatabase from "server"
import useModal from "components/modal/useModal";
import usefetchdata from "hooks/usefetchdata";
import {toast} from "react-toastify";
import UseContext from "hooks/useContext";

const ModalLangue = () => {
    const {refreshLoadWorker} = UseContext()
    const {tempWorkerData} = usefetchdata()
    const {toggle} = useModal()
    const [language, setLanguage] = useState('')
    const [datas, setDatas] = useState([])

    const id = localStorage.getItem('xsrfToken')
    useEffect(() => {
        ApiDatabase.getListLanguages(
            (data) => {
                setDatas(data)
            }, (status, err) => {
                if (err.name !== "CanceledError") {
                    console.log(err)
                }
            }
        )
    }, [setDatas])

    const handleSubmit = (e) => {
        e.preventDefault()
        if (language !== '') {
            const lang = language.split(' - ')[0]
            let tabLangUser = tempWorkerData?.language
            const indexTab = tabLangUser.findIndex(i => i.split(' - ')[0] === lang)
            tabLangUser[indexTab] = language
            if (indexTab === -1) tabLangUser = [...tabLangUser, language]
            ApiDatabase.postUserLanguage({id, language: tabLangUser},
                (data) => {
                    if (data.errors) {
                        console.log(data.errors)
                        toast.error('Une erreur est survenue')
                    } else {
                        refreshLoadWorker()
                        toast.success(`La langue ${language} est ajoutée`)
                    }
                },
                (status, err) => {
                    if (err.name !== "CanceledError") {
                        console.log(err)
                    }
                }
            )
        }
    }


    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>Ajouter une langue</Modalheader>
            <ModalBody>
                <form className='mt-4'>
                    <select name="tool" size='1'
                            className="w-full h-10 pl-3 pr-8 ft-b placeholder-gray-600 border rounded-lg  "
                            onChange={(e) => {
                                setLanguage(e.target.value)
                            }}>
                        <option value="">Selection</option>
                        {datas.map((data) =>
                            <option key={data._id}
                                    value={`${data.name}`}>{data.name}</option>
                        )}

                    </select>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'}
                        onClick={toggle}>Annuler</Button>
                <Button size={'LG'} onClick={handleSubmit}>Confirmer</Button>
            </ModalFooter>
        </>
    );
};

export default ModalLangue;