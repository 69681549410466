import SlideOverHeader from "../../../component/header";
import React, {useEffect, useState} from "react";
import SlideOverBody from "../../../component/body";
import useSlideOver from "../../../useSlideOver";
import UseContext from "../../../../../hooks/useContext";
import ApiDatabase from "server";
import {dateToEpoch, dateWordShort} from "../../../../../helper/helper";
import CopyToClipboard from "../../../../copyToClipboard";
import Button from "../../../../button";
import {ArchiveIcon, ChevronDown, ChevronRight, ExternalLink} from "../../../../../assets/icons";
import BadgeSquare from "../../../../badge/badgeSquare";
import UseModal from "../../../../modal/useModal";
import PreviewTempWorker from "./previewTempWorker";
import ReviewTempWorker from "./reviewTempWorker";
import usefetchdata from "../../../../../hooks/usefetchdata";
import CommentTempWorker from "./commentTempWorker";
import Badge from "../../../../badge/badge";
import HistoryTempWorker from "./historyTempWorker";

const SlideOverTempWorker = () => {
    const {toggle} = UseModal();
    const {toggleSlideOver} = useSlideOver();
    const {objID, setObjID, setAdminLink, setAdminType, setAdminTempWorkerId, removeAllParameters, openModal, setOpenModal}=UseContext()
    const {userData} = usefetchdata()
    const token = localStorage.getItem('xsrfToken')
    const [tempWorkerInfos, setTempWorkerInfos] = useState({
        tempWorker: {},
        user: {},
        viviers: {},
    });
    const [activeTab, setActiveTab] = useState(1);
    const [nbReviews, setNbReviews] = useState(0);
    const [nbComments, setNbComments] = useState(0)
    const [userArchiveReason, setUserArchiveReason] = useState(false)

    const openDocument = (type, uri) => {
        setAdminLink(uri);
        setAdminType(type);
        setAdminTempWorkerId('');
        toggle('ad_file_view');
    }

    useEffect(() => {
        if(openModal){
            toggle(openModal)
            setOpenModal(false)
        }
    }, [openModal]);

    useEffect(() => {
        if(objID){
            ApiDatabase.getUserById({token, id_user: objID},
                (data) => {
                    const birthDay = dateToEpoch(data.birthday);
                    setTempWorkerInfos(prevData => ({
                        ...prevData,
                        user: {
                            ...data,
                            birthday: (('0' + birthDay.getDate()).toString().slice(-2)) + '/' + (('0' + (parseInt(birthDay.getMonth()) + 1)).toString().slice(-2)) + '/' + birthDay.getFullYear(),
                        },
                    }));
                    ApiDatabase.getTempWorkerByIdUser({token, idUser: objID},
                        (data) => {
                            setTempWorkerInfos(prevData => ({
                                ...prevData,
                                tempWorker: data,
                            }));
                        }, (err) => {
                            if (err.name !== "CanceledError") {
                                console.log(err)
                            }
                        })
                },
                (err) => {
                    if (err.name !== "CanceledError") {
                        console.log(err)
                    }
                });

            ApiDatabase.getNbReviewsTempWorker({token, idUser: objID}, (data) => {
                setNbReviews(data.nbReviews);
            }, (err) => {
                if (err.name !== "CanceledError") {
                    console.log(err)
                }
            })

            ApiDatabase.getNbCommentsByTempWorker({token, idUser: objID}, (data) => {
                setNbComments(data.count);
            }, (err) => {
                if (err.name !== "CanceledError") {
                    console.log(err)
                }
            })

            ApiDatabase.getUserVivierArchiveReason({token, idUser: objID}, (data) => {
                setUserArchiveReason(data?.reason || false);
            }, (err) => {
                if (err.name !== "CanceledError") {
                    console.log(err)
                }
            })

            ApiDatabase.getUserViviers({token, idUser: objID}, (data) => {
                setTempWorkerInfos(prevData => ({
                    ...prevData,
                    viviers: data || [],
                }));
            }, (err) => {
                if (err.name !== "CanceledError") {
                    console.log(err)
                }
            })
        }
    }, [objID]);

    const closeSlideOver = () => {
        toggleSlideOver()
        removeAllParameters()
    }

    return (
        <>
            <SlideOverHeader hide={closeSlideOver} hideShow={true}>
                <div className={'text-lg'}>
                    {tempWorkerInfos?.user?.firstname} {tempWorkerInfos?.user?.lastname}
                </div>
                <div className={'flex gap-2 text-gray-500 text-sm items-center'}>
                    <div className={'flex gap-1 items-center'}>
                        <div className={''}>{tempWorkerInfos?.user.token_refresh_mobile ? <Badge type={'APP'}>App</Badge> : ""}</div>
                        <div className={''}>{tempWorkerInfos?.user.token_refresh ? <Badge type={'WEB'}>Web</Badge> : ""}</div>
                    </div>
                    <div className={''}>·</div>
                    <div className={''}>Inscrit depuis le {dateWordShort(new Date(tempWorkerInfos?.user.createdAt))}</div>
                </div>
            </SlideOverHeader>
            <SlideOverBody>
                <div className={'flex flex-col gap-6'}>
                    {userArchiveReason && (
                        <div className={'flex gap-2 border rounded bg-gray-50 px-3 py-2'}>
                            <div className={''}>
                                <ArchiveIcon wh={20} color={'#374151'}/>
                            </div>
                            <div className={''}>
                                <div className={'flex flex-col gap-2'}>
                                    <div className={''}>Profil archivé</div>
                                    <div className={'flex flex-col text-gray-500 text-sm'}>
                                        <div className={''}>
                                            Le profil est archivé, son statut sera actualisée à sa prochaine mise à jour de disponibilités.
                                        </div>
                                        <div className={'flex gap-2'}>
                                            <div className={''}>Raison :</div>
                                            <div className={'font-black'}>
                                                {userArchiveReason === 'admin' && `L'administrateur a archivé le profil`}
                                                {userArchiveReason === '45 days absence' && `Inactivité durant 45 jours`}
                                                {userArchiveReason === 'unavailable' && `Le profil est indisponible`}
                                                {userArchiveReason === 'exclude' && `Exclus de l'agence`}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={'flex justify-between gap-2 py-2'}>
                        <div className={'my-auto flex gap-2 text-sm'}>
                            <div onClick={() => setActiveTab(1)}
                                 className={`flex items-center justify-center  py-3 px-4 cursor-pointer text-gray-500 h-9 ${activeTab === 1 ? ' bg-gray-100 text-gray-700 rounded-md ' : 'hover:bg-gray-100 hover:rounded-md hover:text-gray-700'}`}
                            >
                                Aperçu
                            </div>
                                    <div onClick={() => setActiveTab(2)}
                                         className={`flex items-center justify-center  py-3 px-4 cursor-pointer text-gray-500 h-9 ${activeTab === 2 ? ' bg-gray-100 text-gray-700 rounded-md ' : 'hover:bg-gray-100 hover:rounded-md hover:text-gray-700'}`}
                                    >
                                        <div className={'flex gap-1'}>
                                            <div className={'my-auto'}>
                                                Avis
                                            </div>
                                            <div
                                                className="my-auto bg-yellow-100 text-yellow-800 rounded px-1 flex items-center justify-center min-w-[20px]">
                                                {nbReviews}
                                            </div>
                                        </div>
                                    </div>
                            <div onClick={() => setActiveTab(3)}
                                 className={`flex items-center justify-center  py-3 px-4 cursor-pointer text-gray-500 h-9 ${activeTab === 3 ? ' bg-gray-100 text-gray-700 rounded-md ' : 'hover:bg-gray-100 hover:rounded-md hover:text-gray-700'}`}
                            >
                                <div className={'flex gap-1'}>
                                    <div className={'my-auto'}>
                                        Commentaires
                                    </div>
                                    <div
                                        className="my-auto bg-yellow-100 text-yellow-800 rounded px-1 flex items-center justify-center min-w-[20px]">
                                        {nbComments}
                                    </div>
                                </div>
                            </div>
                            <div onClick={() => setActiveTab(4)}
                                 className={`flex items-center justify-center  py-3 px-4 cursor-pointer text-gray-500 h-9 ${activeTab === 4 ? ' bg-gray-100 text-gray-700 rounded-md ' : 'hover:bg-gray-100 hover:rounded-md hover:text-gray-700'}`}
                            >
                                <div className={'flex gap-1'}>
                                    <div className={'my-auto'}>
                                        Historique
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'my-auto'}>
                            {
                                tempWorkerInfos?.tempWorker?.cv?.file?.filePath ? (
                                    <>
                                        {(() => {
                                            const filePath = process.env.REACT_APP_FILE + tempWorkerInfos?.tempWorker?.cv?.file?.filePath

                                            return (
                                                <div onClick={() => openDocument('cv', {filePath})}>
                                                    <Button color={'SECONDARY'}>CV</Button>
                                                </div>
                                            )
                                        })()}
                                    </>
                                ) : (
                                    <Button color={'DISABLED'}>CV</Button>
                                )
                            }
                        </div>
                    </div>
                    {activeTab === 1 && <PreviewTempWorker tempWorkerInfos={tempWorkerInfos}/>}
                    {activeTab === 2 && <ReviewTempWorker idUser={tempWorkerInfos?.user?._id}/>}
                    {activeTab === 3 && <CommentTempWorker idUser={tempWorkerInfos?.user?._id}/>}
                    {activeTab === 4 && <HistoryTempWorker idUser={tempWorkerInfos?.user?._id}/>}
                </div>
            </SlideOverBody>
        </>
    )
}

export default SlideOverTempWorker;