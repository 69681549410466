import React, {useEffect, useState} from "react";
import ApiDatabase from "../../../server";
import {dateToEpoch} from "../../../helper/helper";
import useModal from "../../../components/modal/useModal";
import usefetchdata from "../../../hooks/usefetchdata";
import {toast} from "react-toastify";
import Banner from "../../../components/banner/banner";
import DatePicker from "react-datepicker";
import Button from "../../../components/button";
import Tooltip from "../../../components/tooltips/Tooltips";
import {
    ChevronDown,
    ChevronTop,
    Close,
    InformationCircle,
    TrashIcon,
} from "../../../assets/icons";
import CopyToClipboard from "../../../components/copyToClipboard";
import Badge from "../../../components/badge/badge";
import Pagination from "../../../components/pagination/pagination";
import UseContext from "hooks/useContext";
import MissionDetailTempWorker from "./missionDetailTempWorker";
import Divider from "../../../components/divider/divider";
import Schedule from "../../../components/schedule/schedule";

const AdminMissionDetails = ({missionId, searchMissionResult, setSearchMissionResult}) => {
    const {userUnAssigned, setUserUnAssigned, modalDataReturn, setModalDataReturn, setObjID} = UseContext()
    const [missionDateStart, setMissionDateStart] = useState('')
    const [originalMissionDateStart, setOriginalMissionDateStart] = useState('')
    const [missionDateEnd, setMissionDateEnd] = useState('')
    const [originalMissionDateEnd, setOriginalMissionDateEnd] = useState('')
    const [isModificationDateMissionAffectBesttContract, setIsModificationDateMissionAffectBesttContract] = useState(false)
    const [searchTempWorker, setSearchTempWorker] = useState('')
    const [searchTempWorkerResultWaiting, setSearchTempWorkerResultWaiting] = useState(false)
    const [searchTempWorkerResultLoadData, setSearchTempWorkerResultLoadData] = useState(false)
    const [searchTempWorkerResult, setSearchTempWorkerResult] = useState(false)
    const [isTempWorkerResultCompleteOpen, setIsTempWorkerResultCompleteOpen] = useState(true)
    const [isTempWorkerResultPartialOpen, setIsTempWorkerResultPartialOpen] = useState(true)
    const [isTempWorkerResultNotUpdatedOpen, setIsTempWorkerResultNotUpdatedOpen] = useState(true)
    const [isTempWorkerResultIncompleteOpen, setIsTempWorkerResultIncompleteOpen] = useState(true)
    const [isAssignationMissionAffectAssignedMission, setIsAssignationMissionAffectAssignedMission] = useState(false)
    const [selectedTempWorkerAssignation, setSelectedTempWorkerAssignation] = useState(false)
    const [errorAssignationMissionIdentityFiles, setErrorAssignationMissionIdentityFiles] = useState(false)
    const [loadingSignatureMission, setLoadingSignatureMission] = useState(false);
    const [loadingContractGeneration, setLoadingContractGeneration] = useState(false);
    const [typeSearchTempWorkerAssignation, setTypeSearchTempWorkerAssignation] = useState(1);
    const [countTempWorkerPostulate, setCountTempWorkerPostulate] = useState(0);
    const [countUsersInvite, setCountUsersInvite] = useState(0);
    const [infoMission, setInfoMission] = useState(false)
    const [isInfoMissionWaiting, setIsInfoMissionWaiting] = useState(false)
    const [loadingAssignationMission, setLoadingAssignationMission] = useState(false);
    const [loadingModificationDateMission, setLoadingModificationDateMission] = useState(false);
    const [isModificationDateMissionDifferentFromOriginal, setIsModificationDateMissionDifferentFromOriginal] = useState(false)

    const [searchTempWorkerAssignationResult, setSearchTempWorkerAssignationResult] = useState(false)
    const [searchTempWorkerAssignationResultWaiting, setSearchTempWorkerAssignationResultWaiting] = useState(false)
    const [searchTempWorkerAssignation, setSearchTempWorkerAssignation] = useState('')
    const [searchTempWorkerAssignationResultLoadData, setSearchTempWorkerAssignationResultLoadData] = useState(false)

    const [searchTempWorkerInviteResult, setSearchTempWorkerInviteResult] = useState([])
    const [searchTempWorkerInviteResultWaiting, setSearchTempWorkerInviteResultWaiting] = useState(false)

    const [currentPageTempWorker, setCurrentPageTempWorker] = useState(1)
    const [limitElementTempWorker, setLimitElementTempWorker] = useState(20)
    const [totalPagesTempWorker, setTotalPagesTempWorker] = useState(0)
    const [countPerPageTempWorker, setCountPerPageTempWorker] = useState(0)
    const [countTotalTempWorker, setCountTotalTempWorker] = useState(0)

    const [currentPageTempWorkerAssignation, setCurrentPageTempWorkerAssignation] = useState(1)
    const [limitElementTempWorkerAssignation, setLimitElementTempWorkerAssignation] = useState(20)
    const [totalPagesTempWorkerAssignation, setTotalPagesTempWorkerAssignation] = useState(0)
    const [countPerPageTempWorkerAssignation, setCountPerPageTempWorkerAssignation] = useState(0)
    const [countTotalTempWorkerAssignation, setCountTotalTempWorkerAssignation] = useState(0)

    const {toggle}=useModal()
    const {userData} = usefetchdata();
    const [role, setRole] = useState(500);

    let timerTempWorkerSearch = '';
    let timerTempWorkerAssignationSearch = '';
    const token = localStorage.getItem('xsrfToken')

    useEffect(() => {
        setMissionDateStart('')
        setOriginalMissionDateStart('')
        setMissionDateEnd('')
        setOriginalMissionDateEnd('')
        setIsModificationDateMissionAffectBesttContract(false)
        setSearchTempWorker('')
        setSearchTempWorkerResultWaiting(false)
        setSearchTempWorkerResultLoadData(false)
        setSearchTempWorkerResult(false)
        setIsAssignationMissionAffectAssignedMission(false)
        setSelectedTempWorkerAssignation(false)
        setErrorAssignationMissionIdentityFiles(false)
        setLoadingSignatureMission(false)
        setLoadingContractGeneration(false)
        setTypeSearchTempWorkerAssignation(1)
        setCountTempWorkerPostulate(0)
        setCountUsersInvite(0)
        setInfoMission(false)
        setIsInfoMissionWaiting(false)
        setLoadingAssignationMission(false)
        setLoadingModificationDateMission(false)
        setIsModificationDateMissionDifferentFromOriginal(false)
        setSearchTempWorkerAssignationResult(false)
        setSearchTempWorkerInviteResult([])
        setSearchTempWorkerAssignationResultWaiting(false)
        setSearchTempWorkerInviteResultWaiting(false)
        setSearchTempWorkerAssignation('')
        setSearchTempWorkerAssignationResultLoadData(false)

        setCurrentPageTempWorker(1)
        setLimitElementTempWorker(20)
        setTotalPagesTempWorker(0)
        setCountPerPageTempWorker(0)
        setCountTotalTempWorker(0)

        setCurrentPageTempWorkerAssignation(1)
        setLimitElementTempWorkerAssignation(20)
        setTotalPagesTempWorkerAssignation(0)
        setCountPerPageTempWorkerAssignation(0)
        setCountTotalTempWorkerAssignation(0)

        editMission()
    }, [missionId])

    const editMission = () => {
        setIsInfoMissionWaiting(true)
        ApiDatabase.getMissionDetailsById({token: token, idMission: missionId}, (data) => {
            setInfoMission(data)
            setIsInfoMissionWaiting(false)
        }, (err) => {
            if (err.name !== "CanceledError") {
                console.error(err);
                setInfoMission(false)
                setIsInfoMissionWaiting(false)
            }
        })
    }

    const shouldShowTrashButton = (mission) => {
        if (mission.status === 'cancel' || mission.status === 'delete') return false;

        const now = new Date();
        const startDate = new Date(mission.duration.datestart);
        const endDate = new Date(mission.duration.dateend);
        const hasAssignedUser = mission.user_assigned;

        let missionStatus;
        if (now < startDate) {
            missionStatus = 'soon';
        } else if (now > endDate) {
            missionStatus = 'past';
        } else {
            missionStatus = 'in_progress';
        }

        if (missionStatus === 'soon' && hasAssignedUser) {
            return 'cancel';
        }

        if ((missionStatus === 'in_progress' || missionStatus === 'soon' || missionStatus === 'past') && !hasAssignedUser) {
            return 'delete';
        }

        return false;
    }

    useEffect(() => {
        if(infoMission){
            if(infoMission.duration?.datestart){
                setMissionDateStart(dateToEpoch(infoMission.duration.datestart))
                setOriginalMissionDateStart(dateToEpoch(infoMission.duration.datestart))
            }
            if(infoMission.duration?.dateend){
                setMissionDateEnd(dateToEpoch(infoMission.duration.dateend))
                setOriginalMissionDateEnd(dateToEpoch(infoMission.duration.dateend))
            }

            if(infoMission.users?.length > 0){
                setCountTempWorkerPostulate(infoMission.users.length)
                setTypeSearchTempWorkerAssignation(1)
            } else {
                setCountTempWorkerPostulate(0)
                setTypeSearchTempWorkerAssignation(2)
            }

            if(infoMission.users_invite?.length > 0){
                setCountUsersInvite(infoMission.users_invite.length)
            } else {
                setCountUsersInvite(0)
            }

            if (infoMission.user_assigned && infoMission.contract_company[0]?.id_bestt) {
                setIsAssignationMissionAffectAssignedMission(true)
            } else {
                setIsAssignationMissionAffectAssignedMission(false)
            }
        }
    }, [infoMission])

    useEffect(() => {
        if(infoMission){
            if((dateToEpoch(missionDateStart).getTime() !== dateToEpoch(originalMissionDateStart).getTime() || dateToEpoch(missionDateEnd).getTime() !== dateToEpoch(originalMissionDateEnd).getTime()) && infoMission.contract_company[0]?.id_bestt){
                setIsModificationDateMissionAffectBesttContract(true)
            } else {
                setIsModificationDateMissionAffectBesttContract(false)
            }

            if(dateToEpoch(missionDateStart).getTime() !== dateToEpoch(originalMissionDateStart).getTime() || dateToEpoch(missionDateEnd).getTime() !== dateToEpoch(originalMissionDateEnd).getTime()){
                setIsModificationDateMissionDifferentFromOriginal(true)
            } else {
                setIsModificationDateMissionDifferentFromOriginal(false)
            }
        }
    }, [missionDateStart, missionDateEnd])

    const manageTempWorkerSearch = (search) => {
        if(search.length > 0){
            setSearchTempWorkerResultWaiting(true)
            setSearchTempWorkerResultLoadData(true)
            setSelectedTempWorkerAssignation(false)

            ApiDatabase.searchTempWorker({token: token, search: search, limit: limitElementTempWorker, page: currentPageTempWorker, missionId: missionId}, (data) => {
                setSearchTempWorkerResult(data.users)
                setTotalPagesTempWorker(data.countPages)
                setCountPerPageTempWorker(limitElementTempWorker)
                setCountTotalTempWorker(data.count)
                setSearchTempWorkerResultWaiting(false)
            }, (err) => {
                if (err.name !== "CanceledError") {
                    setSearchTempWorkerResultWaiting(false)
                    setSearchTempWorkerResult(false)
                    console.error(err);
                }
            })
        } else {
            setSearchTempWorkerResultLoadData(false)
            setSearchTempWorkerResult(false)
            setSelectedTempWorkerAssignation(false)
        }
    }

    useEffect(() => {
        setSelectedTempWorkerAssignation(false)
        if(searchTempWorker){
            const searchTempWorkerSplit = searchTempWorker.split(/(?:,)+/);

            if (searchTempWorkerSplit.length > 0) {
                timerTempWorkerSearch = setTimeout(async () => {
                    await manageTempWorkerSearch(searchTempWorkerSplit);
                }, 400);
            } else {
                setSearchTempWorkerResultLoadData(false)
                setSearchTempWorkerResult(false)
                setSelectedTempWorkerAssignation(false)
            }
            return () => clearTimeout(timerTempWorkerSearch);
        } else {
            setSearchTempWorkerResultLoadData(false)
            setSearchTempWorkerResult(false)
        }
    }, [searchTempWorker, currentPageTempWorker])

    useEffect(() => {
        if((searchTempWorkerResult.length > 0 && selectedTempWorkerAssignation) || (searchTempWorkerAssignationResult.length > 0 && selectedTempWorkerAssignation)){
            const user = (searchTempWorkerResult ? searchTempWorkerResult.find((tempWorker) => tempWorker._id === selectedTempWorkerAssignation) : null) || (searchTempWorkerAssignationResult ? searchTempWorkerAssignationResult.find((tempWorker) => tempWorker._id === selectedTempWorkerAssignation) : null)
            if(user){
                if(user.tempworker){
                    if(user.tempworker.identity && user.tempworker.card_vitality){
                        //Not added
                        if((user.tempworker.identity.validated === 0 || user.tempworker.identity.validated === '0') || (user.tempworker.card_vitality.validated === 0 || user.tempworker.card_vitality.validated === '0')){
                            setErrorAssignationMissionIdentityFiles('Les documents d\'identité n\'ont pas étés ajoutés, un mail va être envoyé à l\'intérimaire pour qu\'il les ajoutent')
                        }
                        //Added but not verified
                        else if((user.tempworker.identity.validated === 1 || user.tempworker.identity.validated === '1') || (user.tempworker.card_vitality.validated === 1 || user.tempworker.card_vitality.validated === '1')){
                            setErrorAssignationMissionIdentityFiles('Les documents d\'identité doivent être vérifiés par un administrateur')
                        }
                        //Verified
                        else if((user.tempworker.identity.validated === 2 || user.tempworker.identity.validated === '2') && (user.tempworker.card_vitality.validated === 2 || user.tempworker.card_vitality.validated === '2')){
                            //Identity and card vitality data is missing
                            if(!user.tempworker.identity.number_identity || !user.tempworker.card_vitality.social_security){
                                setErrorAssignationMissionIdentityFiles('Les documents d\'identité sont validés mais les données sont manquantes, contactez un développeur')
                            }
                            else {
                                setErrorAssignationMissionIdentityFiles(false)
                            }
                        }
                        //Refused
                        else if((user.tempworker.identity.validated === 3 || user.tempworker.identity.validated === '3') || (user.tempworker.card_vitality.validated === 3 || user.tempworker.card_vitality.validated === '3')){
                            setErrorAssignationMissionIdentityFiles('Les documents d\'identité ont été refusés, un mail de rappel sera envoyé')
                        }
                    }
                }
            }
        }
    }, [selectedTempWorkerAssignation])

    useEffect(() => {
        if(userData?.role){
            setRole(userData.role)
        }
    }, [userData])

    const missionDateSubmit = (id_mission) => {
        //toggle('ad_mission_date_confirmation')
        setLoadingModificationDateMission(true)

        const dateStart = dateToEpoch(missionDateStart).getFullYear() + '/' + ('0' + (dateToEpoch(missionDateStart).getMonth() + 1)).toString().slice(-2) + '/' + ('0' + dateToEpoch(missionDateStart).getDate()).toString().slice(-2)
        const dateEnd = dateToEpoch(missionDateEnd).getFullYear() + '/' + ('0' + (dateToEpoch(missionDateEnd).getMonth() + 1)).toString().slice(-2) + '/' + ('0' + dateToEpoch(missionDateEnd).getDate()).toString().slice(-2)

        ApiDatabase.modifyMissionPeriod({idMission: id_mission, dateStart: dateStart, dateEnd: dateEnd, token}, async (data) => {
            setLoadingModificationDateMission(false)
            toast.success('La période de la mission a bien été modifiée')
            for (let i = 0; i < searchMissionResult.length; i++) {
                if(searchMissionResult[i]._id === id_mission){
                    searchMissionResult[i].duration = {
                        datestart: dateToEpoch(missionDateStart).toISOString(),
                        dateend: dateToEpoch(missionDateEnd).toISOString()
                    }
                    break
                }
            }
            setSearchMissionResult(searchMissionResult)
            await editMission(id_mission)
        }, (err) => {
            setLoadingModificationDateMission(false)
            toast.error('Une erreur est survenue lors de la modification de la période de la mission : ' + err)
        })
    }

    const assignationMissionSubmit = (idMission, idTempWorker) => {
        //toggle('ad_mission_assignation_confirmation')
        setLoadingAssignationMission(true)
        ApiDatabase.assignTempWorkerToMission({idMission: idMission, idTempWorker: idTempWorker}, async (data) => {
            setLoadingAssignationMission(false)
            toast.success('L\'intérimaire a bien été assigné à la mission')
            const user = (searchTempWorkerResult ? searchTempWorkerResult.find((tempWorker) => tempWorker._id === selectedTempWorkerAssignation) : null) || (searchTempWorkerAssignationResult ? searchTempWorkerAssignationResult.find((tempWorker) => tempWorker._id === selectedTempWorkerAssignation) : null)
            for (let i = 0; i < searchMissionResult.length; i++) {
                if(searchMissionResult[i]._id === idMission){
                    searchMissionResult[i].user = {
                        _id: idTempWorker,
                        lastname: user.lastname,
                        firstname: user.firstname,
                        email: user.email,
                        phone: user.phone,
                        id_bestt: user.id_bestt,
                    }
                    searchMissionResult[i].user_assigned = idTempWorker
                    break
                }
            }
            setSearchMissionResult(searchMissionResult)
            await editMission(idMission)
        }, async (err) => {
            setLoadingAssignationMission(false)
            toast.error('Une erreur est survenue lors de l\'assignation de l\'intérimaire à la mission : ' + err.response.data.error)
            const user = (searchTempWorkerResult ? searchTempWorkerResult.find((tempWorker) => tempWorker._id === selectedTempWorkerAssignation) : null) || (searchTempWorkerAssignationResult ? searchTempWorkerAssignationResult.find((tempWorker) => tempWorker._id === selectedTempWorkerAssignation) : null)
            for (let i = 0; i < searchMissionResult.length; i++) {
                if(searchMissionResult[i]._id === idMission){
                    searchMissionResult[i].user = {
                        _id: idTempWorker,
                        lastname: user.lastname,
                        firstname: user.firstname,
                        email: user.email,
                        phone: user.phone,
                        id_bestt: user.id_bestt,
                    }
                    searchMissionResult[i].user_assigned = idTempWorker
                    break
                }
            }
            setSearchMissionResult(searchMissionResult)
            await editMission(idMission)
        })
    }

    const sendSignatureMissionSubmit = (missionId) => {
        //toggle('ad_mission_sign_confirmation')
        setLoadingSignatureMission(true)
        ApiDatabase.requestSignContract({idMission: missionId}, async (data) => {
            setLoadingSignatureMission(false)
            toast.success('La signature a bien été effectuée')
            await editMission(missionId)
        }, (err) => {
            setLoadingSignatureMission(false)
            toast.error('Une erreur est survenue lors de l\'envoi du mail de signature : ' + err.response.data.error)
        })
    }

    const generationContractSubmit = (missionId) => {
        setLoadingContractGeneration(true)
        ApiDatabase.requestGenerationContract({token: token, idMission: missionId}, async (data) => {
            setLoadingContractGeneration(false)
            toast.success('La génération du contrat a bien été effectuée')
            await editMission(missionId)
        }, (err) => {
            setLoadingContractGeneration(false)
            toast.error('Une erreur est survenue lors de la génération du contrat : ' + err.response.data.error)
        })
    }

    useEffect(() => {
        setSearchTempWorker('')
        setSearchTempWorkerResult(false)
        setSearchTempWorkerResultLoadData(false)
        setSearchTempWorkerAssignationResult(false)
        setSearchTempWorkerInviteResult([])
        setSearchTempWorkerAssignationResultWaiting(false)
        setSearchTempWorkerInviteResultWaiting(false)
        setErrorAssignationMissionIdentityFiles(false)
        setSelectedTempWorkerAssignation(false)
        setSearchTempWorkerAssignation('')

        setCurrentPageTempWorker(1)
        setLimitElementTempWorker(20)
        setTotalPagesTempWorker(0)
        setCountPerPageTempWorker(0)
        setCountTotalTempWorker(0)

        setCurrentPageTempWorkerAssignation(1)
        setLimitElementTempWorkerAssignation(20)
        setTotalPagesTempWorkerAssignation(0)
        setCountPerPageTempWorkerAssignation(0)
        setCountTotalTempWorkerAssignation(0)

        if(typeSearchTempWorkerAssignation === 1){
            setSearchTempWorkerAssignationResultWaiting(true)
            manageTempWorkerAssignationSearch([])
        }
        if(typeSearchTempWorkerAssignation === 3){
            setSearchTempWorkerInviteResultWaiting(true)
            ApiDatabase.getTempWorkerInviteMission({token: token, idMission: missionId}, (data) => {
                setSearchTempWorkerInviteResult(data.users_invite ?? [])
                setSearchTempWorkerInviteResultWaiting(false)
            }, (err) => {
                if (err.name !== "CanceledError") {
                    console.log(err)
                    setSearchTempWorkerInviteResultWaiting(false)
                }
            })
        }
    }, [typeSearchTempWorkerAssignation])

    const manageTempWorkerAssignationSearch = (search) => {
        setSearchTempWorkerAssignationResultWaiting(true)
        setSearchTempWorkerAssignationResultLoadData(true)

        ApiDatabase.getTempWorkerPostulateMission({token: token, search: search, idMission: missionId, limit: limitElementTempWorkerAssignation, page: currentPageTempWorkerAssignation}, (data) => {
            setSearchTempWorkerAssignationResult(data.users)
            setTotalPagesTempWorkerAssignation(data.countPages)
            setCountPerPageTempWorkerAssignation(limitElementTempWorkerAssignation)
            setCountTotalTempWorkerAssignation(data.count)
            setSearchTempWorkerAssignationResultWaiting(false)
        }, (err) => {
            if (err.name !== "CanceledError") {
                setSearchTempWorkerAssignationResultWaiting(false)
                setSearchTempWorkerAssignationResult(false)
                console.error(err);
            }
        })
    }


    useEffect( () => {
        setSelectedTempWorkerAssignation(false)
        if (searchTempWorkerAssignation) {
            const searchTempWorkerSplit = searchTempWorkerAssignation.split(/(?:,)+/);

            if (searchTempWorkerSplit.length > 0) {
                timerTempWorkerAssignationSearch = setTimeout(async () => {
                    await manageTempWorkerAssignationSearch(searchTempWorkerSplit);
                }, 400);
            } else {
                manageTempWorkerAssignationSearch([])
            }
            return () => clearTimeout(timerTempWorkerAssignationSearch);
        } else {
            manageTempWorkerAssignationSearch([]);
        }
    }, [searchTempWorkerAssignation, currentPageTempWorkerAssignation])
    
    const unassignMission = (idMission, userAssigned) => {
        if(idMission && userAssigned){
            setUserUnAssigned({idMission, userAssigned})
            toggle('d_unassign_mission')
        }
    }

    useEffect(() => {
        if(!userUnAssigned){
            for (let i = 0; i < searchMissionResult.length; i++) {
                if(searchMissionResult[i]._id === missionId){
                    searchMissionResult[i].user = null
                    searchMissionResult[i].user_assigned = null
                    break
                }
            }
            setSearchMissionResult(searchMissionResult)
            editMission(infoMission._id)
        }
    }, [userUnAssigned])

    const handleDeleteMission = () => {
        setObjID(infoMission._id)
        toggle('mission_delete')
    }

    const handleCancelMission = () => {
        setObjID(infoMission._id)
        toggle('mission_cancel')
    }

    useEffect(() => {
        if (modalDataReturn === 'mission canceled') {
            setSearchMissionResult(
                searchMissionResult.map((mission) =>
                    mission._id === infoMission._id ? { ...mission, status: "cancel" } : mission
                )
            );
            editMission(infoMission._id)
            setModalDataReturn({})
        }
        if (modalDataReturn === 'mission deleted') {
            setSearchMissionResult(searchMissionResult.filter((mission) => mission._id !== infoMission._id))
            setInfoMission(false)
            setModalDataReturn({})
        }
    }, [modalDataReturn])

    return (
        <>
            {
                isInfoMissionWaiting ? (
                    <>
                        Chargement...
                    </>
                ) : (
                    <>
                        {
                            infoMission ? (
                                <>
                                    <div className={'h-full border rounded overflow-y-auto'}>
                                        <div className={'flex flex-col gap-8 p-2'}>
                                            <div className={'flex gap-1 justify-between items-center'}>
                                                <h2 className={'ft-b text-lg'}>
                                                    {infoMission.title} n°{infoMission.id_mission}
                                                </h2>
                                                {shouldShowTrashButton(infoMission) === 'delete' && (
                                                    <Button size={'SMI'} color={'SECONDARY'} onClick={handleDeleteMission}>
                                                        <TrashIcon wh={20} color={'#374151'}/>
                                                    </Button>
                                                )}
                                                {shouldShowTrashButton(infoMission) === 'cancel' && (
                                                    <Button size={'SMI'} color={'SECONDARY'} onClick={handleCancelMission}>
                                                        <TrashIcon wh={20} color={'#374151'}/>
                                                    </Button>
                                                )}
                                            </div>
                                            {infoMission.status === 'cancel' ? (
                                                <div className={'flex flex-col gap-3'}>
                                                    <h2 className={'ft-b text-lg'}>
                                                        Supprimer la mission
                                                    </h2>
                                                    <div className={'flex gap-1 text-gray-500'}>
                                                        Raison de l'annulation : <div className={'text-gray-900'}>{infoMission.reason}</div>
                                                    </div>
                                                    {infoMission.contract_company[0]?.id_bestt && (
                                                        <Banner type='warning'>
                                                            Vous devez supprimer manuellement le contrat n°{infoMission.contract_company[0]?.id_bestt} sur Bestt avant de supprimer la mission.
                                                        </Banner>
                                                    )}
                                                    <div className={'w-fit'}>
                                                        <Button size={'SM'} color={'PRIMARY'} onClick={handleDeleteMission}>Supprimer la mission</Button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    {
                                                        (role === 500 || role === 515) && (
                                                            <>
                                                                <Banner type='info'>
                                                                    Pensez à modifier les <u><b>périodes de missions</b></u> de l'intérimaire AVANT <u><b>attribution de la mission</b></u>.
                                                                </Banner>
                                                                <div className={'flex flex-col gap-3'}>
                                                                    <div className={'flex flex-col gap-1'}>
                                                                        <h3 className={'ft-b text-lg'}>Périodes de la mission</h3>
                                                                        {
                                                                            isModificationDateMissionAffectBesttContract && (
                                                                                <div className=''>
                                                                                    <Banner type='warning'>
                                                                                        En modifiant les dates de la mission, pensez également à modifier les dates du contrat présent sur BeSTT : {infoMission.contract_company[0]?.id_bestt ?? null}.
                                                                                    </Banner>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className={'flex gap-2'}>
                                                                        <div className={'w-full'}>
                                                                            <div>
                                                                                <label htmlFor="name" className='ft-sm text-gray-500 flex gap-1'>
                                                                                    Date de début
                                                                                </label>
                                                                                <DatePicker
                                                                                    dateFormat="dd/MM/yyyy"
                                                                                    selected={missionDateStart}
                                                                                    onChange={(date) => setMissionDateStart(date)}
                                                                                    locale='fr-FR'
                                                                                    peekNextMonth
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    dropdownMode="select"
                                                                                    popperPlacement="bottom"
                                                                                    className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-center'
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className={'w-full'}>
                                                                            <div>
                                                                                <label htmlFor="name" className='ft-sm text-gray-500 flex gap-1'>
                                                                                    Date de fin
                                                                                </label>
                                                                                <DatePicker
                                                                                    dateFormat="dd/MM/yyyy"
                                                                                    selected={missionDateEnd}
                                                                                    onChange={(date) => setMissionDateEnd(date)}
                                                                                    locale='fr-FR'
                                                                                    peekNextMonth
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    dropdownMode="select"
                                                                                    popperPlacement="bottom"
                                                                                    className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-center'
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={'flex justify-start'}>
                                                                        {
                                                                            !loadingModificationDateMission ? (
                                                                                <>
                                                                                    {
                                                                                        isModificationDateMissionDifferentFromOriginal ? (
                                                                                            <>
                                                                                                <Button size={'SM'} color={'PRIMARY'} onClick={() => missionDateSubmit(infoMission._id)}>Changer la période de mission</Button>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <Button size={'SM'} color={'SECONDARY'} disabled>Changer la période de mission</Button>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <Button size={'SM'} color={'SECONDARY'} disabled>Changement de la période de la mission ...</Button>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <Schedule mission={infoMission}/>
                                                                <div className={'flex flex-col gap-3'}>
                                                                    <div className={'flex flex-col gap-1'}>
                                                                        <h3 className={'ft-b text-lg'}>Attribution de la mission</h3>
                                                                    </div>
                                                                    {
                                                                        isAssignationMissionAffectAssignedMission && (
                                                                            <div className=''>
                                                                                <Banner type='warning'>
                                                                                    En modifiant l'assignation d'une mission ayant un contrat BeSTT déjà créé, pensez à supprimer le contrat présent sur BeSTT : {infoMission.contract_company[0]?.id_bestt ?? null}.<br></br> Un nouveau contrat sera créé automatiquement.
                                                                                </Banner>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    {
                                                                        errorAssignationMissionIdentityFiles && (
                                                                            <div className=''>
                                                                                <Banner type='alert'>
                                                                                    {errorAssignationMissionIdentityFiles}
                                                                                </Banner>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    {
                                                                        infoMission.user_assigned ? (
                                                                            // Mission assigned
                                                                            <>
                                                                                <div className={'flex gap-4 justify-between'}>

                                                                                    <div className={'w-full flex gap-5 border rounded p-2'}>
                                                                                        <div className={'my-auto flex justify-between w-full'}>
                                                                                            <div className={'flex flex-col'}>
                                                                                                <div className={'flex gap-1'}>
                                                                                                    <div className={'my-auto'}>
                                                                                                        <CopyToClipboard
                                                                                                            text={infoMission.user_assigned_infos.firstname + ' ' + infoMission.user_assigned_infos.lastname}>
                                                                                                            {infoMission.user_assigned_infos.firstname + ' ' + infoMission.user_assigned_infos.lastname}
                                                                                                        </CopyToClipboard>
                                                                                                    </div>
                                                                                                    <div className={'my-auto'}>
                                                                                                        n°{infoMission.user_assigned_infos.id_bestt}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className={''}>
                                                                                                    <CopyToClipboard
                                                                                                        text={infoMission.user_assigned_infos.email}>
                                                                                                        <a className={'underline'}
                                                                                                           href={`mailto:${infoMission.user_assigned_infos.email}`}>
                                                                                                            {infoMission.user_assigned_infos.email}
                                                                                                        </a>
                                                                                                    </CopyToClipboard>
                                                                                                </div>
                                                                                                <div className={''}>
                                                                                                    <CopyToClipboard
                                                                                                        text={infoMission.user_assigned_infos.phone}>
                                                                                                        <a className={'underline'}
                                                                                                           href={`tel:${infoMission.user_assigned_infos.phone}`}>
                                                                                                            {infoMission.user_assigned_infos.phone}
                                                                                                        </a>
                                                                                                    </CopyToClipboard>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className={'flex flex-col justify-between'}>
                                                                                                <div>
                                                                                                    {
                                                                                                        ((infoMission.user_assigned_infos.tempworker.identity.validated === "2" || infoMission.user_assigned_infos.tempworker.identity.validated === 2) && (infoMission.user_assigned_infos.tempworker.card_vitality.validated === "2" || infoMission.user_assigned_infos.tempworker.card_vitality.validated === 2) && (infoMission.user_assigned_infos.tempworker.proof_of_address.validated === "2" || infoMission.user_assigned_infos.tempworker.proof_of_address.validated === 2)) ? (
                                                                                                            <Badge type={'VALID'}>Complet</Badge>
                                                                                                        ) : (
                                                                                                            <Badge type={'ALERT'}>Incomplet</Badge>
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                                <div className={'ml-auto'}>
                                                                                                    {
                                                                                                        infoMission.user_assigned_infos.cv?.filePath ? (
                                                                                                            <a href={process.env.REACT_APP_FILE + infoMission.user_assigned_infos.cv.filePath} target={'_blank'}>
                                                                                                                <Button size={'SM'} color={'SECONDARY'}>CV</Button>
                                                                                                            </a>
                                                                                                        ) : (
                                                                                                            <Button size={'SM'} color={'DISABLED'}>CV</Button>
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={''}>
                                                                                        <div className={'border rounded p-1 cursor-pointer'} onClick={() => unassignMission(infoMission._id, infoMission.user_assigned)}>
                                                                                            <Close wh={22} color={'#374151'}/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            // Mission not assigned
                                                                            <>
                                                                                <div className={'w-full'}>
                                                                                    <div className={'flex gap-4 ft-sm'}>
                                                                                        <div className={`my-auto px-3 py-2 rounded cursor-pointer ${typeSearchTempWorkerAssignation === 1 ? 'text-gray-700 bg-gray-100' : 'text-gray-500'}`} onClick={() => setTypeSearchTempWorkerAssignation(1)}>
                                                                                            <div className={'flex gap-1'}>
                                                                                                <div className={'my-auto'}>
                                                                                                    Candidature
                                                                                                </div>
                                                                                                <div className={'my-auto bg-yellow-100 text-yellow-800 rounded px-1 flex items-center justify-center min-w-[20px]'}>
                                                                                                    {countTempWorkerPostulate}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className={`my-auto text-gray-500 px-3 py-2 rounded cursor-pointer ${typeSearchTempWorkerAssignation === 2 ? 'text-gray-700 bg-gray-100' : 'text-gray-500'}`} onClick={() => setTypeSearchTempWorkerAssignation(2)}>
                                                                                            Rechercher
                                                                                        </div>
                                                                                        <div className={`my-auto ml-auto px-3 py-2 rounded cursor-pointer ${typeSearchTempWorkerAssignation === 3 ? 'text-gray-700 bg-gray-100' : 'text-gray-500'}`} onClick={() => setTypeSearchTempWorkerAssignation(3)}>
                                                                                            <div className={'flex gap-1'}>
                                                                                                <div className={'my-auto'}>
                                                                                                    Invitation
                                                                                                </div>
                                                                                                <div className={'my-auto bg-yellow-100 text-yellow-800 rounded px-1 flex items-center justify-center min-w-[20px]'}>
                                                                                                    {countUsersInvite}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/*Postulate search*/}
                                                                                {
                                                                                    typeSearchTempWorkerAssignation === 1 && (
                                                                                        <>
                                                                                            <label htmlFor="name" className='ft-sm text-gray-500 flex gap-1'>
                                                                                                Intérimaire
                                                                                                <Tooltip text={`La recherche se fait sur l'id BeSTT, l'email, le nom ou le prénom de l'intérimaire.`} placement='right'>
                                                                                                    <div><InformationCircle wh={18} color={'#6B7280'}/></div>
                                                                                                </Tooltip>
                                                                                            </label>
                                                                                            <input type="text"
                                                                                                   className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg'
                                                                                                   value={searchTempWorkerAssignation}
                                                                                                   onChange={(e) => setSearchTempWorkerAssignation(e.target.value)}/>
                                                                                            {
                                                                                                searchTempWorkerAssignationResultLoadData ? (
                                                                                                    <>
                                                                                                        {
                                                                                                            searchTempWorkerAssignationResultWaiting ? (
                                                                                                                <>
                                                                                                                    Chargement des candidatures ...
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    {
                                                                                                                        searchTempWorkerAssignationResult ? (
                                                                                                                            <>
                                                                                                                                <div className={'flex flex-col gap-2 max-h-[230px] overflow-y-auto w-full my-2'}>
                                                                                                                                    {
                                                                                                                                        (searchTempWorkerAssignationResult.complete || searchTempWorkerAssignationResult.partial || searchTempWorkerAssignationResultWaiting.not_updated || searchTempWorkerAssignationResultWaiting.incomplete) ? (
                                                                                                                                            <>
                                                                                                                                                {
                                                                                                                                                    searchTempWorkerAssignationResult.complete.length > 0 && (
                                                                                                                                                        <>
                                                                                                                                                            <div className={'flex flex-col'}>
                                                                                                                                                                <Divider/>
                                                                                                                                                                <div className={'flex justify-between cursor-pointer px-1 py-2'} onClick={() => setIsTempWorkerResultCompleteOpen(!isTempWorkerResultCompleteOpen)}>
                                                                                                                                                                    <div className={'my-auto'}>
                                                                                                                                                                        DISPONIBILITÉ COMPLÈTE
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className={'my-auto'}>
                                                                                                                                                                        {
                                                                                                                                                                            isTempWorkerResultCompleteOpen ? (
                                                                                                                                                                                <ChevronDown w={18} h={8} color={'#374151'}/>
                                                                                                                                                                            ) : (
                                                                                                                                                                                <ChevronTop w={18} h={8} color={'#374151'}/>
                                                                                                                                                                            )
                                                                                                                                                                        }
                                                                                                                                                                    </div>
                                                                                                                                                                </div>
                                                                                                                                                                <Divider/>
                                                                                                                                                                {
                                                                                                                                                                    isTempWorkerResultCompleteOpen && (
                                                                                                                                                                        searchTempWorkerAssignationResult.complete?.map((tempWorker, index) => (
                                                                                                                                                                            <MissionDetailTempWorker index={index} tempWorker={tempWorker} selectedTempWorkerAssignation={selectedTempWorkerAssignation} setSelectedTempWorkerAssignation={setSelectedTempWorkerAssignation} missionDateStart={missionDateStart} missionDateEnd={missionDateEnd}/>
                                                                                                                                                                        ))
                                                                                                                                                                    )
                                                                                                                                                                }
                                                                                                                                                            </div>
                                                                                                                                                        </>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                                {
                                                                                                                                                    searchTempWorkerAssignationResult.partial.length > 0 && (
                                                                                                                                                        <>
                                                                                                                                                            <div className={'flex flex-col'}>
                                                                                                                                                                <Divider/>
                                                                                                                                                                <div className={'flex justify-between cursor-pointer px-1 py-2'} onClick={() => setIsTempWorkerResultPartialOpen(!isTempWorkerResultPartialOpen)}>
                                                                                                                                                                    <div className={'my-auto'}>
                                                                                                                                                                        DISPONIBILITÉ PARTIELLE
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className={'my-auto'}>
                                                                                                                                                                        {
                                                                                                                                                                            isTempWorkerResultPartialOpen ? (
                                                                                                                                                                                <ChevronDown w={18} h={8} color={'#374151'}/>
                                                                                                                                                                            ) : (
                                                                                                                                                                                <ChevronTop w={18} h={8} color={'#374151'}/>
                                                                                                                                                                            )
                                                                                                                                                                        }
                                                                                                                                                                    </div>
                                                                                                                                                                </div>
                                                                                                                                                                <Divider/>
                                                                                                                                                                {
                                                                                                                                                                    isTempWorkerResultPartialOpen && (
                                                                                                                                                                        searchTempWorkerAssignationResult.partial?.map((tempWorker, index) => (
                                                                                                                                                                            <MissionDetailTempWorker index={index} tempWorker={tempWorker} selectedTempWorkerAssignation={selectedTempWorkerAssignation} setSelectedTempWorkerAssignation={setSelectedTempWorkerAssignation} missionDateStart={missionDateStart} missionDateEnd={missionDateEnd}/>
                                                                                                                                                                        ))
                                                                                                                                                                    )
                                                                                                                                                                }
                                                                                                                                                            </div>
                                                                                                                                                        </>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                                {
                                                                                                                                                    searchTempWorkerAssignationResult.not_updated.length > 0 && (
                                                                                                                                                        <>
                                                                                                                                                            <div className={'flex flex-col'}>
                                                                                                                                                                <Divider/>
                                                                                                                                                                <div className={'flex justify-between cursor-pointer px-1 py-2'} onClick={() => setIsTempWorkerResultNotUpdatedOpen(!isTempWorkerResultNotUpdatedOpen)}>
                                                                                                                                                                    <div className={'my-auto'}>
                                                                                                                                                                        NON MIS À JOUR
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className={'my-auto'}>
                                                                                                                                                                        {
                                                                                                                                                                            isTempWorkerResultNotUpdatedOpen ? (
                                                                                                                                                                                <ChevronDown w={18} h={8} color={'#374151'}/>
                                                                                                                                                                            ) : (
                                                                                                                                                                                <ChevronTop w={18} h={8} color={'#374151'}/>
                                                                                                                                                                            )
                                                                                                                                                                        }
                                                                                                                                                                    </div>
                                                                                                                                                                </div>
                                                                                                                                                                <Divider/>
                                                                                                                                                                {
                                                                                                                                                                    isTempWorkerResultNotUpdatedOpen && (
                                                                                                                                                                        searchTempWorkerAssignationResult.not_updated?.map((tempWorker, index) => (
                                                                                                                                                                            <MissionDetailTempWorker index={index} tempWorker={tempWorker} selectedTempWorkerAssignation={selectedTempWorkerAssignation} setSelectedTempWorkerAssignation={setSelectedTempWorkerAssignation} missionDateStart={missionDateStart} missionDateEnd={missionDateEnd}/>
                                                                                                                                                                        ))
                                                                                                                                                                    )
                                                                                                                                                                }
                                                                                                                                                            </div>
                                                                                                                                                        </>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                                {
                                                                                                                                                    searchTempWorkerAssignationResult.incomplete.length > 0 && (
                                                                                                                                                        <>
                                                                                                                                                            <div className={'flex flex-col'}>
                                                                                                                                                                <Divider/>
                                                                                                                                                                <div className={'flex justify-between cursor-pointer px-1 py-2'} onClick={() => setIsTempWorkerResultIncompleteOpen(!isTempWorkerResultIncompleteOpen)}>
                                                                                                                                                                    <div className={'my-auto'}>
                                                                                                                                                                        NON DISPONIBLE
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className={'my-auto'}>
                                                                                                                                                                        {
                                                                                                                                                                            isTempWorkerResultIncompleteOpen ? (
                                                                                                                                                                                <ChevronDown w={18} h={8} color={'#374151'}/>
                                                                                                                                                                            ) : (
                                                                                                                                                                                <ChevronTop w={18} h={8} color={'#374151'}/>
                                                                                                                                                                            )
                                                                                                                                                                        }
                                                                                                                                                                    </div>
                                                                                                                                                                </div>
                                                                                                                                                                <Divider/>
                                                                                                                                                                {
                                                                                                                                                                    isTempWorkerResultIncompleteOpen && (
                                                                                                                                                                        searchTempWorkerAssignationResult.incomplete?.map((tempWorker, index) => (
                                                                                                                                                                            <MissionDetailTempWorker index={index} tempWorker={tempWorker} selectedTempWorkerAssignation={selectedTempWorkerAssignation} setSelectedTempWorkerAssignation={setSelectedTempWorkerAssignation} missionDateStart={missionDateStart} missionDateEnd={missionDateEnd}/>
                                                                                                                                                                        ))
                                                                                                                                                                    )
                                                                                                                                                                }
                                                                                                                                                            </div>
                                                                                                                                                        </>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                            </>
                                                                                                                                        ) : (
                                                                                                                                            searchTempWorkerAssignationResult.map((tempWorker, index) => (
                                                                                                                                                <MissionDetailTempWorker index={index} tempWorker={tempWorker} selectedTempWorkerAssignation={selectedTempWorkerAssignation} setSelectedTempWorkerAssignation={setSelectedTempWorkerAssignation} missionDateStart={missionDateStart} missionDateEnd={missionDateEnd}/>
                                                                                                                                            ))
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                                {
                                                                                                                                    (currentPageTempWorkerAssignation && totalPagesTempWorkerAssignation > 1 && setCurrentPageTempWorkerAssignation) ? (
                                                                                                                                        <div className={'px-8 py-4'}>
                                                                                                                                            <Pagination currentPage={currentPageTempWorkerAssignation} countPerPage={countPerPageTempWorkerAssignation} countTotal={countTotalTempWorkerAssignation} totalPages={totalPagesTempWorkerAssignation} setCurrentPage={setCurrentPageTempWorkerAssignation}/>
                                                                                                                                        </div>
                                                                                                                                    ) : null
                                                                                                                                }
                                                                                                                            </>
                                                                                                                        ) : (
                                                                                                                            <>
                                                                                                                                Pas d'intérimaire trouvé
                                                                                                                            </>
                                                                                                                        )
                                                                                                                    }
                                                                                                                </>
                                                                                                            )
                                                                                                        }
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                }

                                                                                {/*Candidate search*/}
                                                                                {
                                                                                    typeSearchTempWorkerAssignation === 2 && (
                                                                                        <>
                                                                                            <div className={'w-full'}>
                                                                                                <div>
                                                                                                    <label htmlFor="name" className='ft-sm text-gray-500 flex gap-1'>
                                                                                                        Intérimaire
                                                                                                        <Tooltip text={`La recherche se fait sur l'id BeSTT, l'email, le nom ou le prénom de l'intérimaire.`} placement='right'>
                                                                                                            <div><InformationCircle wh={18} color={'#6B7280'}/></div>
                                                                                                        </Tooltip>
                                                                                                    </label>
                                                                                                    <input type="text"
                                                                                                           className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg'
                                                                                                           value={searchTempWorker}
                                                                                                           onChange={(e) => setSearchTempWorker(e.target.value)}/>
                                                                                                    {
                                                                                                        searchTempWorkerResultLoadData ? (
                                                                                                            <>
                                                                                                                {
                                                                                                                    searchTempWorkerResultWaiting ? (
                                                                                                                        <>
                                                                                                                            Chargement...
                                                                                                                        </>
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                searchTempWorkerResult ? (
                                                                                                                                    <>
                                                                                                                                        <div className={'flex flex-col gap-2 max-h-[230px] overflow-y-auto w-full my-2'}>
                                                                                                                                            {
                                                                                                                                                (searchTempWorkerResult.complete || searchTempWorkerResult.partial || searchTempWorkerResult.not_updated || searchTempWorkerResult.incomplete) ? (
                                                                                                                                                    <>
                                                                                                                                                        {
                                                                                                                                                            searchTempWorkerResult.complete.length > 0 && (
                                                                                                                                                                <>
                                                                                                                                                                    <div className={'flex flex-col'}>
                                                                                                                                                                        <Divider/>
                                                                                                                                                                        <div className={'flex justify-between cursor-pointer px-1 py-2'} onClick={() => setIsTempWorkerResultCompleteOpen(!isTempWorkerResultCompleteOpen)}>
                                                                                                                                                                            <div className={'my-auto'}>
                                                                                                                                                                                DISPONIBILITÉ COMPLÈTE
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className={'my-auto'}>
                                                                                                                                                                                {
                                                                                                                                                                                    isTempWorkerResultCompleteOpen ? (
                                                                                                                                                                                        <ChevronDown w={18} h={8} color={'#374151'}/>
                                                                                                                                                                                    ) : (
                                                                                                                                                                                        <ChevronTop w={18} h={8} color={'#374151'}/>
                                                                                                                                                                                    )
                                                                                                                                                                                }
                                                                                                                                                                            </div>
                                                                                                                                                                        </div>
                                                                                                                                                                        <Divider/>
                                                                                                                                                                        {
                                                                                                                                                                            isTempWorkerResultCompleteOpen && (
                                                                                                                                                                                searchTempWorkerResult.complete?.map((tempWorker, index) => (
                                                                                                                                                                                    <MissionDetailTempWorker index={index} tempWorker={tempWorker} selectedTempWorkerAssignation={selectedTempWorkerAssignation} setSelectedTempWorkerAssignation={setSelectedTempWorkerAssignation} missionDateStart={missionDateStart} missionDateEnd={missionDateEnd}/>
                                                                                                                                                                                ))
                                                                                                                                                                            )
                                                                                                                                                                        }
                                                                                                                                                                    </div>
                                                                                                                                                                </>
                                                                                                                                                            )
                                                                                                                                                        }
                                                                                                                                                        {
                                                                                                                                                            searchTempWorkerResult.partial.length > 0 && (
                                                                                                                                                                <>
                                                                                                                                                                    <div className={'flex flex-col'}>
                                                                                                                                                                        <Divider/>
                                                                                                                                                                        <div className={'flex justify-between cursor-pointer px-1 py-2'} onClick={() => setIsTempWorkerResultPartialOpen(!isTempWorkerResultPartialOpen)}>
                                                                                                                                                                            <div className={'my-auto'}>
                                                                                                                                                                                DISPONIBILITÉ PARTIELLE
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className={'my-auto'}>
                                                                                                                                                                                {
                                                                                                                                                                                    isTempWorkerResultPartialOpen ? (
                                                                                                                                                                                        <ChevronDown w={18} h={8} color={'#374151'}/>
                                                                                                                                                                                    ) : (
                                                                                                                                                                                        <ChevronTop w={18} h={8} color={'#374151'}/>
                                                                                                                                                                                    )
                                                                                                                                                                                }
                                                                                                                                                                            </div>
                                                                                                                                                                        </div>
                                                                                                                                                                        <Divider/>
                                                                                                                                                                        {
                                                                                                                                                                            isTempWorkerResultPartialOpen && (
                                                                                                                                                                                searchTempWorkerResult.partial?.map((tempWorker, index) => (
                                                                                                                                                                                    <MissionDetailTempWorker index={index} tempWorker={tempWorker} selectedTempWorkerAssignation={selectedTempWorkerAssignation} setSelectedTempWorkerAssignation={setSelectedTempWorkerAssignation} missionDateStart={missionDateStart} missionDateEnd={missionDateEnd}/>
                                                                                                                                                                                ))
                                                                                                                                                                            )
                                                                                                                                                                        }
                                                                                                                                                                    </div>
                                                                                                                                                                </>
                                                                                                                                                            )
                                                                                                                                                        }
                                                                                                                                                        {
                                                                                                                                                            searchTempWorkerResult.not_updated.length > 0 && (
                                                                                                                                                                <>
                                                                                                                                                                    <div className={'flex flex-col'}>
                                                                                                                                                                        <Divider/>
                                                                                                                                                                        <div className={'flex justify-between cursor-pointer px-1 py-2'} onClick={() => setIsTempWorkerResultNotUpdatedOpen(!isTempWorkerResultNotUpdatedOpen)}>
                                                                                                                                                                            <div className={'my-auto'}>
                                                                                                                                                                                NON MIS À JOUR
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className={'my-auto'}>
                                                                                                                                                                                {
                                                                                                                                                                                    isTempWorkerResultNotUpdatedOpen ? (
                                                                                                                                                                                        <ChevronDown w={18} h={8} color={'#374151'}/>
                                                                                                                                                                                    ) : (
                                                                                                                                                                                        <ChevronTop w={18} h={8} color={'#374151'}/>
                                                                                                                                                                                    )
                                                                                                                                                                                }
                                                                                                                                                                            </div>
                                                                                                                                                                        </div>
                                                                                                                                                                        <Divider/>
                                                                                                                                                                        {
                                                                                                                                                                            isTempWorkerResultNotUpdatedOpen && (
                                                                                                                                                                                searchTempWorkerResult.not_updated?.map((tempWorker, index) => (
                                                                                                                                                                                    <MissionDetailTempWorker index={index} tempWorker={tempWorker} selectedTempWorkerAssignation={selectedTempWorkerAssignation} setSelectedTempWorkerAssignation={setSelectedTempWorkerAssignation} missionDateStart={missionDateStart} missionDateEnd={missionDateEnd}/>
                                                                                                                                                                                ))
                                                                                                                                                                            )
                                                                                                                                                                        }
                                                                                                                                                                    </div>
                                                                                                                                                                </>
                                                                                                                                                            )
                                                                                                                                                        }
                                                                                                                                                        {
                                                                                                                                                            searchTempWorkerResult.incomplete.length > 0 && (
                                                                                                                                                                <>
                                                                                                                                                                    <div className={'flex flex-col'}>
                                                                                                                                                                        <Divider/>
                                                                                                                                                                        <div className={'flex justify-between cursor-pointer px-1 py-2'} onClick={() => setIsTempWorkerResultIncompleteOpen(!isTempWorkerResultIncompleteOpen)}>
                                                                                                                                                                            <div className={'my-auto'}>
                                                                                                                                                                                NON DISPONIBLE
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className={'my-auto'}>
                                                                                                                                                                                {
                                                                                                                                                                                    isTempWorkerResultIncompleteOpen ? (
                                                                                                                                                                                        <ChevronDown w={18} h={8} color={'#374151'}/>
                                                                                                                                                                                    ) : (
                                                                                                                                                                                        <ChevronTop w={18} h={8} color={'#374151'}/>
                                                                                                                                                                                    )
                                                                                                                                                                                }
                                                                                                                                                                            </div>
                                                                                                                                                                        </div>
                                                                                                                                                                        <Divider/>
                                                                                                                                                                        {
                                                                                                                                                                            isTempWorkerResultIncompleteOpen && (
                                                                                                                                                                                searchTempWorkerResult.incomplete?.map((tempWorker, index) => (
                                                                                                                                                                                    <MissionDetailTempWorker index={index} tempWorker={tempWorker} selectedTempWorkerAssignation={selectedTempWorkerAssignation} setSelectedTempWorkerAssignation={setSelectedTempWorkerAssignation} missionDateStart={missionDateStart} missionDateEnd={missionDateEnd}/>
                                                                                                                                                                                ))
                                                                                                                                                                            )
                                                                                                                                                                        }
                                                                                                                                                                    </div>
                                                                                                                                                                </>
                                                                                                                                                            )
                                                                                                                                                        }
                                                                                                                                                    </>
                                                                                                                                                ) : (
                                                                                                                                                    searchTempWorkerResult.map((tempWorker, index) => (
                                                                                                                                                        <MissionDetailTempWorker index={index} tempWorker={tempWorker} selectedTempWorkerAssignation={selectedTempWorkerAssignation} setSelectedTempWorkerAssignation={setSelectedTempWorkerAssignation} missionDateStart={missionDateStart} missionDateEnd={missionDateEnd}/>
                                                                                                                                                    ))
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                        </div>
                                                                                                                                        {
                                                                                                                                            (currentPageTempWorker && totalPagesTempWorker > 1 && setCurrentPageTempWorker) ? (
                                                                                                                                                <div className={'px-8 py-4'}>
                                                                                                                                                    <Pagination currentPage={currentPageTempWorker} countPerPage={countPerPageTempWorker} countTotal={countTotalTempWorker} totalPages={totalPagesTempWorker} setCurrentPage={setCurrentPageTempWorker}/>
                                                                                                                                                </div>
                                                                                                                                            ) : null
                                                                                                                                        }
                                                                                                                                    </>
                                                                                                                                ) : (
                                                                                                                                    <>
                                                                                                                                        Pas d'intérimaire trouvé
                                                                                                                                    </>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </>
                                                                                                                    )
                                                                                                                }
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <></>
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                {/*Invite search*/}
                                                                                {typeSearchTempWorkerAssignation === 3 && (
                                                                                    <>
                                                                                        {searchTempWorkerInviteResultWaiting ? (
                                                                                            'Chargement ...'
                                                                                        ) : (
                                                                                            <>
                                                                                                {searchTempWorkerInviteResult.length > 0 ? (
                                                                                                    <div className={'flex flex-col gap-2 max-h-[230px] overflow-y-auto'}>
                                                                                                        {searchTempWorkerInviteResult.map((tempWorkerInvite, index) => (
                                                                                                            <div className={'border rounded p-2'}>
                                                                                                                <div className={'flex justify-between'}>
                                                                                                                    <div className={'my-auto'}>
                                                                                                                        <CopyToClipboard text={tempWorkerInvite.firstname + ' ' + tempWorkerInvite.lastname}>
                                                                                                                            {tempWorkerInvite.firstname} {tempWorkerInvite.lastname}
                                                                                                                        </CopyToClipboard>
                                                                                                                        <CopyToClipboard text={tempWorkerInvite.email}>
                                                                                                                            {tempWorkerInvite.email}
                                                                                                                        </CopyToClipboard>
                                                                                                                        <CopyToClipboard text={tempWorkerInvite.phone}>
                                                                                                                            {tempWorkerInvite.phone}
                                                                                                                        </CopyToClipboard>
                                                                                                                    </div>
                                                                                                                    <div className={'my-auto'}>
                                                                                                                        {tempWorkerInvite.status === 'pending' && (
                                                                                                                            <Badge type={'WARNING'}>En attente</Badge>
                                                                                                                        )}
                                                                                                                        {tempWorkerInvite.status === 'accepted' && (
                                                                                                                            <Badge type={'VALID'}>Accepté</Badge>
                                                                                                                        )}
                                                                                                                        {tempWorkerInvite.status === 'refused' && (
                                                                                                                            <Badge type={'ALERT'}>Refusé</Badge>
                                                                                                                        )}
                                                                                                                        {tempWorkerInvite.status === 'taken' && (
                                                                                                                            <Badge type={'ALERT'}>Attribué</Badge>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ))}
                                                                                                    </div>
                                                                                                ) : "Aucun intérimaire invité"}
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                                <div className={'flex justify-start'}>
                                                                                    {(typeSearchTempWorkerAssignation === 1 || typeSearchTempWorkerAssignation === 2) && (
                                                                                        <>
                                                                                            {
                                                                                                !loadingAssignationMission ? (
                                                                                                    <>
                                                                                                        {
                                                                                                            selectedTempWorkerAssignation ? (
                                                                                                                <Button size={'SM'} color={'PRIMARY'} onClick={() => assignationMissionSubmit(infoMission._id, selectedTempWorkerAssignation)}>Attribuer la mission</Button>
                                                                                                            ) : (
                                                                                                                <Button size={'SM'} color={'DISABLED'} disabled>Attribuer la mission</Button>
                                                                                                            )
                                                                                                        }
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <Button size={'SM'} color={'DISABLED'} disabled>
                                                                                                            Attribution de la mission ...
                                                                                                        </Button>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    <div className={'flex flex-col gap-3'}>
                                                        <div className={'flex flex-col gap-1'}>
                                                            <h3 className={'ft-b text-lg'}>Envoyer pour signature la mission</h3>
                                                        </div>
                                                        {
                                                            infoMission.user_assigned ? (
                                                                <>
                                                                    {
                                                                        infoMission.contract_tempworker[0]?.yousign_id && (
                                                                            <>
                                                                                <div className=''>
                                                                                    {
                                                                                        infoMission.contract_tempworker[0]?.status === "1" ? (
                                                                                            <Banner type='alert'>
                                                                                                Le contrat de la mission a déjà été signé par l'intérimaire.
                                                                                            </Banner>
                                                                                        ) : (
                                                                                            <Banner type='warning'>
                                                                                                Le contrat de la mission a déjà été envoyé pour signature.
                                                                                            </Banner>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                    {
                                                                        infoMission.contract_tempworker[0]?.id_bestt ? (
                                                                            <Banner type='warning'>
                                                                                La re-génération du contrat ne supprimera pas l'ancien, pensez à supprimer le contrat présent sur BeSTT : {infoMission.contract_company[0]?.id_bestt ?? null}.
                                                                            </Banner>
                                                                        ) : (
                                                                            <Banner type='warning'>
                                                                                Le candidat n'a pas ajouté ses justificatifs. la génération du contrat se fait manuellement.
                                                                            </Banner>
                                                                        )
                                                                    }

                                                                    <div className={'flex justify-start gap-2'}>
                                                                        {
                                                                            !loadingContractGeneration ? (
                                                                                <Button size={'SM'} color={'PRIMARY'} onClick={() => generationContractSubmit(infoMission._id)}>
                                                                                    {infoMission.contract_tempworker[0]?.id_bestt ? 'Re-générer' : 'Générer'} le contrat
                                                                                </Button>
                                                                            ) : (
                                                                                <Button size={'SM'} color={'DISABLED'}>
                                                                                    {infoMission.contract_tempworker[0]?.id_bestt ? 'Re-génération' : 'Génération'} du contrat ...
                                                                                </Button>
                                                                            )
                                                                        }
                                                                        {
                                                                            !loadingSignatureMission ? (
                                                                                <>
                                                                                    <Button size={'SM'} color={infoMission.contract_tempworker[0]?.id_bestt ? 'PRIMARY' : 'DISABLED'} onClick={
                                                                                        infoMission.contract_tempworker[0]?.id_bestt
                                                                                            ? () => sendSignatureMissionSubmit(infoMission._id)
                                                                                            : null
                                                                                    }
                                                                                    >
                                                                                        Envoyer pour signature
                                                                                    </Button>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <Button size={'SM'} color={'DISABLED'} disabled>
                                                                                        Envoi du contrat pour signature ...
                                                                                    </Button>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className=''>
                                                                        <Banner type='warning'>
                                                                            La mission n'est pas encore attribuée à un intérimaire.
                                                                        </Banner>
                                                                    </div>

                                                                    <div className={'flex justify-start'}>
                                                                        <Button size={'SM'} color={'DISABLED'} disabled>Envoyer pour signature</Button>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    Pas de données
                                </>
                            )
                        }
                    </>
                )
            }
        </>
    )
}

export default AdminMissionDetails