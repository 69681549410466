import {useEffect, useState} from "react";
import Tooltip from "components/tooltips/Tooltips";
import {CopyIcon} from "../../assets/icons";

const CopyToClipboard = ({ text, children }) => {
    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(text).then(r => {
            setIsCopied(true);
        });
    };

    useEffect(() => {
        if (isCopied) {
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        }
    }, [isCopied]);

    return (
        <div className="flex">
            <div className={'my-auto truncate cursor-pointer'} onClick={copyToClipboard}>
                <Tooltip
                    text={isCopied ? 'Copié !' : 'Copier dans le presse papier'}
                    placement="right"
                >
                    <div>
                        {children}
                    </div>
                </Tooltip>
            </div>
            <div className={'my-auto flex ml-1'}>
                <Tooltip
                    text={isCopied ? 'Copié !' : 'Copier dans le presse papier'}
                    placement="right"
                >
                    <button onClick={copyToClipboard}>
                        <CopyIcon wh={24}/>
                    </button>
                </Tooltip>
            </div>
        </div>
    );
}

export default CopyToClipboard