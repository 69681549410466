import React, {useEffect, useState} from "react";
import ApiDatabase from "server";
import {dateToEpoch} from "../../helper/helper";
import useSlideOver from "../SlideOver/useSlideOver";
import UseContext from "../../hooks/useContext";
import Divider from "../divider/divider";
import ScheduleWeekListMission from "./ScheduleWeekListMission";
import {ChevronDown, ChevronTop, Pencil, Plus, Warning} from "../../assets/icons";
import Banner from "../banner/banner";

const Schedule = (mission) => {
    const {toggleSlideOver} = useSlideOver();
    const [scheduleWeeksProv, setScheduleWeeksProv] = useState([]);
    const [scheduleWeekDisplay, setScheduleWeekDisplay] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [dateStartSchedule, setDateStartSchedule] = useState(null);
    const [dateEndSchedule, setDateEndSchedule] = useState(null);
    const [isScheduleToChange, setIsScheduleToChange] = useState(false);
    const [dateStartMission, setDateStartMission] = useState(null);
    const [dateEndMission, setDateEndMission] = useState(null);
    const {
        scheduleWeeks,
        setScheduleWeeks,
        setMissionDetails,
        updSchedule,
        setUpdSchedule
    } = UseContext();
    const token = localStorage.getItem('xsrfToken');
    const [missionProv, setMissionProv] = useState(null);
    const [isScheduleDayMissing, setIsScheduleDayMissing] = useState(false);

    // Appel de l'API séparé dans une fonction distincte
    const fetchSchedule = (missionId) => {
        ApiDatabase.getScheduleByIdMission({token, idMission: missionId}, (res) => {
            setScheduleWeeksProv(res);
            setUpdSchedule(false);  // Assurez-vous de réinitialiser updSchedule ici
        });
    };

    useEffect(() => {
        if ((mission?.mission?._id && missionProv !== mission?.mission) || updSchedule) {
            setMissionProv(mission?.mission);
            setMissionDetails({
                _id: mission?.mission?._id,
                duration: mission?.mission?.duration,
                schedule: mission?.mission?.schedule,
                title: mission?.mission?.title,
                id_mission: mission?.mission?.id_mission,
                name_commercial: mission?.mission?.company?.name_commercial,
            });
            fetchSchedule(mission?.mission?._id);  // Utilisation de la fonction d'appel API
        }
    }, [mission, updSchedule]);

    useEffect(() => {
        if (scheduleWeeksProv.length > 0) {
            const scheduleWeeksProvTemp = scheduleWeeksProv;
            scheduleWeeksProvTemp.sort((a, b) => a.week_number - b.week_number);

            setDateStartSchedule(dateToEpoch(new Date(scheduleWeeksProvTemp[0].days[0].date)));
            setDateEndSchedule(dateToEpoch(new Date(scheduleWeeksProvTemp[scheduleWeeksProvTemp.length - 1].days[scheduleWeeksProvTemp[scheduleWeeksProvTemp.length - 1].days.length - 1].date)));

            let isAWeekChosen = false;
            for (let i = 0; i < scheduleWeeksProvTemp.length; i++) {
                scheduleWeeksProvTemp[i].days.sort((a, b) => a.date - b.date);

                const number_week = ('0' + (parseInt(scheduleWeeksProvTemp[i].week_number) + 1)).slice(-2);
                scheduleWeeksProvTemp[i].name = `Semaine ${number_week}`;
                scheduleWeeksProvTemp[i].isNext = !!scheduleWeeksProvTemp[i + 1];
                scheduleWeeksProvTemp[i].isPrev = !!scheduleWeeksProvTemp[i - 1];

                let isHidden = true;
                for (let j = 0; j < scheduleWeeksProvTemp[i].days.length; j++) {
                    const scheduleDate = dateToEpoch(scheduleWeeksProvTemp[i].days[j].date);
                    const today = dateToEpoch(new Date());

                    if (scheduleDate === today) {
                        setScheduleWeekDisplay(i);
                        isHidden = false;
                        isAWeekChosen = true;
                    }

                    if (scheduleWeeksProvTemp[i].days[j].periods) {
                        if (scheduleWeeksProvTemp[i].days[j].periods?.periode_one_start === null && scheduleWeeksProvTemp[i].days[j].periods?.periode_one_end === null) {
                            setIsScheduleDayMissing(true);
                        }
                    } else {
                        setIsScheduleDayMissing(true);
                    }
                }
                scheduleWeeksProvTemp[i].isHidden = isHidden;
                scheduleWeeksProvTemp[i].isActualWeek = !isHidden;
            }
            if (!isAWeekChosen) {
                scheduleWeeksProvTemp[0].isHidden = false;
            }

            setScheduleWeeks(scheduleWeeksProvTemp);
        } else {
            setScheduleWeeks([]);
        }
    }, [scheduleWeeksProv]);

    useEffect(() => {
        if (mission?.mission?.duration?.datestart && mission?.mission?.duration?.dateend) {
            setDateStartMission(dateToEpoch(new Date(mission?.mission?.duration?.datestart)));
            setDateEndMission(dateToEpoch(new Date(mission?.mission?.duration?.dateend)));
        }
    }, [mission?.mission]);

    useEffect(() => {
        if (dateStartSchedule && dateEndSchedule && dateStartMission && dateEndMission) {
            if (dateStartSchedule !== dateStartMission || dateEndSchedule !== dateEndMission) {
                setIsScheduleToChange(true);
            } else {
                setIsScheduleToChange(false);
            }
        }
    }, [dateStartSchedule, dateEndSchedule, dateStartMission, dateEndMission]);

    const editSchedule = () => {
        toggleSlideOver('get_schedule');
    };

    return (
        <>
            <Divider/>
            <div className={'flex flex-col gap-3'}>
                <div className={'flex justify-between gap-1'}>
                    <h3 className={'ft-b text-lg my-auto'}>Planning</h3>
                    <div className={'flex gap-3 my-auto'}>
                        {
                            scheduleWeeksProv.length > 0 ? (
                                <>
                                    {isScheduleToChange && (
                                        <div
                                            className={'border rounded px-2 flex gap-2 text-sm bg-red-100 text-red-800 h-fit my-auto'}>
                                            <div className={'my-auto'}>
                                                <Warning wh={16} color={'#F87171'}/>
                                            </div>
                                            <div className={'my-auto'}>
                                                À modifier
                                            </div>
                                        </div>
                                    )}
                                    {isScheduleDayMissing && (
                                        <div
                                            className={'border rounded px-2 flex gap-2 text-sm bg-red-100 text-red-800 h-fit my-auto'}>
                                            <div className={'my-auto'}>
                                                Manquant
                                            </div>
                                        </div>
                                    )}
                                    <div className={'border rounded p-2 cursor-pointer'} onClick={() => editSchedule()}>
                                        <Pencil wh={18} color={'#374151'}/>
                                    </div>
                                    {isOpen ? (
                                        <div
                                            className={'border rounded p-2 w-9 h-9 flex justify-center items-center cursor-pointer'}
                                            onClick={() => setIsOpen(!isOpen)}>
                                            <ChevronDown w={18} h={8} color={'#374151'}/>
                                        </div>
                                    ) : (
                                        <div
                                            className={'border rounded p-2 w-9 h-9 flex justify-center items-center cursor-pointer'}
                                            onClick={() => setIsOpen(!isOpen)}>
                                            <ChevronTop w={18} h={8} color={'#374151'}/>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    <div
                                        className={'border rounded px-2 flex gap-2 text-sm bg-red-100 text-red-800 h-fit my-auto'}>
                                        <div className={'my-auto'}>
                                            Manquant
                                        </div>
                                    </div>
                                    <div
                                        className={'border rounded p-2 w-9 h-9 flex justify-center items-center cursor-pointer'}
                                        onClick={() => editSchedule()}>
                                        <Plus wh={18} color={"#374151"}/>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
                {
                    (isOpen && scheduleWeeks.length > 0) && (
                        <>
                            {isScheduleToChange && (
                                <Banner type={'alert'}>
                                    Les dates du planning ne correspondent pas avec celles de la mission
                                </Banner>
                            )}
                        </>
                    )
                }
                {
                    isOpen && scheduleWeeks.map((scheduleWeek, index) => {
                        if (scheduleWeek.isNext !== undefined && scheduleWeek.isPrev !== undefined && scheduleWeek.isHidden !== undefined && scheduleWeek.isActualWeek !== undefined) {
                            return (
                                <ScheduleWeekListMission
                                    key={index}
                                    indexScheduleWeek={index}
                                    scheduleWeek={scheduleWeek}
                                    isNext={scheduleWeek.isNext}
                                    isPrev={scheduleWeek.isPrev}
                                    isHidden={scheduleWeekDisplay !== index}
                                    isActualWeek={scheduleWeek.isActualWeek}
                                    setScheduleWeekDisplay={setScheduleWeekDisplay}
                                />
                            )
                        }
                    })
                }
            </div>
            <Divider/>
        </>
    )
}

export default Schedule;
