import {BanIcon, CrossIcon, Dots, Localisation, Plus, Star} from "../../../../../../../assets/icons";
import {useEffect, useRef, useState} from "react";
import UseContext from "../../../../../../../hooks/useContext";
import useSlideOver from "../../../../../../../components/SlideOver/useSlideOver";
import {useNavigate} from "react-router-dom";
import useModal from "../../../../../../../components/modal/useModal";
import PositionedDiv from "../../../../../../../components/PositionedDiv/PositionedDiv";

const UserKanbanVivier = ({user, usersSelected, setUsersSelected, setVivierEntered, idVivier, isExclude, vivierStatus}) => {
    const {setObjID, setInfoVivier} = UseContext()
    const {toggleSlideOver} = useSlideOver();
    const {toggle} = useModal()
    const navigate = useNavigate();
    const [hoveredUser, setHoveredUser] = useState(false)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropDownRef = useRef(null);


    const clickUser = (event, id) => {
        if (event.ctrlKey || event.metaKey) {
            if (usersSelected.includes(id)) {
                setUsersSelected(usersSelected.filter(userId => userId !== id))
            } else {
                setUsersSelected([...usersSelected, id])
            }
        } else {
            setUsersSelected([])
            setVivierEntered(false)
            openTempWorkerInfo(id)
        }
    }

    const openTempWorkerInfo = (id) => {
        setObjID(id)
        toggleSlideOver('get_temp_worker')
        navigate('/ficheVivier/'+ idVivier +'?idTempWorker=' + id);
    }

    const excludeAgencySelected = (idUser) => {
        setInfoVivier({listUsers: [idUser]})
        toggle("exclude_agency")
    }

    const addVivierSelected = (idUser) => {
        setInfoVivier({listUsers: [idUser]})
        toggle("vivier_add")
    }

    const changeLocalisation = (idUser) => {
        setInfoVivier({listUsers: [idUser]})
        toggle("localisation_preferences")
    }

    const excludeVivier = (idUser, user) => {
        setInfoVivier({
            idVivier: idVivier,
            nameUser: user.firstname + " " + user.lastname,
            listUsers: [idUser]
        })
        toggle("vivier_delete_candidate")
    }

    const handleDropDown = (e) => {
        e.stopPropagation();
        setDropdownOpen(prev => !prev);
    }

    useEffect(() => {
        let handler = (e) => {
            if (dropDownRef.current && !dropDownRef.current.contains(e.target)) {
                setDropdownOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)

        return () => {
            document.removeEventListener("mousedown", handler)
        }
    }, [])

    const drag = event => {
        if (usersSelected.length === 0) setUsersSelected([event.currentTarget.dataset.id])
    }

    return (
        <>
            {!user?.isDeleted && (
                <div
                    className={`p-4 flex gap-1 justify-between rounded ${vivierStatus !== 'archive' && 'cursor-grab'} select-none min-h-[110px] border-2 ${usersSelected.includes(user._id) ? 'border-yellow-500 bg-yellow-50' : 'border-gray-100 bg-white'}`}
                    {...(vivierStatus !== 'archive' && {
                        draggable: 'true',
                        onDragStart: drag,
                    })}
                    data-id={user._id}
                    onClick={(e) => clickUser(e, user._id)}
                    onMouseEnter={() => setHoveredUser(user._id)}
                    onMouseLeave={() => setHoveredUser(false)}
                >
                    <div className={'flex flex-col justify-between gap-1'}>
                        <div className={''}>{user.lastname} {user.firstname}</div>
                        <div className={''}>
                            {(user.rating || user.rating === 0) ? (
                                <div className={'my-auto border rounded-full flex gap-1 px-1.5 w-fit'}>
                                    <div className={'my-auto'}>
                                        <Star wh={18} color={'#FF9600'}/>
                                    </div>
                                    <div className={'my-auto'}>
                                        {user.rating}
                                    </div>
                                </div>
                            ) : (
                                <div className={'text-sm text-gray-500'}>
                                    Non noté
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={'flex flex-col justify-between min-w-[37px]'}>
                        <div>
                            {(hoveredUser === user._id || dropdownOpen) && (
                                <div className={'my-auto relative cursor-pointer w-9 h-9 flex items-center justify-center rounded hover:bg-gray-100'}
                                     ref={dropDownRef} onClick={(e) => handleDropDown(e)}
                                >
                                    <Dots w={14} h={4} color={'#374151'}/>
                                    {dropdownOpen &&
                                        <>
                                            <PositionedDiv parentRef={dropDownRef} position={'left'} size={261}>
                                                <div className={'border-2 border-[#F3F4F6] rounded-lg shadow-lg h-30 right-8 -bottom-8 bg-gray-50'}>
                                                    <div
                                                        className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer"
                                                        onClick={() => addVivierSelected(user?._id)}>
                                                        <div className={'flex gap-2'}>
                                                            <div className={'my-auto'}>
                                                                <Plus wh={20} color={'#374151'}/>
                                                            </div>
                                                            <div className={'my-auto'}>
                                                                Ajouter dans un vivier
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer"
                                                        onClick={() => changeLocalisation(user?._id)}>
                                                        <div className={'flex gap-2'}>
                                                            <div className={'my-auto'}>
                                                                <Localisation wh={20} color={'#374151'}/>
                                                            </div>
                                                            <div className={'my-auto'}>
                                                                Modifier la localisation
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="border-b border-gray-100"></div>
                                                    <div
                                                        className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer"
                                                        onClick={() => excludeVivier(user?._id, {
                                                            firstname: user.firstname,
                                                            lastname: user.lastname
                                                        })}>
                                                        <div className={'flex gap-2'}>
                                                            <div className={'my-auto'}>
                                                                <CrossIcon wh={20} color={'#374151'}/>
                                                            </div>
                                                            <div className={'my-auto'}>
                                                                Supprimer du vivier
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {!isExclude && (
                                                        <>
                                                            <div
                                                                className="flex flex-row gap-2 items-center h-9 p-2 ft-sm text-red-500 cursor-pointer"
                                                                onClick={() => excludeAgencySelected(user?._id)}>
                                                                <div className={'flex gap-2'}>
                                                                    <div className={'my-auto'}>
                                                                        <BanIcon wh={20} color={'#DC2626'}/>
                                                                    </div>
                                                                    <div className={'my-auto'}>
                                                                        Exclure de l'agence
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </PositionedDiv>
                                        </>
                                    }
                                </div>
                            )}
                        </div>
                        <div className={'flex justify-center'}>
                            {user.preferences_department?.department ? (
                                <>
                                    {user.preferences_department.department === 'Martinique' && 'MQ'}
                                    {user.preferences_department.department === 'Guadeloupe' && 'GP'}
                                </>
                            ) : 'NaN'}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default UserKanbanVivier;