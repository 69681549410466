import {dateToEpoch, dateWordMonthShort} from "../../../helper/helper";
import CopyToClipboard from "../../../components/copyToClipboard";
import {ArrowRight, Calendar, ExternalLink, NegativeThumb, PositiveThumb, Star, Warning} from "../../../assets/icons";
import Badge from "../../../components/badge/badge";
import Button from "../../../components/button";
import Tooltip from "../../../components/tooltips/Tooltips";
import UseContext from "../../../hooks/useContext";
import useSlideOver from "../../../components/SlideOver/useSlideOver";
import React from "react";

const MissionDetailTempWorker = ({tempWorker, index, selectedTempWorkerAssignation, setSelectedTempWorkerAssignation, missionDateStart, missionDateEnd}) => {
    const {setObjID}=UseContext()
    const {toggleSlideOver} = useSlideOver();

    const openTempWorkerInfo = (id) => {
        setObjID(id)
        toggleSlideOver('get_temp_worker')
    }

    return (
        <div key={index} className={'w-full flex gap-5 border rounded p-2 cursor-pointer'} onClick={() => setSelectedTempWorkerAssignation(tempWorker._id)}>
            <div className={'my-auto'}>
                <input type="radio" name={'changeAssignationTempWorker'} className={'cursor-pointer'} checked={selectedTempWorkerAssignation === tempWorker._id}/>
            </div>
            <div className={'my-auto flex justify-between w-full'}>
                <div className={'flex flex-col gap-2'}>
                    <div className={'flex flex-col'}>
                        <div className={'flex gap-1'}>
                            <div className={'my-auto'}>
                                <CopyToClipboard text={tempWorker.firstname + ' ' + tempWorker.lastname}>
                                    {tempWorker.firstname + ' ' + tempWorker.lastname}
                                </CopyToClipboard>
                            </div>
                            <div className={'my-auto'}>
                                n°{tempWorker.id_bestt}
                            </div>
                            <div className={'my-auto cursor-pointer'} onClick={() => openTempWorkerInfo(tempWorker?._id)}>
                                <ExternalLink wh={18} color={'#235FE6'}/>
                            </div>
                        </div>
                        <div className={''}>
                            <CopyToClipboard text={tempWorker.email}>
                                <a className={'underline'} href={`mailto:${tempWorker.email}`}>
                                    {tempWorker.email}
                                </a>
                            </CopyToClipboard>
                        </div>
                        <div className={''}>
                            <CopyToClipboard text={tempWorker.phone}>
                                <a className={'underline'} href={`tel:${tempWorker.phone}`}>
                                    {tempWorker.phone}
                                </a>
                            </CopyToClipboard>
                        </div>
                    </div>
                    {tempWorker.vivierExclude === false && (
                        <div className={'flex flex-col'}>
                            {
                                (tempWorker.tempworker.availablePeriodMission?.dateStart || tempWorker.tempworker.availablePeriodMission?.dateEnd) ? (
                                    <>
                                        {
                                            (dateToEpoch(tempWorker.tempworker.availablePeriodMission?.dateStart).getTime() === dateToEpoch(missionDateStart.toString()).getTime() && dateToEpoch(tempWorker.tempworker.availablePeriodMission?.dateEnd).getTime() === dateToEpoch(missionDateEnd.toString()).getTime()) ? (
                                                <div className={'flex gap-1'}>
                                                    <div className={'my-auto'}>
                                                        <Calendar wh={20} color={'#6B7280'}/>
                                                    </div>
                                                    <div className={'my-auto text-gray-500'}>
                                                        Toute la période
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    {
                                                        (dateToEpoch(tempWorker.tempworker.availablePeriodMission?.dateStart).getTime() === dateToEpoch(missionDateStart.toString()).getTime() && dateToEpoch(tempWorker.tempworker.availablePeriodMission?.dateEnd).getTime() !== dateToEpoch(missionDateEnd.toString()).getTime()) && (
                                                            <>
                                                                <div className={'flex gap-1 text-gray-500'}>
                                                                    <div className={'my-auto'}>
                                                                        <Warning wh={20} color={'#FF9600'}/>
                                                                    </div>
                                                                    <div className={'my-auto'}>
                                                                        {dateWordMonthShort(dateToEpoch(missionDateStart.toString()))}
                                                                    </div>
                                                                    <div className={'my-auto'}>
                                                                        <ArrowRight wh={20} color={'#6B7280'}/>
                                                                    </div>
                                                                    <div className={'my-auto'}>
                                                                        {dateWordMonthShort(dateToEpoch(tempWorker.tempworker.availablePeriodMission?.dateEnd))}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        (dateToEpoch(tempWorker.tempworker.availablePeriodMission?.dateStart).getTime() !== dateToEpoch(missionDateStart.toString()).getTime() && dateToEpoch(tempWorker.tempworker.availablePeriodMission?.dateEnd).getTime() === dateToEpoch(missionDateEnd.toString()).getTime()) && (
                                                            <>
                                                                <div className={'flex gap-1 text-gray-500'}>
                                                                    <div className={'my-auto'}>
                                                                        <Warning wh={20} color={'#FF9600'}/>
                                                                    </div>
                                                                    <div className={'my-auto'}>
                                                                        {dateWordMonthShort(dateToEpoch(tempWorker.tempworker.availablePeriodMission?.dateStart))}
                                                                    </div>
                                                                    <div className={'my-auto'}>
                                                                        <ArrowRight wh={20} color={'#6B7280'}/>
                                                                    </div>
                                                                    <div className={'my-auto'}>
                                                                        {dateWordMonthShort(dateToEpoch(missionDateEnd.toString()))}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        (dateToEpoch(tempWorker.tempworker.availablePeriodMission?.dateStart).getTime() !== dateToEpoch(missionDateStart.toString()).getTime() && dateToEpoch(tempWorker.tempworker.availablePeriodMission?.dateEnd).getTime() !== dateToEpoch(missionDateEnd.toString()).getTime()) && (
                                                            <>
                                                                <div className={'flex gap-1 text-gray-500'}>
                                                                    <div className={'my-auto'}>
                                                                        <Warning wh={20} color={'#FF9600'}/>
                                                                    </div>
                                                                    <div className={'my-auto'}>
                                                                        {dateWordMonthShort(dateToEpoch(tempWorker.tempworker.availablePeriodMission?.dateStart))}
                                                                    </div>
                                                                    <div className={'my-auto'}>
                                                                        <ArrowRight wh={20} color={'#6B7280'}/>
                                                                    </div>
                                                                    <div className={'my-auto'}>
                                                                        {dateWordMonthShort(dateToEpoch(tempWorker.tempworker.availablePeriodMission?.dateEnd))}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </>
                                ) : (
                                    <div className={'flex gap-1'}>
                                        <div className={'my-auto'}>
                                            <Warning wh={20} color={'#EF4444'}/>
                                        </div>
                                        <div className={'my-auto text-gray-500'}>
                                            Non disponible
                                        </div>
                                    </div>
                                )
                            }
                            {
                                tempWorker.tempworker.weekDaysStatus?.length > 0 && (
                                    <div className={'flex gap-1'}>
                                        {
                                            tempWorker.tempworker.weekDaysStatus.map((dayStatus, index) => {
                                                const days = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
                                                return (
                                                    <Tooltip key={index} text={dayStatus === null ? `Jour non travaillé mais intérimaire disponible.` : (dayStatus === 'not_worked_and_unavailable_day' ? `Jour non travaillé et intérimaire indisponible.` : (dayStatus === true ? `Jour travaillé et intérimaire disponible.` : `Jour travaillé mais intérimaire non disponible.`))} placement='top'>
                                                        <div className={`my-auto rounded w-[24px] h-[24px] text-center cursor-pointer ${dayStatus === null ? 'text-gray-500 bg-gray-100' : (dayStatus === 'not_worked_and_unavailable_day' ? 'text-yellow-500 bg-yellow-50' : (dayStatus === true ? 'text-green-500 bg-green-50' : 'text-red-500 bg-red-50'))}`}>
                                                            {days[index]}
                                                        </div>
                                                    </Tooltip>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                        </div>
                    )}
                </div>
                {tempWorker.vivierExclude === false ? (
                    <div className={'flex flex-col justify-between'}>
                        <div className={'flex flex-col gap-2'}>
                            <div className={'ml-auto'}>
                                {
                                    ((tempWorker.tempworker.identity.validated === "2" || tempWorker.tempworker.identity.validated === 2) && (tempWorker.tempworker.card_vitality.validated === "2" || tempWorker.tempworker.card_vitality.validated === 2) && (tempWorker.tempworker.proof_of_address.validated === "2" || tempWorker.tempworker.proof_of_address.validated === 2)) ? (
                                        <Badge type={'VALID'}>Complet</Badge>
                                    ) : (
                                        <Badge type={'ALERT'}>Incomplet</Badge>
                                    )
                                }
                            </div>
                            <div className={'flex gap-1'}>
                                {tempWorker.review?.type && (
                                    <>
                                        {tempWorker.review?.type === 'positive' && (
                                            <div className={'my-auto w-fit rounded-full p-2 bg-green-50'}>
                                                <PositiveThumb wh={20} color={'#10B981'}/>
                                            </div>
                                        )}
                                        {tempWorker.review?.type === 'negative' && (
                                            <div className={'my-auto w-fit rounded-full p-2 bg-red-50'}>
                                                <NegativeThumb wh={20} color={'#EF4444'}/>
                                            </div>
                                        )}
                                    </>
                                )}
                                {(tempWorker.rating !== null) && (
                                    <div className={'my-auto border rounded-full flex gap-1 px-1.5'}>
                                        <div className={'my-auto'}>
                                            <Star wh={18} color={'#FF9600'}/>
                                        </div>
                                        <div className={'my-auto'}>
                                            {tempWorker.rating}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={'ml-auto'}>
                            {
                                tempWorker.cv?.filePath ? (
                                    <a href={process.env.REACT_APP_FILE + tempWorker.cv.filePath} target={'_blank'}>
                                        <Button size={'SM'} color={'SECONDARY'}>CV</Button>
                                    </a>
                                ) : (
                                    <Button size={'SM'} color={'DISABLED'}>CV</Button>
                                )
                            }
                        </div>
                    </div>
                ) : (
                    <div className={'flex flex-col'}>
                        <div className={'my-auto'}>
                            <Badge type={'PRIMARY'}>Exclu</Badge>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default MissionDetailTempWorker;