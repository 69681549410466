import React, {useEffect, useState} from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import useModal from "components/modal/useModal";
import ApiDatabase from 'server'
import {toast} from "react-toastify";
import usefetchdata from "hooks/usefetchdata";
import Banner from "components/banner/banner";
import UseContext from "hooks/useContext";


const ModifyiIban = () => {
    const {toggle} = useModal()
    const {tempWorkerData}=usefetchdata()
    const {language,refreshLoadWorker} = UseContext()
    const pIban=!!tempWorkerData?.bank?.iban
    const [bank, setBank] = useState( '')

    useEffect(()=>{
        if(pIban) setBank(tempWorkerData?.bank?.iban)
    },[pIban])
    const handleChange = e => {
        e.preventDefault()
        setBank(e.target.value)
    }

    const handleSubmit = e => {
        e.preventDefault()
        ApiDatabase.postUpdateIban(
            {
                token: localStorage.getItem('xsrfToken'),
                iban:bank
            },
            (data) => {
                refreshLoadWorker()
                toast.success('L\'identité bancaire est modifiée.')
            },
            (err) => {
                if (err.name !== "CanceledError") {
                    toast.error('Un problème survenu')
                    console.log(err)
                }
            })
    }
    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                {language.getValue('justify.m_iban_title_u')}
            </Modalheader>
            <ModalBody>
                {tempWorkerData?.bank?.validated === '3' ?
                    <Banner type={'alert'}>Motif a venir</Banner>
                    :''}
                {tempWorkerData?.bank?.validated === '1' ?
                    <Banner type={'warning'}>Un document est déjà en cours d’observation, la modification reste possible.</Banner>
                    :''}
                <form className='grid grid-cols-2 gap-2'>
                    <div className="col-span-2">
                        <label
                            htmlFor="iban"
                            className="block ft-sm font-medium  text-gray-500"
                        >
                            Iban
                        </label>
                        <div className=" text-gray-500">
                            <input
                                name="iban"
                                pattern="([A-Z])[\w+.]{1,}"
                                value={bank}
                                onChange={handleChange}
                                className="w-full h-10 pl-2 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                type="text"
                                required
                            />
                        </div>
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'}
                        onClick={toggle}>Annuler</Button>
                <Button size={'LG'} onClick={handleSubmit}>Confirmer</Button>
            </ModalFooter>
        </>
    );
};

export default ModifyiIban;