import React, {useState, useCallback, useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";

function TabsF({children}) {
    const pathname = useLocation().pathname;
    const pathnameTab = pathname.split('/')[3] ? pathname.split('/')[2]+'/'+pathname.split('/')[3] : pathname.split('/')[2];

    const [activeTab, setActiveTab] = useState(pathnameTab);
    const handleActiveTab = useCallback(label => setActiveTab(label), [])
    const navigate = useNavigate()

    const tabs = children.map(child => (

        <div onClick={e => {
            e.preventDefault()
            handleActiveTab(child.props.label)
            navigate(`./${child.props.label}`)
        }}
             className={`flex items-center justify-center  py-3 px-4 cursor-pointer text-gray-500 ${child.props.label === activeTab ? 'h-9 bg-gray-100 text-gray-700 rounded-md ' : 'hover:bg-gray-100 hover:rounded-md hover:h-9 hover:text-gray-700'}`}
             key={child.props.label}>
            {child.props.tabName}
        </div>
    ))

    const tabContent = children.filter(child => child.props.label === activeTab)
    return (
        <div>
            <div className='flex justify-end items-center pr-4 gap-4  bg-white border-b-0 border-gray-100'>{tabs}</div>
            <div className=''>{tabContent}</div>
        </div>
    )
}

function TabF(props) {
    return <>{props.children}</>
}

export {TabsF, TabF}