import ModalBody from "../../../components/body";
import Modalheader from "../../../components/header";
import useModal from "../../../useModal";
import Divider from "../../../../divider/divider";
import EmptyState from "../../../../EmptyState/EmptyState";
import axios from "axios";
import {toast} from "react-toastify";
import {useEffect, useState} from "react";

const ActivityVivier = () => {
    const { toggle } = useModal()
    const [logViviers, setLogViviers] = useState({})

    const token = localStorage.getItem('xsrfToken')
    //get id vivier in url (last param)
    const url = window.location.href.split('/')
    const idVivier = url[url.length - 1]


    useEffect(() => {
        if(idVivier && token){
            axios.get(`${process.env.REACT_APP_API_URL}vivier/logVivier?token=${token}&idVivier=${idVivier}`).then((res) => {
                setLogViviers(res.data)
            }).catch((err) => {
                if (err.name !== "CanceledError") {
                    console.error(err)
                    toast.error('Une erreur est survenue')
                }
            })
        }
    }, [idVivier]);

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Activité du vivier
            </Modalheader>
            <ModalBody>
                {
                    (Object.keys(logViviers).length > 0) ? (
                        <>
                            <div className='flex flex-col gap-4 py-3'>
                                {
                                    logViviers.map((log, key) => {
                                        //transform date log.createdAt to dd/mm/yyyy, hh:ii
                                        const dateLogVivier = new Date(log.createdAt)
                                        const dateLogVivierFormat = `${('0' + dateLogVivier.getDate()).toString().slice(-2)}/${('0' + (dateLogVivier.getMonth() + 1)).toString().slice(-2)}/${dateLogVivier.getFullYear()}, ${('0' + dateLogVivier.getHours()).toString().slice(-2)}:${('0' + dateLogVivier.getMinutes()).toString().slice(-2)}`
                                        return (
                                            <>
                                                <div className='flex flex-col gap-1' key={key}>
                                                    <div className={'font-bold'}>Notifié le {dateLogVivierFormat}</div>
                                                    <div className={'text-gray-500'}>par {log.employee?.firstname} {log.employee?.lastname}</div>
                                                </div>
                                                <Divider />
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </>
                    ) : (
                        <EmptyState type={'log_vivier'}/>
                    )
                }
            </ModalBody>
        </>
    )
}

export default ActivityVivier;