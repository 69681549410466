import React from 'react';
import { useLocation} from "react-router-dom";
import Thead from "components/table/components/thead";
import Tbodycontract from "components/table/components/tbodycontract";
import Tbodyhours from "./components/tbodyhours";
import Tbodycompany from "./components/tbodycompany";
import Tbodycompanycoeff from "./components/Tbodycompanycoeff";
import Pagination from "../pagination/pagination";
import TbodyVivierRatingTempWorker from "./components/tbodyVivierRatingTempWorker";
import TbodyQualification from "./components/tbodyQualification";

const Table = ({champsHeader,champsBody, type, currentPage, countPerPage, countTotal, totalPages, setCurrentPage}) => {
    const route = useLocation()
    const {pathname}=route

    return (
        <div className='overflow-auto'>
            <table className="table-auto w-full text-gray-500 border-separate space-y-6 ft-sm">
                <thead>
                    <Thead champsHeader={champsHeader}/>
                </thead>
                <tbody>
                    {pathname.includes('contract')?<Tbodycontract champsBody={champsBody}/>:null}
                    {pathname.includes('timeSheet')?<Tbodyhours champsBody={champsBody}/>:null}
                    {(pathname.includes('company') && type !== 'coeff')?<Tbodycompany champsBody={champsBody}/>:null}
                    {(pathname.includes('company') && type === 'coeff')?<Tbodycompanycoeff champsBody={champsBody}/>:null}
                    {(pathname.includes('rating-treated'))?<TbodyVivierRatingTempWorker champsBody={champsBody}/>:null}
                    {(pathname.includes('rating-untreated'))?<TbodyVivierRatingTempWorker champsBody={champsBody}/>:null}
                    {(pathname.includes('qualification'))?<TbodyQualification champsBody={champsBody}/>:null}
                </tbody>
            </table>
            {
                (currentPage && totalPages > 1 && setCurrentPage) ? (
                    <div className={'px-8 py-4'}>
                        <Pagination currentPage={currentPage} countPerPage={countPerPage} countTotal={countTotal} totalPages={totalPages} setCurrentPage={setCurrentPage}/>
                    </div>
                ) : null
            }
        </div>
    );
};

export default Table;