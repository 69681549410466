import CopyToClipboard from "../../../../copyToClipboard";
import {
    Calendar,
    ChevronDown,
    ChevronRight,
    ClockIcon,
    CrossIcon,
    ExternalLink,
    Pencil,
    Plus,
    Warning,
} from "../../../../../assets/icons";
import BadgeSquare from "../../../../badge/badgeSquare";
import {dateWordShort} from "../../../../../helper/helper";
import React, {useEffect, useState} from "react";
import UseContext from "../../../../../hooks/useContext";
import UseModal from "../../../../modal/useModal";
import usefetchdata from "../../../../../hooks/usefetchdata";
import CopyToClipboardLarge from "../../../../copyToClipboard/copyToClipboardLarge";
import Button from "../../../../button";
import ApiDatabase from "../../../../../server";
import {toast} from "react-toastify";
import Badge from "../../../../badge/badge";
import Divider from "../../../../divider/divider";
import Experiences from "../../../../experiences/experiences";
import Licences from "../../../../licences/licences";
import Trainings from "../../../../trainings/trainings";
import Tooltip from "../../../../tooltips/Tooltips";

const PreviewTempWorker = ({ tempWorkerInfos }) => {
    const {setAdminLink, setAdminType, setAdminTempWorkerId, setInfoVivier, usersVivier, setUsersVivier, setUserVivierToArchive, userVivierToArchiveUpdated,setUserVivierToArchiveUpdated }=UseContext()
    const {userData} = usefetchdata()
    const {toggle} = UseModal();
    const [isVivierOpen, setIsVivierOpen] = useState(false);
    const [isTrainingGuidesOpen, setIsTrainingGuidesOpen] = useState(false);
    const [isIdentityFilesOpen, setIsIdentityFilesOpen] = useState(false);
    const [selectedVivierStatus, setSelectedVivierStatus] = useState({});
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [isIdentityFileOpen, setIsIdentityFileOpen] = useState({
        identity: false,
        visa: false,
        card_vitality: false,
    });
    const token = localStorage.getItem('xsrfToken')

    const kanbanColumns = [
        { name: 'wish', title: 'Souhait', icon: '🧞' },
        { name: 'test', title: 'Test', icon: '⏳' },
        { name: 'valid', title: 'Validé', icon: '✅' },
        { name: 'archive', title: 'Archivé', icon: '📦' }
    ];

    const openDocument = (type, type2, uri) => {
        setAdminLink(uri);
        setAdminType(type);

        if (type2 === 'view') {
            setAdminTempWorkerId('');
            toggle('ad_file_view');
        }
        else {
            setAdminTempWorkerId(tempWorkerInfos?.tempWorker?._id);
            if (type === 'identity') toggle('ad_file_accept_identity')
            else if (type === 'visa') toggle('ad_file_accept_visa')
            else if (type === 'card_vitality') toggle('ad_file_accept_card_vitality')
            else if (type === 'proof_of_address') toggle('ad_file_accept_simple')
            else if (type === 'accommodation_of_address') toggle('ad_file_accept_simple')
            else toggle('ad_file_view');
        }
    }

    useEffect(() => {
        if(usersVivier.length > 0) {
            for (let i = 0; i < usersVivier.length; i++) {
                if(usersVivier[i].id_user.toString() === tempWorkerInfos?.user?._id.toString()) {
                    tempWorkerInfos.viviers = usersVivier[i].viviers
                    tempWorkerInfos.tempWorker.preferences_department = usersVivier[i].preferences_department
                }
            }
            setUsersVivier([])
            setInfoVivier({listUsers: []})
            if (tempWorkerInfos.viviers?.length === 0) setIsVivierOpen(false)
        }
    }, [usersVivier])

    const addVivierSelected = (e, idUser) => {
        e.stopPropagation();
        setInfoVivier({listUsers: [idUser]})
        toggle("vivier_add")
    }

    const handleChangeVivierStatus = (idVivier, vivierStatus) => {
        if (idVivier && vivierStatus) {
            if (vivierStatus === 'archive') {
                setUserVivierToArchive({
                    idUsers: [tempWorkerInfos?.user?._id],
                    idVivier,
                    vivierStatus: vivierStatus
                })

                toggle('vivier_user_archive')
            } else {
                ApiDatabase.updUserVivierPreferenceStatus({
                    token,
                    idUsers: [tempWorkerInfos?.user?._id],
                    idVivier,
                    vivierStatus: vivierStatus
                }, (data) => {
                    if (!isToastVisible) {
                        setIsToastVisible(true);
                        toast.success(`L'utilisateur a été déplacé.`)
                    }

                    setSelectedVivierStatus(prevData => ({
                        ...prevData,
                        [idVivier]: vivierStatus
                    }))
                }, (err) => {
                    console.log('err', err)
                })
            }
        }
    }

    useEffect(() => {
        if (userVivierToArchiveUpdated.idUsers && userVivierToArchiveUpdated.idVivier && userVivierToArchiveUpdated.vivierStatus) {
            for (let i = 0; i < userVivierToArchiveUpdated.idUsers.length; i++) {
                if (tempWorkerInfos?.user?._id === userVivierToArchiveUpdated.idUsers[i]) {
                    setSelectedVivierStatus(prevData => ({
                        ...prevData,
                        [userVivierToArchiveUpdated.idVivier]: userVivierToArchiveUpdated.vivierStatus
                    }))
                }
            }
            setUserVivierToArchiveUpdated({})
        }
    }, [userVivierToArchiveUpdated]);

    useEffect(() => {
        if (tempWorkerInfos?.viviers?.length > 0) {
            for (let i = 0; i < tempWorkerInfos?.viviers.length; i++) {
                for (let j = 0; j < tempWorkerInfos?.viviers[i].user_preferences?.length; j++) {
                    if (tempWorkerInfos?.viviers[i].user_preferences[j].id_user === tempWorkerInfos?.user?._id) {
                        setSelectedVivierStatus(prevData => ({
                            ...prevData,
                            [tempWorkerInfos?.viviers[i]._id]: tempWorkerInfos?.viviers[i].user_preferences[j].status
                        }))
                        break;
                    }
                }
            }
        }
    }, [tempWorkerInfos?.viviers])

    useEffect(() => {
        if (isToastVisible) {
            const timeoutId = setTimeout(() => {
                setIsToastVisible(false);
            }, 3000);

            return () => clearTimeout(timeoutId);
        }
    }, [isToastVisible]);

    const excludeVivier = (idVivier) => {
        setInfoVivier({
            idVivier: idVivier,
            nameUser: tempWorkerInfos?.user?.firstname + " " + tempWorkerInfos?.user?.lastname,
            listUsers: [tempWorkerInfos?.user?._id]
        })
        toggle("vivier_delete_candidate")
    }

    const changeLocalisation = (idUser) => {
        setInfoVivier({listUsers: [idUser]})
        toggle("localisation_preferences")
    }

    const getAvailabilityText = () => {
        const availability = tempWorkerInfos?.tempWorker?.availability;

        if (!availability) {
            return 'Non renseigné';
        }

        const { is_available, date_start, date_end } = availability;

        if (is_available === false) {
            if (!date_start && !date_end) {
                return 'Non disponible';
            } else if (date_start && !date_end) {
                return 'Non disponible jusqu\'au ' + dateWordShort(new Date(date_start));
            } else if (!date_start && date_end) {
                return 'Non disponible à partir du ' + dateWordShort(new Date(date_end));
            } else {
                return 'Non disponible du ' + dateWordShort(new Date(date_start)) + ' au ' + dateWordShort(new Date(date_end));
            }
        } else if (is_available === true) {
            if (!date_start && !date_end) {
                return 'Disponibilité complète';
            } else if (date_start && !date_end) {
                return 'Disponible à partir du ' + dateWordShort(new Date(date_start));
            } else if (!date_start && date_end) {
                return 'Disponible jusqu\'au ' + dateWordShort(new Date(date_end));
            } else {
                return 'Disponible du ' + dateWordShort(new Date(date_start)) + ' au ' + dateWordShort(new Date(date_end));
            }
        } else {
            return 'Non renseigné';
        }
    };

    const getAvailabilityStatus = (unavailableDays) => {
        const days = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
        return days.map((day, index) => {
            return {
                day,
                status: unavailableDays.includes(index) ? false : true
            };
        });
    };

    return (
        <>
            <div className={'grid gap-2 grid-cols-1 md:grid-cols-2'}>
                <div className={'border rounded p-3 flex justify-between items-center'}>
                    <CopyToClipboardLarge libelle={'Adresse e-mail'} text={tempWorkerInfos?.user.email}/>
                </div>
                <div className={'border rounded p-3'}>
                    <CopyToClipboardLarge libelle={'Numéro'} text={tempWorkerInfos?.user.phone}/>
                </div>
                <div className={'border rounded p-3'}>
                    <div>Date de naissance</div>
                    <div className={'text-gray-500 text-sm'}>
                        {tempWorkerInfos?.user.birthday}
                    </div>
                </div>
                <div className={'border rounded p-3'}>
                    <div className={'flex justify-between gap-2 items-center'}>
                        <div>
                            <div>Localisation</div>
                            <div className={'text-gray-500 text-sm'}>
                                {
                                    tempWorkerInfos?.tempWorker?.preferences_department?.city && tempWorkerInfos?.tempWorker?.preferences_department?.city
                                        ? tempWorkerInfos?.tempWorker?.preferences_department?.city + ', ' + tempWorkerInfos?.tempWorker?.preferences_department?.department
                                        : 'Non renseigné'
                                }
                            </div>
                        </div>
                        <div className={'border rounded w-8 h-8 flex justify-center items-center cursor-pointer'}
                             onClick={() => changeLocalisation(tempWorkerInfos?.user._id)}
                        >
                            <Pencil wh={20} color={'#374151'}/>
                        </div>
                    </div>
                </div>
                <div className={'border rounded p-3'}>
                    <div className={'flex justify-between gap-2 items-center'}>
                        <div>
                            <div>Nationalité</div>
                            <div className={'text-gray-500 text-sm'}>
                                {tempWorkerInfos?.tempWorker?.identity?.nationality ? tempWorkerInfos?.tempWorker?.identity?.nationality.NationaliteF : 'Non renseigné'}
                            </div>
                        </div>
                        <div className={'flex gap-1 items-center'}>
                            {tempWorkerInfos?.tempWorker?.visa?.end_validity && (
                                <>
                                    {(new Date(tempWorkerInfos?.tempWorker?.visa?.end_validity).getTime() < new Date().getTime()) && (
                                        <Tooltip text={`Date de fin de visa : ${dateWordShort(new Date(tempWorkerInfos?.tempWorker?.visa?.end_validity))}`} placement='top'>
                                            <div>
                                                <ClockIcon wh={20} color={'#DC2626'}/>
                                            </div>
                                        </Tooltip>
                                    )}
                                </>
                            )}
                            {tempWorkerInfos?.tempWorker?.identity?.nationality?.UnionEuropéenne === 0 && (
                                <Tooltip text={`Pays hors UE`} placement='top'>
                                    <div>
                                        <Warning wh={20} color={'#D77A1E'}/>
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                </div>
                <div className={'border rounded p-3'}>
                    {tempWorkerInfos?.tempWorker?.bank?.iban ? (
                        <CopyToClipboardLarge libelle={'IBAN'} text={tempWorkerInfos?.tempWorker?.bank?.iban.replace(/\s/g, '')}/>
                    ) : (
                        <>
                            <div>IBAN</div>
                            <div className={'text-gray-500 text-sm'}>Non renseigné</div>
                        </>
                    )}
                </div>
                <div className={'border rounded p-3 col-span-full'}>
                    <div className={'flex flex-col gap-1'}>
                        <div className={'flex gap-1 items-center'}>
                            <div>
                                <Calendar wh={20} color={'#374151'}/>
                            </div>
                            <div>
                                {getAvailabilityText()}
                            </div>
                        </div>
                        <div className={'flex gap-1 items-center'}>
                            {getAvailabilityStatus(tempWorkerInfos?.tempWorker?.unavailable_days ?? []).map((day, index) => (
                                <div className={`my-auto rounded w-[24px] h-[24px] flex justify-center items-center cursor-pointer ${day.status ? 'text-green-500 bg-green-50' : 'text-gray-500 bg-gray-100'}`}>
                                    <Tooltip text={day.status ? 'Disponible' : 'Non disponible'} placement='top'>
                                        <div>
                                            {day.day}
                                        </div>
                                    </Tooltip>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className={'border rounded p-3 flex flex-col gap-6'}>
                <div className={'flex justify-between select-none cursor-pointer'}
                     onClick={() => setIsIdentityFilesOpen(!isIdentityFilesOpen)}>
                    <div className={'my-auto'}>Fichiers d'identité</div>
                    <div className={'my-auto'}>
                    <div className={'flex cursor-pointer border rounded w-8 h-8 items-center justify-center'}>
                            {isIdentityFilesOpen ? <ChevronDown w={24} h={10} color={'#374151'}/> : <ChevronRight wh={24} color={'#374151'}/>}
                        </div>
                    </div>
                </div>
                {isIdentityFilesOpen && (
                    <div className={'grid gap-2 grid-cols-1 md:grid-cols-2'}>
                        {/*Identity*/}
                        <div className={'p-2 border rounded h-fit'}>
                            <div className={'flex justify-between gap-2 select-none'}>
                                <div className={'my-auto flex gap-1'}>
                                    <div className={'my-auto'}>
                                        {tempWorkerInfos.tempWorker?.identity?.type === 'Passeport' ? 'Passeport' : 'Carte d\'identité'}
                                    </div>
                                    <>
                                        {(() => {
                                            let filePathFinal = null
                                            const filePath1 = process.env.REACT_APP_FILE + tempWorkerInfos?.tempWorker?.identity?.fileR?.filePath
                                            const filePath2 = tempWorkerInfos?.tempWorker?.identity?.fileV?.filePath ? process.env.REACT_APP_FILE + tempWorkerInfos?.tempWorker?.identity?.fileV?.filePath : null
                                            if(filePath2 === null) filePathFinal = {filePath1}
                                            else filePathFinal = {filePath1, filePath2}

                                            return (
                                                <>
                                                    {
                                                        filePathFinal && (
                                                            <>
                                                                <div className={'my-auto cursor-pointer'}
                                                                     onClick={() => openDocument('identity', 'view', filePathFinal)}>
                                                                    <ExternalLink wh={18} color={'#235fe6'}/>
                                                                </div>
                                                                <div className={'my-auto cursor-pointer'}
                                                                     onClick={() => openDocument('identity', 'edit', filePathFinal)}>
                                                                    <Pencil wh={18} color={'#235fe6'}/>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </>
                                            )
                                        })()}
                                    </>
                                </div>
                                <div className={'my-auto flex gap-2 cursor-pointer'} onClick={() => {
                                    setIsIdentityFileOpen(prevData => ({
                                        ...prevData,
                                        identity: !isIdentityFileOpen.identity,
                                    }))
                                }}>
                                    <div className={'my-auto'}>
                                        <BadgeSquare type={tempWorkerInfos.tempWorker?.identity?.validated === "2" ? "5" : tempWorkerInfos.tempWorker?.identity?.validated}/>
                                    </div>
                                    <div className={'my-auto'}>
                                        <ChevronRight wh={20} color={'#374151'}/>
                                    </div>
                                </div>
                            </div>
                            {isIdentityFileOpen.identity && (
                                <div className={'flex flex-col gap-2 text-sm mt-2'}>
                                    <div className={'flex gap-1'}>
                                        <div className={'my-auto font-extrabold'}>Numéro d'identité :</div>
                                        <div className={'my-auto'}>
                                            {tempWorkerInfos.tempWorker?.identity?.number_identity ? (
                                                <CopyToClipboard text={tempWorkerInfos.tempWorker?.identity?.number_identity}>
                                                    <div className={'cursor-pointer'}>{tempWorkerInfos.tempWorker?.identity?.number_identity}</div>
                                                </CopyToClipboard>
                                            ) : 'NaN'}
                                        </div>
                                    </div>
                                    <div className={'flex gap-1'}>
                                        <div className={'my-auto font-extrabold'}>Ville de naissance :</div>
                                        <div className={'my-auto'}>
                                            {tempWorkerInfos.tempWorker?.identity?.birthplace ? (
                                                <CopyToClipboard text={tempWorkerInfos.tempWorker?.identity?.birthplace}>
                                                    <div className={'cursor-pointer'}>{tempWorkerInfos.tempWorker?.identity?.birthplace}</div>
                                                </CopyToClipboard>
                                            ) : 'NaN'}
                                        </div>
                                    </div>
                                    <div className={'flex gap-1'}>
                                        <div className={'my-auto font-extrabold'}>Validité :</div>
                                        <div className={'my-auto'}>{tempWorkerInfos.tempWorker?.identity?.start_validity ? dateWordShort(tempWorkerInfos.tempWorker?.identity?.start_validity) : 'NaN'} - {tempWorkerInfos.tempWorker?.identity?.end_validity ? dateWordShort(tempWorkerInfos.tempWorker?.identity?.end_validity) : 'NaN'}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/*Visa*/}
                        {tempWorkerInfos.tempWorker.visa?.type && (
                            <div className={'p-2 border rounded h-fit'}>
                                <div className={'flex justify-between gap-2 select-none'}>
                                    <div className={'my-auto flex gap-1'}>
                                        <div className={'my-auto'}>
                                            {tempWorkerInfos.tempWorker.visa?.type === 'Visa' ? 'Visa' : 'Titre de séjour'}
                                        </div>
                                        <>
                                            {(() => {
                                                let filePathFinal = null
                                                const filePath1 = process.env.REACT_APP_FILE + tempWorkerInfos?.tempWorker?.visa?.fileR?.filePath
                                                const filePath2 = tempWorkerInfos?.tempWorker?.visa?.fileV?.filePath ? process.env.REACT_APP_FILE + tempWorkerInfos?.tempWorker?.identity?.fileV?.filePath : null
                                                if(filePath2 === null) filePathFinal = {filePath1}
                                                else filePathFinal = {filePath1, filePath2}

                                                return (
                                                    <>
                                                        {
                                                            filePathFinal && (
                                                                <>
                                                                    <div className={'my-auto cursor-pointer'} onClick={() => openDocument('visa', 'view', filePathFinal)}>
                                                                        <ExternalLink wh={18} color={'#235fe6'}/>
                                                                    </div>
                                                                    <div className={'my-auto cursor-pointer'} onClick={() => openDocument('visa', 'edit', filePathFinal)}>
                                                                        <Pencil wh={18} color={'#235fe6'}/>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    </>
                                                )
                                            })()}
                                        </>
                                    </div>
                                    <div className={'my-auto flex gap-2 cursor-pointer'} onClick={() => {
                                        setIsIdentityFileOpen(prevData => ({
                                            ...prevData,
                                            visa: !isIdentityFileOpen.visa,
                                        }))
                                    }}>
                                        <div className={'my-auto'}>
                                            <BadgeSquare type={tempWorkerInfos.tempWorker?.visa?.validated === "2" ? "5" : tempWorkerInfos.tempWorker?.visa?.validated}/>
                                        </div>
                                        <div className={'my-auto'}>
                                            <ChevronRight wh={20} color={'#374151'}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={'flex flex-col gap-2 text-sm mt-2'}>
                                    <div className={'flex gap-1'}>
                                        <div className={'my-auto font-extrabold'}>Numéro :</div>
                                        <div className={'my-auto'}>
                                            {tempWorkerInfos.tempWorker?.visa?.number_visa ? (
                                                <CopyToClipboard text={tempWorkerInfos.tempWorker?.visa?.number_visa}>
                                                    <div className={'cursor-pointer'}>{tempWorkerInfos.tempWorker?.visa?.number_visa}</div>
                                                </CopyToClipboard>
                                            ) : 'NaN'}
                                        </div>
                                    </div>
                                    <div className={'flex gap-1'}>
                                        <div className={'my-auto font-extrabold'}>Validité :</div>
                                        <div className={'my-auto'}>{tempWorkerInfos.tempWorker?.visa?.start_validity ? dateWordShort(tempWorkerInfos.tempWorker?.visa?.start_validity) : 'NaN'} - {tempWorkerInfos.tempWorker?.visa?.end_validity ? dateWordShort(tempWorkerInfos.tempWorker?.visa?.end_validity) : 'NaN'}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/*Card vitality*/}
                        <div className={'p-2 border rounded h-fit'}>
                            <div className={'flex justify-between gap-2 select-none'}>
                                <div className={'my-auto flex gap-1'}>
                                    <div className={'my-auto'}>
                                        Carte vitale
                                    </div>
                                    <>
                                        {(() => {
                                            let filePathFinal = null
                                            const filePath1 = process.env.REACT_APP_FILE + tempWorkerInfos?.tempWorker?.card_vitality?.file?.filePath
                                            if(filePath1) filePathFinal = {filePath1}

                                            return (
                                                <>
                                                    {
                                                        filePathFinal && (
                                                            <>
                                                                <div className={'my-auto cursor-pointer'}
                                                                     onClick={() => openDocument('card_vitality', 'view', filePathFinal)}>
                                                                    <ExternalLink wh={18} color={'#235fe6'}/>
                                                                </div>
                                                                <div className={'my-auto cursor-pointer'}
                                                                     onClick={() => openDocument('card_vitality', 'edit', filePathFinal)}>
                                                                    <Pencil wh={18} color={'#235fe6'}/>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </>
                                            )
                                        })()}
                                    </>
                                </div>
                                <div className={'my-auto flex gap-2 cursor-pointer'} onClick={() => {
                                    setIsIdentityFileOpen(prevData => ({
                                        ...prevData,
                                        card_vitality: !isIdentityFileOpen.card_vitality,
                                    }))
                                }}>
                                    <div className={'my-auto'}>
                                        <BadgeSquare type={tempWorkerInfos.tempWorker?.card_vitality?.validated === "2" ? "5" : tempWorkerInfos.tempWorker?.card_vitality?.validated}/>
                                    </div>
                                    <div className={'my-auto'}>
                                        <ChevronRight wh={20} color={'#374151'}/>
                                    </div>
                                </div>
                            </div>
                            {isIdentityFileOpen.card_vitality && (
                                <div className={'flex flex-col gap-2 text-sm mt-2'}>
                                    <div className={'flex gap-1'}>
                                        <div className={'my-auto font-extrabold'}>Numéro :</div>
                                        <div className={'my-auto'}>
                                            {tempWorkerInfos.tempWorker?.card_vitality?.social_security ? (
                                                <CopyToClipboard text={tempWorkerInfos.tempWorker?.card_vitality?.social_security}>
                                                    <div className={'cursor-pointer'}>{tempWorkerInfos.tempWorker?.card_vitality?.social_security}</div>
                                                </CopyToClipboard>
                                            ) : 'NaN'}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/*Proof of address*/}
                        <div className={'p-2 border rounded h-fit'}>
                            <div className={'flex justify-between gap-2 select-none'}>
                                <div className={'my-auto flex gap-1'}>
                                    <div className={'my-auto'}>
                                        Justificatif d'adresse
                                    </div>
                                    <>
                                        {(() => {
                                            let filePathFinal = null
                                            const filePath1 = process.env.REACT_APP_FILE + tempWorkerInfos?.tempWorker?.proof_of_address?.file?.filePath
                                            if(filePath1) filePathFinal = {filePath1}

                                            return (
                                                <>
                                                    {
                                                        filePathFinal && (
                                                            <>
                                                                <div className={'my-auto cursor-pointer'}
                                                                     onClick={() => openDocument('proof_of_address', 'view', filePathFinal)}>
                                                                    <ExternalLink wh={18} color={'#235fe6'}/>
                                                                </div>
                                                                <div className={'my-auto cursor-pointer'}
                                                                     onClick={() => openDocument('proof_of_address', 'edit', filePathFinal)}>
                                                                    <Pencil wh={18} color={'#235fe6'}/>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </>
                                            )
                                        })()}
                                    </>
                                </div>
                                <div className={'my-auto'}>
                                    <BadgeSquare type={tempWorkerInfos.tempWorker?.proof_of_address?.validated === "2" ? "5" : tempWorkerInfos.tempWorker?.proof_of_address?.validated}/>
                                </div>
                            </div>
                        </div>
                        {/*Accommodation of address*/}
                        {tempWorkerInfos.tempWorker?.accommodation_of_address?.file && (
                            <div className={'p-2 border rounded h-fit'}>
                                <div className={'flex justify-between gap-2 select-none'}>
                                    <div className={'my-auto flex gap-1'}>
                                        <div className={'my-auto'}>
                                            Attestation d'hébergement
                                        </div>
                                        <>
                                            {(() => {
                                                let filePathFinal = null
                                                const filePath1 = process.env.REACT_APP_FILE + tempWorkerInfos?.tempWorker?.accommodation_of_address?.file?.filePath
                                                if(filePath1) filePathFinal = {filePath1}

                                                return (
                                                    <>
                                                        {
                                                            filePathFinal && (
                                                                <>
                                                                    <div className={'my-auto cursor-pointer'}
                                                                         onClick={() => openDocument('accommodation_of_address', 'view', filePathFinal)}>
                                                                        <ExternalLink wh={18} color={'#235fe6'}/>
                                                                    </div>
                                                                    <div className={'my-auto cursor-pointer'}
                                                                         onClick={() => openDocument('accommodation_of_address', 'edit', filePathFinal)}>
                                                                        <Pencil wh={18} color={'#235fe6'}/>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    </>
                                                )
                                            })()}
                                        </>
                                    </div>
                                    <div className={'my-auto'}>
                                        <BadgeSquare type={tempWorkerInfos.tempWorker?.accommodation_of_address?.validated === "2" ? "5" : tempWorkerInfos.tempWorker?.accommodation_of_address?.validated}/>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className={'border rounded p-3 flex flex-col gap-6'}>
                <div className={'flex justify-between select-none cursor-pointer'} onClick={() => setIsTrainingGuidesOpen(!isTrainingGuidesOpen)}>
                    <div className={'my-auto'}>Guides & Formations</div>
                    <div className={'my-auto'}>
                        <div className={'flex cursor-pointer border rounded w-8 h-8 items-center justify-center'}>
                            {isTrainingGuidesOpen ? <ChevronDown w={24} h={10} color={'#374151'}/> :
                                <ChevronRight wh={24} color={'#374151'}/>}
                        </div>
                    </div>
                </div>
                {isTrainingGuidesOpen && (
                    <div className={'flex flex-col gap-6'}>
                        <div className={'flex justify-between gap-2'}>
                            <div className={'my-auto'}>Guide de bienvenue</div>
                            <div className={'my-auto'}>
                                <BadgeSquare type={tempWorkerInfos?.tempWorker?.welcome_guide?.is_read ? 6 : 7}/>
                            </div>
                        </div>
                        <div className={'flex justify-between gap-2'}>
                            <div className={'my-auto'}>Livret de sécurité</div>
                            <div className={'my-auto'}>
                                <BadgeSquare type={tempWorkerInfos?.tempWorker?.safety_booklet?.is_read ? 6 : 7}/>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className={'border rounded p-3 flex flex-col gap-6'}>
                <div className={'flex justify-between select-none cursor-pointer'}
                     {...(tempWorkerInfos.viviers.length > 0 ? { onClick: () => setIsVivierOpen(!isVivierOpen) } : {})}
                >
                    <div className={'my-auto'}>Vivier</div>
                    <div className={'my-auto'}>
                        {tempWorkerInfos.viviers.length === 0 ? (
                            <Button color={'SECONDARY'} size={'SM'} onClick={(e) => addVivierSelected(e, tempWorkerInfos?.user?._id)}>
                                <div className={'flex gap-1 items-center'}>
                                    <div className={''}><Plus wh={20} color={'#374151'}/></div>
                                    <div className={''}>Ajouter</div>
                                </div>
                            </Button>
                        ) : (
                            <>
                                <div className={'flex gap-2 items-center'}>
                                    <div className={'text-gray-500 text-sm'}>
                                        {tempWorkerInfos.viviers.length === 1 ? '1 vivier' : tempWorkerInfos.viviers.length + ' viviers'}
                                    </div>
                                    <div className={'flex cursor-pointer border rounded w-8 h-8 items-center justify-center'} onClick={(e) => addVivierSelected(e, tempWorkerInfos?.user?._id)}>
                                        <Plus wh={24} color={'#374151'}/>
                                    </div>
                                    <div className={'flex cursor-pointer border rounded w-8 h-8 items-center justify-center'}>
                                        {isVivierOpen ? <ChevronDown w={24} h={10} color={'#374151'}/> : <ChevronRight wh={24} color={'#374151'}/>}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {isVivierOpen && (
                    <div className={'flex flex-col gap-6'}>
                        {tempWorkerInfos.viviers?.map((vivier, index) => (
                            <div key={index} className={'flex justify-between gap-2 items-center'}>
                                <div className={''}>{vivier.name}</div>
                                <div className={'flex gap-2 items-center'}>
                                    <div className={''}>
                                        {selectedVivierStatus[vivier._id] === 'archive' ? (
                                            <div className={'w-full px-2 py-1 ft-b border rounded-lg text-gray-900 bg-gray-100 flex items-center'}>
                                                {kanbanColumns.map((kanban, index) => {
                                                    if (selectedVivierStatus[vivier._id] === kanban.name) {
                                                        return <div key={index} className={'flex gap-1 items-center'}>
                                                            <div className={'my-auto'}>{kanban.icon}</div>
                                                            <div className={'my-auto'}>{kanban.title}</div>
                                                        </div>
                                                    }
                                                })}
                                            </div>
                                        ) : (
                                            <>
                                                {vivier._id.toString() !== "64e8cfd10b4f73f442432c9f" && (
                                                    <select
                                                        className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                                                        value={selectedVivierStatus[vivier._id]}
                                                        onChange={e => handleChangeVivierStatus(vivier._id, e.target.value)}
                                                    >
                                                        {kanbanColumns.map((kanban, index) => (
                                                            <option key={index}
                                                                    value={kanban.name}>{kanban.icon} - {kanban.title}</option>
                                                        ))}
                                                    </select>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div className={'cursor-pointer'} onClick={() => excludeVivier(vivier._id)}>
                                        <CrossIcon wh={20} color={'#374151'}/>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className={'border rounded p-3 flex flex-col gap-6'}>
                <div className={'flex flex-col gap-4'}>
                    <div className={'flex justify-between gap-2 items-center'}>
                        <div>Langues</div>
                        <div className={'border rounded bg-gray-100 w-5 h-5 flex justify-center items-center text-sm'}>
                            {tempWorkerInfos?.tempWorker?.language?.length}
                        </div>
                    </div>
                    {tempWorkerInfos?.tempWorker?.language?.length > 0 && (
                        <div className={'flex gap-2 flex-wrap'}>
                            {tempWorkerInfos?.tempWorker?.language?.map((language, index) => (
                                <Badge key={index} type={'WARNING'}>{language}</Badge>
                            ))}
                        </div>
                    )}
                </div>
                <Divider/>
                <div className={'flex flex-col gap-4'}>
                    <div className={'flex justify-between gap-2 items-center'}>
                        <div>Formations</div>
                        <div className={'border rounded bg-gray-100 w-5 h-5 flex justify-center items-center text-sm'}>
                            {tempWorkerInfos?.tempWorker?.formations?.length}
                        </div>
                    </div>
                    {tempWorkerInfos?.tempWorker?.formations?.length > 0 && (
                        <div className={'flex flex-col gap-4 text-sm'}>
                            {tempWorkerInfos?.tempWorker?.formations && (
                                <Trainings formations={tempWorkerInfos.tempWorker.formations}/>
                            )}
                        </div>
                    )}
                </div>
                <Divider/>
                <div className={'flex flex-col gap-4'}>
                    <div className={'flex justify-between gap-2 items-center'}>
                        <div>Permis</div>
                        <div className={'border rounded bg-gray-100 w-5 h-5 flex justify-center items-center text-sm'}>
                            {tempWorkerInfos?.tempWorker?.licences?.length}
                        </div>
                    </div>
                    {tempWorkerInfos?.tempWorker?.licences?.length > 0 && (
                        <div className={'flex flex-col gap-4 text-sm'}>
                            {tempWorkerInfos?.tempWorker?.licences && (
                                <Licences licences={tempWorkerInfos.tempWorker.licences}/>
                            )}
                        </div>
                    )}
                </div>
                <Divider/>
                <div className={'flex flex-col gap-4'}>
                    <div className={'flex justify-between gap-2 items-center'}>
                        <div>Expériences</div>
                        <div className={'border rounded bg-gray-100 w-5 h-5 flex justify-center items-center text-sm'}>
                            {tempWorkerInfos?.tempWorker?.experiences?.length}
                        </div>
                    </div>
                    {tempWorkerInfos?.tempWorker?.experiences?.length > 0 && (
                        <div className={'flex flex-col gap-4 text-sm'}>
                            {tempWorkerInfos?.tempWorker?.experiences && (
                                <Experiences experiences={tempWorkerInfos.tempWorker.experiences}/>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default PreviewTempWorker