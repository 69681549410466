import React, {useState, useEffect, useContext} from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import {checkPassword} from "helper/helper";
import {
    Information, InterrogationCercle,
    NoShowPassword,
    ShowPassword,
    ValidationCercle
} from "assets/icons";
import ApiDatabase from 'server'
import useModal from "components/modal/useModal";
import UseContext from "hooks/useContext";


const Modalmodifypwd = () => {
    const {refreshLoadUser} = UseContext()
    const {toggle}=useModal()
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newConfirmPassowrd, setNewConfirmPassword] = useState('')
    const [valid, setValid] = useState(false)
    const [verify, setVerify] = useState(false)
    const [validMatch, setValidMatch] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showNewPasswordConfirm, setShowNewPasswordConfirm] = useState(false)
    const [lower, setLower] = useState(false)
    const [upper, setUpper] = useState(false)
    const [number, setNumber] = useState(false)
    const [special, setSpecial] = useState(false)
    const [length, setLength] = useState(false)

    const handleShowPassword = (e) => setShowPassword(!showPassword)
    const handleShowPasswordNew = (e) => setShowNewPassword(!showNewPassword)
    const handleShowPassordNewConfirm = (e) => setShowNewPasswordConfirm(!showNewPasswordConfirm)
    const handleCheck = (e) => {
        const result = checkPassword(newPassword)
        setLower(result.lower)
        setNumber(result.number)
        setLength(result.length)
        setUpper(result.upper)
        setSpecial(result.special)
        if (length && special && number && upper && lower) setValid(true)

    }
    useEffect(() => {
        setVerify(password !== newPassword)
        if (verify) {
        }
    }, [password, newPassword])
    useEffect(() => {
        setValidMatch(newPassword === newConfirmPassowrd);
        if (validMatch) {
        }
    }, [newPassword, newConfirmPassowrd]);

    const handleSubmit = (e) => {
        e.preventDefault()

        if (validMatch && password !== '' && verify) {
            ApiDatabase.postPasswordRefresh({password, newPassword,id:localStorage.getItem('xsrfToken')},
                (data) => {
                    if (data.success) {
                        setSuccess(true)
                        refreshLoadUser()
                        setTimeout(() => {
                            setSuccess(false)
                            setPassword('')
                            setNewPassword('')
                            setNewConfirmPassword('')
                        }, 4000)
                    }
                    if (data.error) {
                        setError(true)
                        setTimeout(() => {
                            setError(false)
                        }, 3000)
                    }
                },
                (err) => {
                    setError(true)
                    setTimeout(() => {
                        setError(false)
                    }, 3000)
                })
        }
    }
    return (
        <>
                <Modalheader hide={toggle} hideShow={true}>
                    Changer le mot de passe
                </Modalheader>
                <ModalBody>
                    <form>
                        <div className='grid grid-cols-12 gap-6'>
                            <div className="col-span-12">
                            {success ?
                                <span
                                    className='ft-b text-green-400 flex items-center justify-center my-4'>Votre modification a bien été validée</span> : ''}
                            {error ?
                                <span
                                    className='ft-b text-red-400 flex items-center justify-center my-4'>Une erreur est intervenu, veuillez saisir a nouveau</span> : ''}
                            </div>
                            <div className="col-span-12">
                                <label className="block ft-sm font-medium text-gray-500">
                                    Mot de passe actuel
                                </label>
                                <div className="relative text-gray-500">
                                    <input
                                        name="password"
                                        value={password}
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                        }}
                                        onKeyUp={handleCheck}
                                        className={`w-full h-10 pl-3 pr-8 ft-b border rounded-lg  `}
                                        type={showPassword ? "text" : "password"}
                                        required
                                    />
                                    <div
                                        className="absolute inset-y-0 right-0 flex items-center px-2 cursor-pointer"
                                        onClick={handleShowPassword}
                                    >
                                        {showPassword ? (
                                            <NoShowPassword wh={18} color={'#666666'}
                                                            strokeW={2}/>
                                        ) : (
                                            <ShowPassword wh={18} color={'#666666'}
                                                          strokeW={2}/>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-12">
                                <label className="block ft-sm font-medium text-gray-500">
                                    Nouveau mot de passe
                                </label>
                                <div className="relative text-gray-500">
                                    <input
                                        name="password"
                                        value={newPassword}
                                        onChange={(e) => {
                                            setNewPassword(e.target.value);
                                        }}
                                        onKeyUp={handleCheck}
                                        className={`w-full h-10 pl-3 pr-8 ft-b border rounded-lg  `}
                                        type={showNewPassword ? "text" : "password"}
                                        required
                                    />
                                    <div
                                        className="absolute inset-y-0 right-0 flex items-center px-2 cursor-pointer"
                                        onClick={handleShowPasswordNew}
                                    >
                                        {showNewPassword ? (
                                            <NoShowPassword wh={18} color={'#666666'}
                                                            strokeW={2}/>
                                        ) : (
                                            <ShowPassword wh={18} color={'#666666'}
                                                          strokeW={2}/>
                                        )}
                                    </div>
                                </div>
                                {newPassword !== '' ?
                                    <div
                                        className='bg-gray-100 p-2 sh-sm mt-3 rounded-md'>
                                        <p className={`flex flex-row justify-start items-center gap-3 ${valid ? 'text-green-600' : 'text-yellow-600'}`}>
                                      <span>
                                          {valid ?
                                              <Information wh={20} color={'#059669'}/> :

                                              <Information wh={20} color={'#FF9600'}/>
                                          }
                                      </span>
                                            Votre mot de passe doit contenir :
                                        </p>
                                        <ul className="relative flex flex-col gap-3 mt-2">
                                            <li className={`${length ? 'text-gray-300' : ''} relative flex flex-row items-center list-none transition-all ft-sm gap-3 `}>
                                                {/*#059669 -> vert 600 #DC2626-> red 600 */}
                                                {length ?

                                                    <ValidationCercle wh={20}
                                                                      color={'#059669'}/> :
                                                    <InterrogationCercle wh={20}
                                                                         color={'#DC2626'}/>
                                                }Au moins 8 caractères
                                            </li>
                                            <li className={`${upper ? 'text-gray-300' : ''} relative flex flex-row list-none transition-all ft-sm gap-3`}>
                                                {upper ?

                                                    <ValidationCercle wh={20}
                                                                      color={'#059669'}/> :
                                                    <InterrogationCercle wh={20}
                                                                         color={'#DC2626'}/>
                                                }
                                                Au moins un caractère majuscule
                                            </li>
                                            <li className={`${lower ? 'text-gray-300' : ''} relative flex flex-row list-none transition-all ft-sm gap-3`}>
                                                {lower ?

                                                    <ValidationCercle wh={20}
                                                                      color={'#059669'}/> :
                                                    <InterrogationCercle wh={20}
                                                                         color={'#DC2626'}/>
                                                }
                                                Au moins un caractère minuscule
                                            </li>
                                            <li className={`${special ? 'text-gray-300' : ''} relative flex flex-row list-none transition-all ft-sm gap-3`}>
                                                {special ?

                                                    <ValidationCercle wh={20}
                                                                      color={'#059669'}/> :
                                                    <InterrogationCercle wh={20}
                                                                         color={'#DC2626'}/>
                                                }
                                                Au moins un caractère spécial
                                            </li>
                                            <li className={`${special ? 'text-gray-300' : ''} relative flex flex-row list-none transition-all ft-sm gap-3`}>
                                                {number ?

                                                    <ValidationCercle wh={20}
                                                                      color={'#059669'}/> :
                                                    <InterrogationCercle wh={20}
                                                                         color={'#DC2626'}/>
                                                }
                                                Au moins un chiffre (0-9)
                                            </li>
                                        </ul>
                                    </div>
                                    : ''}
                            </div>
                            <div className="col-span-12">
                                <label
                                    htmlFor="confirmPassword"
                                    className="block ft-sm font-medium  text-gray-500"
                                >
                                    Confirmation mot de passe
                                </label>
                                <div className="relative">
                                    <input
                                        name="confirmPassword"
                                        onChange={(e) => {
                                            setNewConfirmPassword(e.target.value);
                                        }}
                                        value={newConfirmPassowrd}
                                        className="w-full h-10 pl-3 pr-8 ft-b border rounded-lg focus:shadow-outline "
                                        type={showNewPasswordConfirm ? "text" : "password"}
                                        required
                                    />
                                    <div
                                        className="absolute inset-y-0 right-0 flex items-center px-2"
                                        onClick={handleShowPassordNewConfirm}
                                    >
                                        {showNewPasswordConfirm ? (
                                            <NoShowPassword wh={18} color={'#666666'}
                                                            strokeW={2}/>
                                        ) : (
                                            <ShowPassword wh={18} color={'#666666'}
                                                          strokeW={2}/>
                                        )}
                                    </div>
                                </div>
                                <p
                                    className={
                                        newConfirmPassowrd && !validMatch
                                            ? "text-red-500 flex flex-row gap-4 items-center m-2"
                                            : "hidden"
                                    }
                                >
                  <span>
                      <Information wh={15} color={'#FF9600'}/>

                  </span>
                                    Vos mots de passe ne correspondent pas.
                                </p>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button size={'LG'} color={'SECONDARY'}
                            onClick={toggle}>Annuler</Button>
                    <Button size={'LG'} onClick={handleSubmit}>Confirmer</Button>
                </ModalFooter>
        </>
    )
        ;
};

export default Modalmodifypwd;