import React, {useEffect, useState} from 'react';
import Chip from "components/chip/chip";
import {DocumentIcon, Signer} from "assets/icons";
import {dateWordShort} from "helper/helper";
import UseContext from "hooks/useContext";
import UseModal from "components/modal/useModal";
import {useNavigate} from "react-router-dom";


const Tbodyhours = ({champsBody}) => {
    const {setObjID, roleUser, setContent} = UseContext()
    const {toggle} = UseModal()
    const navigate = useNavigate()
    const rUser = localStorage.getItem('rUser')

    const statusTextDay = {
        1: 'À vérifier',
        2: 'Litige',
        3: 'Complet',
    }
    const statusColorDay = {
        1: 'ALERT',
        2: 'WARNING',
        3: 'INFO',

    }

    const handleModal=(e,i,item)=>{
        e.preventDefault()
        setObjID(i)
        setContent({
            title: item.title,
            idMission: item.id_mission_reel,
            nameCompany: item.name_commercial
        })
        navigate(`../ficheHour/${i}`)
    }

    const handleModalConsult=(e,i,item)=>{
        e.preventDefault()
        setObjID(i)
        setContent({
            title: item.title,
            idMission: item.id_mission_reel,
            nameCompany: item.name_commercial
        })
        navigate(`../ficheHour/${i}`)
    }



    return (
        <>
            {champsBody?.map((item, key) =>
                <tr key={key} className={'border-b-2 border-gray-100 '}>
                    <td className={'py-5'}>{item.id_time_sheet}</td>
                    <td className={'py-5'}>{item.name_commercial}</td>
                    <td className={'py-5'}>{item.title}</td>
                    <td className={'py-5'}>{item.id_mission_reel}</td>
                    <td className={'py-5'}>{item.firstname} {item.lastname}</td>
                    <td className={'py-5'}>
                        <Chip type={'INDICATOR'} color={statusColorDay[item.validated]}>{statusTextDay[item.validated]}</Chip>
                    </td>
                    <td className={'py-5'}>{dateWordShort(item.periode_start)} > {dateWordShort(item.periode_end)}</td>
                    <td className={'py-5 flex items-center justify-center cursor-pointer'}>
                        {
                            item.validated === 1 || item.validated === 2 ?
                                <span onClick={(e) => {handleModal(e,item._id, item)}}>
                                    <Signer wh={24}/>
                                </span>
                            :
                                <span onClick={(e) => {handleModalConsult(e,item._id, item)}}>
                                    <DocumentIcon wh={24} color={'#111827'}/>
                                </span>
                        }
                    </td>
                </tr>
            )}
        </>
    );
};

export default Tbodyhours;