import axios from "axios";

const abortControllers = {};

const getAbortController = (key) => {
    if (abortControllers[key]) {
        abortControllers[key].abort();
    }
    abortControllers[key] = new AbortController();
    return abortControllers[key];
};

export default {
    getUserProfile: (payload, resolve, reject) => {
        const controller = getAbortController('getUserProfile');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}user/info`,
            withCredentials: true,
            data: {
                token: payload.token
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getTempWorker: (payload, resolve, reject) => {
        const controller = getAbortController('getTempWorker');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}tempWorker/info`,
            withCredentials: true,
            data: {
                token: payload.token
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getCompanyProfile: (payload, resolve, reject) => {
        const controller = getAbortController('getCompanyProfile');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}company/${payload.token}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postRoleUser: (payload, resolve, reject) => {
        const controller = getAbortController('postRoleUser');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}user/role`,
            data: {
                token: payload.token
            },
            signal: controller.signal
        })
            .then(res => {
                if (res.data.errors)
                    reject(0, res.data.errors);
                else
                    resolve(res.data);
            })
            .catch(err => {
                if (reject) reject(1, err);
            });
    },
    postPasswordRefresh: (payload, resolve, reject) => {
        const controller = getAbortController('postPasswordRefresh');
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}user/update/password`,
            withCredentials: true,
            data: {
                oldPwd: payload.password,
                password: payload.newPassword,
                id: payload.id
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postEmailRefresh: (payload, resolve, reject) => {
        const controller = getAbortController('postEmailRefresh');
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}user/update/email`,
            withCredentials: true,
            data: {
                email: payload.email,
                token: payload.token,
                tokenMail: payload.tokenMail,
                validation: payload.validation
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postResendEmailToken: (payload, resolve, reject) => {
        const controller = getAbortController('postResendEmailToken');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}resend/tokenMail`,
            data: {
                email: payload.email
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postCreateTokenMail: (payload, resolve, reject) => {
        const controller = getAbortController('postCreateTokenMail');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}resend/createToken`,
            data: {
                email: payload.email,
                token: payload.token
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },

    // Modal
    //******** Language **************
    getListLanguages: (resolve, reject) => {
        const controller = getAbortController('getListLanguages');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}language`,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },

    postUserLanguage: (payload, resolve, reject) => {
        const controller = getAbortController('postUserLanguage');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}tempWorker/create/language`,
            withCredentials: true,
            data: {
                token: payload.id,
                language: payload.language
            },
            signal: controller.signal
        })
            .then(res => {
                if (res.data.errors)
                    reject(0, res.data.errors);
                else
                    resolve(res.data);
            })
            .catch(err => {
                if (reject) reject(1, err);
            });
    },
    deleteUserLanguage: (payload, resolve, reject) => {
        const controller = getAbortController('deleteUserLanguage');
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}tempWorker/delete/languages`,
            withCredentials: true,
            data: {
                token: payload.token,
                language: payload.language
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },

    //******** Tools **************
    getListTools: (resolve, reject) => {
        const controller = getAbortController('getListTools');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}tools`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },

    postUserTool: (payload, resolve, reject) => {
        const controller = getAbortController('postUserTool');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}tempWorker/create/tools`,
            withCredentials: true,
            data: {
                token: payload.token,
                tools: payload.tools
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postUserToolDelete: (payload, resolve, reject) => {
        const controller = getAbortController('postUserToolDelete');
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}tempWorker/delete/tools`,
            withCredentials: true,
            data: {
                token: payload.token,
                tool: payload.tool
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    // Modal
    //******** Language **************
    deleteUserCV: (payload, resolve, reject) => {
        const controller = getAbortController('deleteUserCV');
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}tempWorker/delete/cv`,
            withCredentials: true,
            data: {
                token: payload.token,
                cv: payload.cv
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },

    // Mission
    //******** GET ALL **************
    getAllMissions: (resolve, reject) => {
        const controller = getAbortController('getAllMissions');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}mission`,
            withCredentials: true,
            signal: controller.signal
        })
            .then((res) => resolve(res.data))
            .catch(err => reject(err));
    },
    getInfoMission: (payload, resolve, reject) => {
        const controller = getAbortController('getInfoMission');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}mission/${payload.id}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postCandidate: (payload, resolve, reject) => {
        const controller = getAbortController('postCandidate');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}mission/addUser`,
            withCredentials: true,
            data: {
                token: payload.token,
                mission: payload.mission
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postVerifyUser: (payload, resolve, reject) => {
        const controller = getAbortController('postVerifyUser');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}mission/verify`,
            withCredentials: true,
            data: {
                token: payload.token,
                missionUser: payload.mission
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    deleteUserMission: (payload, resolve, reject) => {
        const controller = getAbortController('deleteUserMission');
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}mission/deleteUser`,
            withCredentials: true,
            data: {
                mission: payload.mission,
                missionUser: payload.missionUser
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    // FILE
    //******** POST **************
    postFile: (payload, resolve, reject) => {
        const controller = getAbortController('postFile');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}upload/singleFile/CV`,
            withCredentials: true,
            data: {
                file: payload.file,
                token: payload.token
            },
            headers: {
                'content-type': 'multipart/form-data'
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    //******** GET PATH **************
    getPathFile: (payload, resolve, reject) => {
        const controller = getAbortController('getPathFile');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}upload/getSingleFile/${payload.id}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    // Modal Experience
    //******** POST **************
    postCreateExp: (payload, resolve, reject) => {
        const controller = getAbortController('postCreateExp');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}tempWorker/create/experience`,
            withCredentials: true,
            data: {
                token: payload.token,
                experience: payload.experience
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    //******** UPDATE **************
    putUpdateExp: (payload, resolve, reject) => {
        const controller = getAbortController('putUpdateExp');
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}tempWorker/update/experience`,
            withCredentials: true,
            data: {
                token: payload.token,
                expID: payload.expID,
                experience: payload.experience
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    //******** DELETE **************
    postDeleteExp: (payload, resolve, reject) => {
        const controller = getAbortController('postDeleteExp');
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}tempWorker/delete/experiences`,
            withCredentials: true,
            data: {
                token: payload.token,
                experienceID: payload.expID
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },

    //    Modal Licence
    //******** GET **************
    getAllLicence: (resolve, reject) => {
        const controller = getAbortController('getAllLicence');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}licence`,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    //******** UPDATE **************
    //******** DELETE **************
    postDeleteLicence: (payload, resolve, reject) => {
        const controller = getAbortController('postDeleteLicence');
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}tempWorker/delete/licence`,
            withCredentials: true,
            data: {
                token: payload.token,
                licence: payload.objID
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    //    Modal Licence
    //******** POST **************
    //******** UPDATE **************
    //******** DELETE **************
    postDeleteFormation: (payload, resolve, reject) => {
        const controller = getAbortController('postDeleteFormation');
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}tempWorker/delete/training`,
            withCredentials: true,
            data: {
                token: payload.token,
                formation: payload.objID
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    // JUSTIFICATIFS
    //    IBAN
    //******** POST **************
    postUpdateIban: (payload, resolve, reject) => {
        const controller = getAbortController('postUpdateIban');
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}tempWorker/update/bank`,
            withCredentials: true,
            data: {
                token: payload.token,
                bank: payload.iban
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },

    //    ACCOUNT
    //******** POST **************
    postUpdateCivility: (payload, resolve, reject) => {
        const controller = getAbortController('postUpdateCivility');
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}user/update/civility`,
            withCredentials: true,
            data: {
                token: payload.token,
                civility: payload.civility,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },

    postUpdatePhone: (payload, resolve, reject) => {
        const controller = getAbortController('postUpdatePhone');
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}user/update/phone`,
            withCredentials: true,
            data: {
                token: payload.token,
                phone: payload.phone,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },

    postUpdateAddress: (payload, resolve, reject) => {
        const controller = getAbortController('postUpdateAddress');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}address/add`,
            withCredentials: true,
            data: {
                token: payload.token,
                address: payload.address,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postUpdateAddressTempWorker: (payload, resolve, reject) => {
        const controller = getAbortController('postUpdateAddressTempWorker');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/address/add`,
            withCredentials: true,
            data: {
                token: payload.token,
                idUser: payload.id_user,
                address: payload.address,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getAddress: (payload, resolve, reject) => {
        const controller = getAbortController('getAddress');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}address/${payload.address}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    //    Time Sheets
    getTimeSheets: (payload, resolve, reject) => {
        const controller = getAbortController('getTimeSheets');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/timeSheets/allInfo`,
            withCredentials: true,
            data: {
                token: payload.token,
                status: payload.status,
                search: payload.search,
                searchOptions: payload.searchOptions,
                limit: payload.limit,
                page: payload.page,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getTimeSheetInfo: (payload, resolve, reject) => {
        const controller = getAbortController('getTimeSheetInfo');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}timeSheets/${payload.id}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getTimeSheetDay: (payload, resolve, reject) => {
        const controller = getAbortController('getTimeSheetDay');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}timeSheets/getTimeSheetDay/${payload.id}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    //******** POST **************
    postUpdateDay: (payload, resolve, reject) => {
        const controller = getAbortController('postUpdateDay');
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}timeSheets/updateDayAdmin`,
            withCredentials: true,
            data: {
                timeDay: payload.timeData,
                token: payload.token,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postUpdateWeek: (payload, resolve, reject) => {
        const controller = getAbortController('postUpdateWeek');
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}timeSheets/updateWeek`,
            withCredentials: true,
            data: {
                id: payload.id,
                tHours: payload.totalH
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getAllUsersWithIdentityFilesToComplete: (payload, resolve, reject) => {
        const controller = getAbortController('getAllUsersWithIdentityFilesToComplete');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/tempWorkersWithIdentityFilesToComplete`,
            withCredentials: true,
            data: {
                token: payload.token
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getUserWithIdentityFilesToComplete: (payload, resolve, reject) => {
        const controller = getAbortController('getUserWithIdentityFilesToComplete');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/tempWorkerWithIdentityFilesToComplete`,
            withCredentials: true,
            data: {
                token: payload.token,
                idTempWorker: payload.idTempWorker,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postRefuseTempWorkerFileAdmin: (payload, resolve, reject) => {
        const controller = getAbortController('postRefuseTempWorkerFileAdmin');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/refuseTempWorkerFileAdmin`,
            withCredentials: true,
            data: {
                token: payload.token,
                adminTempWorkerId: payload.adminTempWorkerId,
                adminType: payload.adminType,
                reason: payload.reason,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postAcceptTempWorkerFileAdmin: (payload, resolve, reject) => {
        const controller = getAbortController('postAcceptTempWorkerFileAdmin');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/acceptTempWorkerFileAdmin`,
            withCredentials: true,
            data: {
                token: payload.token,
                adminTempWorkerId: payload.adminTempWorkerId,
                adminType: payload.adminType,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getTempWorkerById: (payload, resolve, reject) => {
        const controller = getAbortController('getTempWorkerById');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}tempWorker/${payload.adminTempWorkerId}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getTempWorkerByIdUser: (payload, resolve, reject) => {
        const controller = getAbortController('getTempWorkerByIdUser');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}tempWorker/tempWorkerByIdUser/${payload.idUser}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getUserById: (payload, resolve, reject) => {
        const controller = getAbortController('getUserById');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}user/${payload.id_user}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postAcceptTempWorkerFileIdentityAdmin: (payload, resolve, reject) => {
        const controller = getAbortController('postAcceptTempWorkerFileIdentityAdmin');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/acceptTempWorkerFileIdentityAdmin`,
            withCredentials: true,
            data: {
                token: payload.token,
                adminTempWorkerId: payload.adminTempWorkerId,
                adminType: payload.adminType,
                numberIdentity: payload.numberIdentity,
                placeOfBirthday: payload.placeOfBirthday,
                dateObtained: payload.dateObtained,
                dateExpiration: payload.dateExpiration,
                idNationality: payload.idNationality,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postAcceptTempWorkerFileVisaAdmin: (payload, resolve, reject) => {
        const controller = getAbortController('postAcceptTempWorkerFileVisaAdmin');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/acceptTempWorkerFileVisaAdmin`,
            withCredentials: true,
            data: {
                token: payload.token,
                adminTempWorkerId: payload.adminTempWorkerId,
                adminType: payload.adminType,
                numberVisa: payload.numberVisa,
                dateObtained: payload.dateObtained,
                dateExpiration: payload.dateExpiration,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postAcceptTempWorkerFileVitalCardAdmin: (payload, resolve, reject) => {
        const controller = getAbortController('postAcceptTempWorkerFileVitalCardAdmin');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/acceptTempWorkerFileVitalCardAdmin`,
            withCredentials: true,
            data: {
                token: payload.token,
                adminTempWorkerId: payload.adminTempWorkerId,
                adminType: payload.adminType,
                numberVitalCard: payload.numberVitalCard,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getTimeSheetsBeSTT: (payload, resolve, reject) => {
        const controller = getAbortController('getTimeSheetsBeSTT');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/getTimeSheetsBeSTT`,
            withCredentials: true,
            data: {
                id_temp_worker_bestt: payload.id_temp_worker_bestt,
                id_company_bestt: payload.id_company_bestt,
                date_start: payload.date_start_format,
                date_end: payload.date_end_format,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postUpdateTimeSheet: (payload, resolve, reject) => {
        const controller = getAbortController('postUpdateTimeSheet');
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}timeSheets/updateCompany`,
            withCredentials: true,
            data: {
                id: payload.id,
                time: payload.time,
                token: payload.token,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getCompanies: (payload, resolve, reject) => {
        const controller = getAbortController('getCompanies');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/getCompaniesList?token=${payload.token}&search=${payload.search}&page=${payload.page}&limit=${payload.limit}&optionSearch=${payload.optionSearch}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getCompanyInfo: (payload, resolve, reject) => {
        const controller = getAbortController('getCompanyInfo');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/getCompanyInfo?token=${payload.token}&idCompany=${payload.id}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getMinimumWage: (resolve, reject) => {
        const controller = getAbortController('getMinimumWage');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/getMinimumWage`,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postCoefficient: (payload, resolve, reject) => {
        const controller = getAbortController('postCoefficient');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/postCoefficient`,
            withCredentials: true,
            data: {
                token: payload.token,
                idCompany: payload.idCompany,
                coefficients: payload.coefficients,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    searchMission: (payload, resolve, reject) => {
        const controller = getAbortController('searchMission');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/searchMission`,
            withCredentials: true,
            data: {
                token: payload.token,
                search: payload.search,
                isMissionSearchAssigned: payload.isMissionSearchAssigned,
                isMissionSearchSigned: payload.isMissionSearchSigned,
                isMissionSearchSignatureSend: payload.isMissionSearchSignatureSend,
                statusMission: payload.statusMission,
                limit: payload.limit,
                page: payload.page,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getMissionDetailsById: (payload, resolve, reject) => {
        const controller = getAbortController('getMissionDetailsById');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/getMissionDetailsById?token=${payload.token}&idMission=${payload.idMission}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    searchTempWorker: (payload, resolve, reject) => {
        const controller = getAbortController('searchTempWorker');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/searchTempWorker`,
            withCredentials: true,
            data: {
                token: payload.token,
                search: payload.search,
                limit: payload.limit,
                page: payload.page,
                missionId: payload.missionId,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    modifyMissionPeriod: (payload, resolve, reject) => {
        const controller = getAbortController('modifyMissionPeriod');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}mission/modifyMissionPeriod`,
            withCredentials: true,
            data: {
                idMission: payload.idMission,
                dateStart: payload.dateStart,
                dateEnd: payload.dateEnd,
                token: payload.token,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    assignTempWorkerToMission: (payload, resolve, reject) => {
        const controller = getAbortController('assignTempWorkerToMission');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}mission/assign`,
            withCredentials: true,
            data: {
                idMission: payload.idMission,
                idTempWorker: payload.idTempWorker,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    requestSignContract: (payload, resolve, reject) => {
        const controller = getAbortController('requestSignContract');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/requestSignContract`,
            withCredentials: true,
            data: {
                idMission: payload.idMission,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getJobs: (payload, resolve, reject) => {
        const controller = getAbortController('getJobs');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}hrFlowJobs/getJobs?token=${payload.token}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postJob: (payload, resolve, reject) => {
        const controller = getAbortController('postJob');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}hrFlowJobs/addJob`,
            withCredentials: true,
            data: {
                token: payload.token,
                title: payload.title,
                description: payload.description,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    putJob: (payload, resolve, reject) => {
        const controller = getAbortController('putJob');
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}hrFlowJobs/updateJob`,
            withCredentials: true,
            data: {
                token: payload.token,
                jobID: payload.jobID,
                title: payload.title,
                description: payload.description,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    patchJob: (payload, resolve, reject) => {
        const controller = getAbortController('patchJob');
        axios({
            method: 'patch',
            url: `${process.env.REACT_APP_API_URL}hrFlowJobs/deleteJob`,
            withCredentials: true,
            data: {
                token: payload.token,
                jobID: payload.jobID,
                title: payload.title,
                description: payload.description,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getTempWorkerPostulateMission: (payload, resolve, reject) => {
        const controller = getAbortController('getTempWorkerPostulateMission');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/getTempWorkerPostulateMission`,
            withCredentials: true,
            data: {
                token: payload.token,
                idMission: payload.idMission,
                search: payload.search,
                limit: payload.limit,
                page: payload.page,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    unassignMission: (payload, resolve, reject) => {
        const controller = getAbortController('unassignMission');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/unassignMission`,
            withCredentials: true,
            data: {
                token: payload.token,
                idMission: payload.idMission,
                userAssigned: payload.userAssigned,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    // #region ---- Début Section Vivier ----
    // #region **** All vivier ****
    getVivierAll: (resolve, reject) => {
        const controller = getAbortController('getVivierAll');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}vivier/vivierAll`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    // #endregion **** All vivier ****
    // #region **** Create Vivier ****
    createVivier: (payload, resolve, reject) => {
        const controller = getAbortController('createVivier');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}vivier/create`,
            withCredentials: true,
            data: {
                nameVivier: payload.name,
                secteurVivier: payload.secteur,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    // #endregion **** Create Vivier ****
    // #endregion ---- Fin Section Vivier ----
    putDisplayCostEstimate: (payload, resolve, reject) => {
        const controller = getAbortController('putDisplayCostEstimate');
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}admin/updateDisplayCostEstimate`,
            withCredentials: true,
            data: {
                token: payload.token,
                idCompany: payload.idCompany,
                displayCostEstimate: payload.displayCostEstimate,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getDisplayCostEstimate: (payload, resolve, reject) => {
        const controller = getAbortController('getDisplayCostEstimate');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/getDisplayCostEstimate?token=${payload.token}&idCompany=${payload.idCompany}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getScheduleByIdMission: (payload, resolve, reject) => {
        const controller = getAbortController('getScheduleByIdMission');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/getScheduleByIdMission?token=${payload.token}&idMission=${payload.idMission}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    linkCompanyToBigAccount: (payload, resolve, reject) => {
        const controller = getAbortController('linkCompanyToBigAccount');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/linkCompanyToBigAccount`,
            withCredentials: true,
            data: {
                token: payload.token,
                idCompany: payload.idCompany,
                idBigAccount: payload.idBigAccount,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getBigAccounts: (payload, resolve, reject) => {
        const controller = getAbortController('getBigAccounts');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/getBigAccounts`,
            withCredentials: true,
            data: {
                token: payload.token,
                search: payload.search,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    addBigAccount: (payload, resolve, reject) => {
        const controller = getAbortController('addBigAccount');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/addBigAccount`,
            withCredentials: true,
            data: {
                token: payload.token,
                name: payload.name,
                isLinked: payload.isLinked,
                idCompany: payload.idCompany,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getCompanyBigAccount: (payload, resolve, reject) => {
        const controller = getAbortController('getCompanyBigAccount');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/getCompanyBigAccount?token=${payload.token}&idCompany=${payload.idCompany}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    updScheduleByIdMission: (payload, resolve, reject) => {
        const controller = getAbortController('updScheduleByIdMission');
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}mission/updScheduleByIdMission`,
            withCredentials: true,
            data: {
                token: payload.token,
                idMission: payload.idMission,
                schedule: payload.schedule,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    deleteCompanyBigAccount: (payload, resolve, reject) => {
        const controller = getAbortController('deleteCompanyBigAccount');
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}admin/deleteCompanyBigAccount`,
            withCredentials: true,
            data: {
                token: payload.token,
                idCompany: payload.idCompany,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    deleteBigAccount: (payload, resolve, reject) => {
        const controller = getAbortController('deleteBigAccount');
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}admin/deleteBigAccount`,
            withCredentials: true,
            data: {
                token: payload.token,
                idBigAccount: payload.idBigAccount,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    requestGenerationContract: (payload, resolve, reject) => {
        const controller = getAbortController('requestGenerationContract');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/requestGenerationContract`,
            withCredentials: true,
            data: {
                token: payload.token,
                idMission: payload.idMission,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postAddCompany: (payload, resolve, reject) => {
        const controller = getAbortController('postAddCompany');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}company/add`,
            withCredentials: true,
            data: {
                token: payload.token,
                company: payload.company,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getReasonRefusesDocuments: (payload, resolve, reject) => {
        const controller = getAbortController('getReasonRefusesDocuments');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/reasonRefusesDocuments?token=${payload.token}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getRatingTreatedTempWorkers: (payload, resolve, reject) => {
        const controller = getAbortController('getRatingTreatedTempWorkers');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/ratingTreatedTempWorkers?token=${payload.token}&search=${payload.search}&limit=${payload.limit}&page=${payload.page}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getRatingUntreatedTempWorkers: (payload, resolve, reject) => {
        const controller = getAbortController('getRatingUntreatedTempWorkers');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/ratingUntreatedTempWorkers?token=${payload.token}&search=${payload.search}&limit=${payload.limit}&page=${payload.page}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getRatingTempWorker: (payload, resolve, reject) => {
        const controller = getAbortController('getRatingTempWorker');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/ratingTempWorker?token=${payload.token}&idTempWorkerRating=${payload.idTempWorkerRating}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getRatingTempWorkerByMission: (payload, resolve, reject) => {
        const controller = getAbortController('getRatingTempWorkerByMission');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/ratingTempWorkerByMission?token=${payload.token}&idUser=${payload.idUser}&idMission=${payload.idMission}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postTreatmentRatingTempWorker: (payload, resolve, reject) => {
        const controller = getAbortController('postTreatmentRatingTempWorker');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/treatmentRatingTempWorker`,
            withCredentials: true,
            data: {
                token: payload.token,
                idTempWorkerReview: payload.idTempWorkerReview,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getNbRatingUntreatedTempWorkers: (payload, resolve, reject) => {
        const controller = getAbortController('getNbRatingUntreatedTempWorkers');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/nbRatingUntreatedTempWorkers?token=${payload.token}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getReviewsByTempWorkerGroupByMissions: (payload, resolve, reject) => {
        const controller = getAbortController('getReviewsByTempWorkerGroupByMissions');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/reviewsByTempWorkerGroupByMissions?token=${payload.token}&idUser=${payload.idUser}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getReviewsByTempworkerByMission: (payload, resolve, reject) => {
        const controller = getAbortController('getReviewsByTempworkerByMission');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/reviewsByTempworkerByMission?token=${payload.token}&idUser=${payload.idUser}&idMission=${payload.idMission}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getTempWorkerInviteMission: (payload, resolve, reject) => {
        const controller = getAbortController('getTempWorkerInviteMission');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/getTempWorkerInviteMission?token=${payload.token}&idMission=${payload.idMission}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getNbReviewsTempWorker: (payload, resolve, reject) => {
        const controller = getAbortController('getNbReviewsTempWorker');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/nbReviewsTempWorker?token=${payload.token}&idUser=${payload.idUser}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getNationalities: (payload, resolve, reject) => {
        const controller = getAbortController('getNationalities');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/nationalities?token=${payload.token}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getAllQualifications: (payload, resolve, reject) => {
        const controller = getAbortController('getAllQualifications');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}qualification/getAllQualifications?token=${payload.token}&search=${payload.search}&limit=${payload.limit}&page=${payload.page}&optionSearch=${payload.optionSearch}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postQualification: (payload, resolve, reject) => {
        const controller = getAbortController('postQualification');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}qualification/postQualification`,
            withCredentials: true,
            data: {
                token: payload.token,
                name: payload.name,
                description: payload.description,
                pcs: payload.pcs,
                rome: payload.rome,
                type: payload.type,
                isToDisplayInMissionPreferences: payload.isToDisplayInMissionPreferences,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    updateQualification: (payload, resolve, reject) => {
        const controller = getAbortController('updateQualification');
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}qualification/putQualification`,
            withCredentials: true,
            data: {
                token: payload.token,
                idQualification: payload.idQualification,
                name: payload.name,
                description: payload.description,
                pcs: payload.pcs,
                rome: payload.rome,
                type: payload.type,
                isToDisplayInMissionPreferences: payload.isToDisplayInMissionPreferences,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getQualificationByID: (payload, resolve, reject) => {
        const controller = getAbortController('getQualificationByID');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}qualification/getQualificationByID?token=${payload.token}&idQualification=${payload.idQualification}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    delQualification: (payload, resolve, reject) => {
        const controller = getAbortController('delQualification');
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}qualification/delQualification`,
            withCredentials: true,
            data: {
                token: payload.token,
                idQualification: payload.idQualification,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    activateQualification: (payload, resolve, reject) => {
        const controller = getAbortController('activateQualification');
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}qualification/activateQualification`,
            withCredentials: true,
            data: {
                token: payload.token,
                idQualification: payload.idQualification,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postExcludeAgency: (payload, resolve, reject) => {
        const controller = getAbortController('postExcludeAgency');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}vivier/postExcludeAgency`,
            withCredentials: true,
            data: {
                token: payload.token,
                idUsers: payload.idUsers,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getLocalisationPreferenceUser: (payload, resolve, reject) => {
        const controller = getAbortController('getLocalisationPreferenceUser');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/localisationPreference?token=${payload.token}&idUser=${payload.idUser}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    updLocalisationPreferenceUsers: (payload, resolve, reject) => {
        const controller = getAbortController('updLocalisationPreferenceUsers');
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}admin/localisationPreferenceUsers`,
            withCredentials: true,
            data: {
                token: payload.token,
                idUsers: payload.idUsers,
                localisation: payload.localisation,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    deleteVivier: (payload, resolve, reject) => {
        const controller = getAbortController('deleteVivier');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}vivier/delete`,
            withCredentials: true,
            data: {
                token: payload.token,
                idVivier: payload.idVivier,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    deleteVivierUser: (payload, resolve, reject) => {
        const controller = getAbortController('deleteVivierUser');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}vivier/deleteCandidateVivier`,
            withCredentials: true,
            data: {
                token: payload.token,
                idVivier: payload.idVivier,
                idUsers: payload.idUsers,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    updUserVivierPreferenceStatus: (payload, resolve, reject) => {
        const controller = getAbortController('updUserVivierPreferenceStatus');
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}vivier/updUserVivierPreferenceStatus`,
            withCredentials: true,
            data: {
                token: payload.token,
                idUsers: payload.idUsers,
                idVivier: payload.idVivier,
                vivierStatus: payload.vivierStatus,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getCommentsByTempWorker: (payload, resolve, reject) => {
        const controller = getAbortController('getCommentsByTempWorker');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/commentsByTempWorker?token=${payload.token}&idUser=${payload.idUser}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getNbCommentsByTempWorker: (payload, resolve, reject) => {
        const controller = getAbortController('getNbCommentsByTempWorker');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/nbCommentsByTempWorker?token=${payload.token}&idUser=${payload.idUser}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postCommentTempWorker: (payload, resolve, reject) => {
        const controller = getAbortController('postCommentTempWorker');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/commentTempWorker`,
            withCredentials: true,
            data: {
                token: payload.token,
                idUser: payload.idUser,
                comment: payload.comment,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    deleteCommentTempWorker: (payload, resolve, reject) => {
        const controller = getAbortController('deleteCommentTempWorker');
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}admin/commentTempWorker`,
            withCredentials: true,
            data: {
                token: payload.token,
                idComment: payload.idComment,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getVivierById: (payload, resolve, reject) => {
        const controller = getAbortController('getVivierById');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}vivier/vivierById?token=${payload.token}&idVivier=${payload.idVivier}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    updateUsersVivierStatus: (payload, resolve, reject) => {
        const controller = getAbortController('updateUsersVivierStatus');
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}vivier/updateUsersVivierStatus`,
            withCredentials: true,
            data: {
                token: payload.token,
                idUsers: payload.idUsers,
                idVivier: payload.idVivier,
                vivierStatus: payload.vivierStatus,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getUserVivierArchiveReason: (payload, resolve, reject) => {
        const controller = getAbortController('getUserVivierArchiveReason');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/userVivierArchiveReason?token=${payload.token}&idUser=${payload.idUser}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getUserViviers: (payload, resolve, reject) => {
        const controller = getAbortController('getUserViviers');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/userViviers?token=${payload.token}&idUser=${payload.idUser}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getUserMissions: (payload, resolve, reject) => {
        const controller = getAbortController('getUserMissions');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/userMissions?token=${payload.token}&idUser=${payload.idUser}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getCollectiveAgreement: (payload, resolve, reject) => {
        const controller = getAbortController('getCollectiveAgreement');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/collectiveAgreement?token=${payload.token}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    deleteAdminVivierFavorite: (payload, resolve, reject) => {
        const controller = getAbortController('deleteAdminVivierFavorite');
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}admin/adminVivierFavorite`,
            withCredentials: true,
            data: {
                token: payload.token,
                idVivier: payload.idVivier,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    searchCompanyInfosBySiret: (payload, resolve, reject) => {
        const controller = getAbortController('searchCompanyInfosBySiret');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/searchCompanyInfosBySiret?token=${payload.token}&siret=${payload.siret}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postAdminVivierFavorite: (payload, resolve, reject) => {
        const controller = getAbortController('postAdminVivierFavorite');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/adminVivierFavorite`,
            withCredentials: true,
            data: {
                token: payload.token,
                idVivier: payload.idVivier,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    searchCompanyInfosByName: (payload, resolve, reject) => {
        const controller = getAbortController('searchCompanyInfosByName');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/searchCompanyInfosByName?token=${payload.token}&name=${payload.name}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getTimeSheetHoursVerified: (payload, resolve, reject) => {
        const controller = getAbortController('getTimeSheetHoursVerified');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/getTimeSheetHoursVerified`,
            withCredentials: true,
            data: {
                token: payload.token,
                dateRange: payload.dateRange,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postCancelMission: (payload, resolve, reject) => {
        const controller = getAbortController('postCancelMission');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/cancelMission`,
            withCredentials: true,
            data: {
                token: payload.token,
                idMission: payload.idMission,
                reason: payload.reason,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    postDeleteMission: (payload, resolve, reject) => {
        const controller = getAbortController('postDeleteMission');
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}admin/deleteMission`,
            withCredentials: true,
            data: {
                token: payload.token,
                idMission: payload.idMission,
                reason: payload.reason,
            },
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
    getInfoMissionCancelDelete: (payload, resolve, reject) => {
        const controller = getAbortController('getInfoMissionCancelDelete');
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}mission/infoMissionCancelDelete?token=${payload.token}&idMission=${payload.idMission}&isAdmin=${payload.isAdmin}`,
            withCredentials: true,
            signal: controller.signal
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    },
};
