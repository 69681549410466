import React from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import ApiDatabase from 'server'
import {toast} from "react-toastify";
import useModal from "components/modal/useModal";
import UseContext from "hooks/useContext";

const Modaldelete = () => {
    const {objID,refreshLoadWorker,objType}=UseContext()
    const {toggle}=useModal()

    const handleDeleted=(e)=>{
        e.preventDefault()
        if (objType === 'licence'){

        ApiDatabase.postDeleteLicence({token: localStorage.getItem('xsrfToken'),objID},
            (data)=>{
                if (data.success) {
                    refreshLoadWorker()
                    toast.success('La formation est supprimée.')
                }
                if (data.error) toast.error('une erreur est survenu')
            },
            (err)=>{
                if (err.name !== "CanceledError") {
                    console.log(err)
                    toast.error('une erreur est survenu')
                }
            })
        }
        if(objType==='training'){
            ApiDatabase.postDeleteFormation({token: localStorage.getItem('xsrfToken'),objID},
                (data)=>{
                    if (data.success) {
                        refreshLoadWorker()
                        toast.success('La formation est supprimée.')
                    }
                    if (data.error) toast.error('une erreur est survenu')
                },
                (err)=> {
                    if (err.name !== "CanceledError") {
                        console.log(err)
                        toast.error('une erreur est survenu')
                    }
                })
        }
    }
    return (
        <>
                <Modalheader hide={toggle} hideShow={true}>Suppression  {objType === 'licence'? 'un permis' :' une formation'}</Modalheader>
                <ModalBody>
                    Êtes-vous sûr de vouloir supprimer {objType === 'licence'? 'ce permis' :'cette formation'} ? Cette action est irréversible.
                </ModalBody>
                <ModalFooter>
                    <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Annuler</Button>
                    <Button size={'LG'} onClick={handleDeleted}>Confirmer</Button>
                </ModalFooter>
        </>
    );
};

export default Modaldelete;